import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;
const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};
export async function getBEData(BE_ID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/GetBUByID/${BE_ID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching BEData:', error);
        throw error;
    }
}

export async function updateBEData(busEntity) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/updateEntity`, busEntity, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating BEData:', error);
        throw error;
    }
}

export async function insertBUData(busUnit) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/InsetBusUnit`, busUnit, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error inserting BUData:', error);
        throw error;
    }
}

export async function getBUData() {
    try {
        const response = await axios.get(`${API_BASE_URL}Configuration/getBusUnit`, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching BUData:', error);
        throw error;
    }
}

export async function updateBUData(busUnit) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/UpdateBusUnit`, busUnit, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating BUData:', error);
        throw error;
    }
}

export async function deleteBUData(busUnit) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteBusUnit`, busUnit, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error deleting BUData:', error);
        throw error;
    }
}

export async function insertBRData(busBranch) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/InsetBusBranch`, busBranch, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error inserting BRData:', error);
        throw error;
    }
}

export async function getBRData() {
    try {
        const response = await axios.get(`${API_BASE_URL}Configuration/getBusBranch`, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching BRData:', error);
        throw error;
    }
}

export async function updateBRData(busBranch) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/UpdateBusBranch`, busBranch, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating BRData:', error);
        throw error;
    }
}

export async function deleteBRData(busBranch) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteBusBranch`, busBranch, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error deleting BRData:', error);
        throw error;
    }
}

export async function setBranchStatus(BR_ID, IsActive) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeactivateBusBranch`, null, {
            params: {
                BusBranchID: BR_ID,
                IsActive: IsActive
            },
            headers: getAuthHeader()
        });
        return response.data;
    } catch (error) {
        console.error('Error Updating Status:', error);
        throw error;
    }
}
