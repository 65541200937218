export const customStyles = {
    menu: base => ({ ...base, zIndex: 9999 }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? '#FFFFFF' : base.backgroundColor,
        color: state.isSelected ? '#000000' : base.color,
        ':active': {
            backgroundColor: state.isSelected ? '#000000' : base[':active'].backgroundColor,
        },
    }),
    multiValue: base => ({
        ...base,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 100
    }),
    multiValueLabel: base => ({
        ...base,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }),
};