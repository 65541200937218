import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import './SideMenu.css';

import GetUserAccess from '../CommomPages/GetUserAccess';

import HomeIcon from '../assets/icon/Home.svg';
import iTechLogo from '../assets/img/iTech_Logo.png';
import SettingsIcon from '../assets/icon/Settings.svg';
import SideMenuEpand from '../assets/icon/SideMenuEpand.svg';


const SideMenuNew = ({ activeMenuItem, setActiveMenuItem }) => {
    const location = useLocation();
    const [userScreens, setUserScreens] = useState({});

    const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);
    const [isSubMenuOpenAsset, setIsSubMenuOpenAsset] = useState(false);
    const [isSubMenuOpenInbox, setIsSubMenuOpenInbox] = useState(false);
    const [isSubMenuOpenImport, setIsSubMenuOpenImport] = useState(false);
    const [isSubMenuOpenReports, setIsSubMenuOpenReports] = useState(false);

    const [assetMaintenanceCount, setAssetMaintenanceCount] = useState(0);
    const [assetServiceRequestCount, setAssetServiceRequestCount] = useState(0);
    const [allNearlyExpiryInsurance, setAllNearlyExpiryInsurance] = useState(0);
    const [allNearlyExpiryWarranty, setAllNearlyExpiryWarranty] = useState(0);

    useEffect(() => {
        const queryParams = `${location.pathname}${location.search}`
        handleMenuItemClick(queryParams);
        if (['/AssetCreationView'].includes(queryParams) ||
            ['/AssetCreationAdd'].includes(queryParams) ||
            ['/AssetDetailsView'].includes(queryParams) ||
            ['/FinancialTransaction'].includes(queryParams) ||
            ['/CheckOut'].includes(queryParams) ||
            ['/CheckIn'].includes(queryParams) ||
            ['/Transfer'].includes(queryParams) ||
            ['/AuditList'].includes(queryParams)
        ) {
            setIsSubMenuOpenAsset(true);
        } else {
            setIsSubMenuOpenAsset(false);
        }

        if (['/ProcessFormConfigurationInsurance'].includes(queryParams) ||
            ['/ProcessFormConfigurationMaintenance'].includes(queryParams) ||
            ['/ProcessFormConfigurationServiceRequest'].includes(queryParams) ||
            ['/ProcessFormConfigurationWarranty'].includes(queryParams)
        ) {
            setIsSubMenuOpenInbox(true);
        } else {
            setIsSubMenuOpenInbox(false);
        }

        if (['/DataImport'].includes(queryParams) ||
            ['/ImportHistory'].includes(queryParams)
        ) {
            setIsSubMenuOpenImport(true);
        } else {
            setIsSubMenuOpenImport(false);
        }

        if (['/Reports/Asset/AssetIdReport'].includes(queryParams) ||
            ['/Reports/Asset/AssetCategoryReport'].includes(queryParams) ||
            ['/Reports/Asset/AssetDepartmentReport'].includes(queryParams) ||
            ['/Reports/Asset/AssetUnitBranchReport'].includes(queryParams) ||
            ['/Reports/Asset/AssetLinkedReport'].includes(queryParams) ||
            ['/Reports/Status/Repaired'].includes(queryParams) ||
            ['/Reports/Status/Broken'].includes(queryParams) ||
            ['/Reports/Status/Disposed'].includes(queryParams) ||
            ['/Reports/Status/Soled'].includes(queryParams) ||
            ['/Reports/Status/Lost'].includes(queryParams) ||
            ['/Reports/Status/Donated'].includes(queryParams) ||
            ['/Reports/CheckOut/Person'].includes(queryParams) ||
            ['/Reports/CheckOut/Asset'].includes(queryParams) ||
            ['/Reports/CheckOut/Due'].includes(queryParams) ||
            ['/Reports/CheckOut/PastDue'].includes(queryParams) ||
            ['/Reports/CheckOut/SiteAndLocation'].includes(queryParams) ||
            ['/Reports/CheckOut/TimeFrame'].includes(queryParams) ||
            ['/Reports/Maintenance/Schedule'].includes(queryParams) ||
            ['/Reports/Maintenance/Asset'].includes(queryParams) ||
            ['/Reports/Maintenance/Complete'].includes(queryParams) ||
            ['/Reports/Maintenance/Due'].includes(queryParams) ||
            ['/Reports/Insurance/Insurance'].includes(queryParams) ||
            ['/Reports/Insurance/Asset'].includes(queryParams)

        ) {
            setIsSubMenuOpenReports(true);
        } else {
            setIsSubMenuOpenReports(false);
        }
    }, [location]);

    useEffect(() => {
        fetchUserScreens();
        const MaintenanceCount = parseInt(localStorage.getItem('MaintenanceCount'), 10);
        if (MaintenanceCount > 0) { setAssetMaintenanceCount(MaintenanceCount) }
        const ServiceRequestCount = parseInt(localStorage.getItem('ServiceRequestCount'), 10);
        if (ServiceRequestCount > 0) { setAssetServiceRequestCount(ServiceRequestCount) }
        const InsuranceCount = parseInt(localStorage.getItem('InsuranceCount'), 10);
        if (InsuranceCount > 0) { setAllNearlyExpiryInsurance(InsuranceCount) }
        const WarrantyCount = parseInt(localStorage.getItem('WarrantyCount'), 10);
        if (WarrantyCount > 0) { setAllNearlyExpiryWarranty(WarrantyCount) }
    }, [location]);

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
        setActiveSubMenuItem(menuItem);
    };

    const handleSubMenuItemClick = (subMenuItem) => {
        setActiveSubMenuItem(subMenuItem);
        setActiveMenuItem(subMenuItem);
    };

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
            console.log('User has access to these screens:', userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    return (
        <div style={{ width: '225px !important' }}>
            <Sidebar className='sidebarNew sideMenuScrollbar'>
                <div style={{ textAlign: 'center', paddingTop: '10%', paddingBottom: '20%' }}>
                    <img src={iTechLogo} style={{ width: '140px', height: '62.15px' }} alt="iTech Logo" />
                </div>
                <Menu >
                    <Link to="/Dashboard" className='no-underline' onClick={() => handleMenuItemClick("/Dashboard", "Dashboard")}>
                        <MenuItem
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color: activeMenuItem === "/Dashboard" ? "#FFFFFF" : "#000000",
                                background: activeMenuItem === "/Dashboard" ? "#605BFF" : "",
                            }}
                        >
                            <img
                                src={HomeIcon}
                                alt='DashboardIcon'
                                style={{ paddingRight: '10px', paddingBottom: '5px', filter: activeMenuItem === "/Dashboard" ? "invert(1)" : "invert(0)" }}
                            />
                            <span
                                style={{ color: activeMenuItem === "/Dashboard" ? "#FFFFFF" : "#000000" }}
                            >
                                Dashboard
                            </span>
                        </MenuItem>
                    </Link>

                    <Link to="/Settings" className='no-underline' onClick={() => handleMenuItemClick("/Settings", "Settings")}>
                        <MenuItem
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color: activeMenuItem === "/Settings" ? "#FFFFFF" : "#000000",
                                background: activeMenuItem === "/Settings" ? "#605BFF" : "",
                            }}
                        >
                            <img
                                src={SettingsIcon}
                                alt='SettingsIcon'
                                style={{ paddingRight: '10px', paddingBottom: '5px', filter: activeMenuItem === "/Settings" ? "invert(0)" : "invert(1)" }}
                            />
                            <span
                                style={{ color: activeMenuItem === "/Settings" ? "#FFFFFF" : "#000000" }}
                            >
                                Settings
                            </span>
                        </MenuItem>
                    </Link>

                    {userScreens["Asset Management"]?.View && (
                        <SubMenu
                            label={
                                <span style={{
                                    display: 'flex', alignItems: 'center', color:
                                        activeSubMenuItem?.includes("/AssetCreationAdd") ||
                                            activeSubMenuItem === "/AssetCreationView" ||
                                            activeSubMenuItem === "/FinancialTransaction" ||
                                            activeSubMenuItem === "/CheckOut" ||
                                            activeSubMenuItem === "/CheckIn" ||
                                            activeSubMenuItem === "/Transfer" ||
                                            activeSubMenuItem === "/AuditList" ||
                                            activeSubMenuItem?.includes("/AssetDetailsView") ? "#FFFFFF" : "#000000"
                                }}>
                                    <img
                                        src={SettingsIcon}
                                        alt='SettingsIcon'
                                        style={{
                                            marginRight: '10px',
                                            paddingBottom: '5px',
                                            filter:
                                                activeSubMenuItem?.includes("/AssetCreationAdd") ||
                                                    activeSubMenuItem === "/AssetCreationView" ||
                                                    activeSubMenuItem === "/FinancialTransaction" ||
                                                    activeSubMenuItem === "/CheckOut" ||
                                                    activeSubMenuItem === "/CheckIn" ||
                                                    activeSubMenuItem === "/Transfer" ||
                                                    activeSubMenuItem === "/AuditList" ||
                                                    activeSubMenuItem?.includes("/AssetDetailsView") ? "invert(0)" : "invert(1)"
                                        }}
                                    />
                                    Asset
                                </span>
                            }
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color:
                                    activeSubMenuItem?.includes("/AssetCreationAdd") ||
                                        activeSubMenuItem === "/AssetCreationView" ||
                                        activeSubMenuItem === "/FinancialTransaction" ||
                                        activeSubMenuItem === "/CheckOut" ||
                                        activeSubMenuItem === "/CheckIn" ||
                                        activeSubMenuItem === "/Transfer" ||
                                        activeSubMenuItem === "/AuditList" ||
                                        activeSubMenuItem?.includes("/AssetDetailsView") ? "#FFFFFF" : "#000000",
                                background:
                                    activeSubMenuItem?.includes("/AssetCreationAdd") ||
                                        activeSubMenuItem === "/AssetCreationView" ||
                                        activeSubMenuItem === "/FinancialTransaction" ||
                                        activeSubMenuItem === "/CheckOut" ||
                                        activeSubMenuItem === "/CheckIn" ||
                                        activeSubMenuItem === "/Transfer" ||
                                        activeSubMenuItem === "/AuditList" ||
                                        activeSubMenuItem?.includes("/AssetDetailsView") ? "#605BFF" : "",
                            }}
                            defaultOpen={isSubMenuOpenAsset}
                        >

                            <Link to="/AssetCreationView" className='no-underline' onClick={() => handleSubMenuItemClick("/AssetCreationView")}>
                                <MenuItem
                                    className="menuItem1 fontweightSemiBold"
                                    style={{
                                        fontWeight: "500",
                                        background: activeSubMenuItem?.includes("/AssetCreationView") ? "#FFFFFF" : "",
                                    }}
                                >
                                    <span style={{
                                        fontWeight: "500",
                                        marginLeft: "14px",
                                        color: activeSubMenuItem?.includes("/AssetCreationView") ? "#605BFF" : "#000000",
                                    }}>View Asset</span>
                                </MenuItem>
                            </Link>

                            {userScreens["Asset Management"]?.Add && (
                                <Link to="/AssetCreationAdd?id=0&view=0" className='no-underline' onClick={() => handleSubMenuItemClick("/AssetCreationAdd?id=0&view=0")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/AssetCreationAdd") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/AssetCreationAdd") ? "#605BFF" : "#000000",
                                        }}>Create Asset</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Asset Management"]?.Edit && (
                                <Link to="/FinancialTransaction" className='no-underline' onClick={() => handleSubMenuItemClick("/FinancialTransaction")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/FinancialTransaction") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/FinancialTransaction") ? "#605BFF" : "#000000",
                                        }}>Financial Transaction</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Asset Management"]?.Edit && (
                                <Link to="/CheckOut" className='no-underline' onClick={() => handleSubMenuItemClick("/CheckOut")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/CheckOut") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/CheckOut") ? "#605BFF" : "#000000",
                                        }}>Check Out</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Asset Management"]?.Edit && (
                                <Link to="/CheckIn" className='no-underline' onClick={() => handleSubMenuItemClick("/CheckIn")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/CheckIn") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/CheckIn") ? "#605BFF" : "#000000",
                                        }}>Check In</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Asset Management"]?.Edit && (
                                <Link to="/Transfer" className='no-underline' onClick={() => handleSubMenuItemClick("/Transfer")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Transfer") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/Transfer") ? "#605BFF" : "#000000",
                                        }}>Transfer</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Asset Management"]?.Edit && (
                                <Link to="/AuditList" className='no-underline' onClick={() => handleSubMenuItemClick("/AuditList")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/AuditList") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/AuditList") ? "#605BFF" : "#000000",
                                        }}>Audit</span>
                                    </MenuItem>
                                </Link>
                            )}
                        </SubMenu>
                    )}

                    {(userScreens["Maintenance"]?.View || userScreens["Warranties"]?.View || userScreens["Service Request"]?.View || userScreens["Insurance"]?.View) && (
                        <SubMenu
                            label={
                                <span style={{
                                    display: 'flex', alignItems: 'center', color:
                                        activeSubMenuItem?.includes("/ProcessFormConfigurationInsurance") ||
                                            activeSubMenuItem === "/ProcessFormConfigurationMaintenance" ||
                                            activeSubMenuItem === "/ProcessFormConfigurationServiceRequest" ||
                                            activeSubMenuItem?.includes("/ProcessFormConfigurationWarranty") ? "#FFFFFF" : "#000000"
                                }}>
                                    <img
                                        src={HomeIcon}
                                        alt='InboxIcon'
                                        style={{
                                            marginRight: '10px',
                                            paddingBottom: '5px',
                                            filter:
                                                activeSubMenuItem?.includes("/ProcessFormConfigurationInsurance") ||
                                                    activeSubMenuItem === "/ProcessFormConfigurationMaintenance" ||
                                                    activeSubMenuItem === "/ProcessFormConfigurationServiceRequest" ||
                                                    activeSubMenuItem?.includes("/ProcessFormConfigurationWarranty") ? "invert(1)" : "invert(0)"
                                        }}
                                    />
                                    Inbox
                                </span>
                            }
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color:
                                    activeSubMenuItem?.includes("/ProcessFormConfigurationInsurance") ||
                                        activeSubMenuItem === "/ProcessFormConfigurationMaintenance" ||
                                        activeSubMenuItem === "/ProcessFormConfigurationServiceRequest" ||
                                        activeSubMenuItem?.includes("/ProcessFormConfigurationWarranty") ? "#FFFFFF" : "#000000",
                                background:
                                    activeSubMenuItem?.includes("/ProcessFormConfigurationInsurance") ||
                                        activeSubMenuItem === "/ProcessFormConfigurationMaintenance" ||
                                        activeSubMenuItem === "/ProcessFormConfigurationServiceRequest" ||
                                        activeSubMenuItem?.includes("/ProcessFormConfigurationWarranty") ? "#605BFF" : "",
                            }}
                            defaultOpen={isSubMenuOpenInbox}
                        >

                            {userScreens["Insurance"]?.View && (
                                <Link to="/ProcessFormConfigurationInsurance" className='no-underline' onClick={() => handleSubMenuItemClick("/ProcessFormConfigurationInsurance")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/ProcessFormConfigurationInsurance") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/ProcessFormConfigurationInsurance") ? "#605BFF" : "#000000",
                                        }}>Insurance ({allNearlyExpiryInsurance})</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Maintenance"]?.View && (
                                <Link to="/ProcessFormConfigurationMaintenance" className='no-underline' onClick={() => handleSubMenuItemClick("/ProcessFormConfigurationMaintenance")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/ProcessFormConfigurationMaintenance") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/ProcessFormConfigurationMaintenance") ? "#605BFF" : "#000000",
                                        }}>Maintenance ({assetMaintenanceCount})</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Service Request"]?.View && (
                                <Link to="/ProcessFormConfigurationServiceRequest" className='no-underline' onClick={() => handleSubMenuItemClick("/ProcessFormConfigurationServiceRequest")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/ProcessFormConfigurationServiceRequest") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/ProcessFormConfigurationServiceRequest") ? "#605BFF" : "#000000",
                                        }}>Service Request ({assetServiceRequestCount})</span>
                                    </MenuItem>
                                </Link>
                            )}

                            {userScreens["Warranties"]?.View && (
                                <Link to="/ProcessFormConfigurationWarranty" className='no-underline' onClick={() => handleSubMenuItemClick("/ProcessFormConfigurationWarranty")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/ProcessFormConfigurationWarranty") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "14px",
                                            color: activeSubMenuItem?.includes("/ProcessFormConfigurationWarranty") ? "#605BFF" : "#000000",
                                        }}>Warranty ({allNearlyExpiryWarranty})</span>
                                    </MenuItem>
                                </Link>
                            )}

                        </SubMenu>
                    )}

                    {userScreens["Asset Management"]?.Import && (
                        <SubMenu
                            label={
                                <span style={{
                                    display: 'flex', alignItems: 'center', color:
                                        activeSubMenuItem?.includes("/DataImport") ||
                                            activeSubMenuItem?.includes("/ImportHistory") ? "#FFFFFF" : "#000000"
                                }}>
                                    <img
                                        src={SettingsIcon}
                                        alt='SettingsIcon'
                                        style={{
                                            marginRight: '10px',
                                            paddingBottom: '5px',
                                            filter:
                                                activeSubMenuItem?.includes("/DataImport") ||
                                                    activeSubMenuItem?.includes("/ImportHistory") ? "invert(0)" : "invert(1)"
                                        }}
                                    />
                                    Import
                                </span>
                            }
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color:
                                    activeSubMenuItem?.includes("/DataImport") ||
                                        activeSubMenuItem?.includes("/ImportHistory") ? "#FFFFFF" : "#000000",
                                background:
                                    activeSubMenuItem?.includes("/DataImport") ||
                                        activeSubMenuItem?.includes("/ImportHistory") ? "#605BFF" : "",
                            }}
                            defaultOpen={isSubMenuOpenImport}
                        >

                            <Link to="/DataImport" className='no-underline' onClick={() => handleSubMenuItemClick("/DataImport")}>
                                <MenuItem
                                    className="menuItem1 fontweightSemiBold"
                                    style={{
                                        fontWeight: "500",
                                        background: activeSubMenuItem?.includes("/DataImport") ? "#FFFFFF" : "",
                                    }}
                                >
                                    <span style={{
                                        fontWeight: "500",
                                        marginLeft: "14px",
                                        color: activeSubMenuItem?.includes("/DataImport") ? "#605BFF" : "#000000",
                                    }}>Data Import</span>
                                </MenuItem>
                            </Link>
                            <Link to="/ImportHistory" className='no-underline' onClick={() => handleSubMenuItemClick("/ImportHistory")}>
                                <MenuItem
                                    className="menuItem1 fontweightSemiBold"
                                    style={{
                                        fontWeight: "500",
                                        background: activeSubMenuItem?.includes("/ImportHistory") ? "#FFFFFF" : "",
                                    }}
                                >
                                    <span style={{
                                        fontWeight: "500",
                                        marginLeft: "14px",
                                        color: activeSubMenuItem?.includes("/ImportHistory") ? "#605BFF" : "#000000",
                                    }}>Import History</span>
                                </MenuItem>
                            </Link>
                        </SubMenu>
                    )}

                    {userScreens["Asset Management"]?.Export && (
                        <SubMenu
                            label={
                                <span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: activeSubMenuItem?.includes("/Reports/Asset/AssetIdReport") ||
                                        activeSubMenuItem?.includes("/Reports/Asset/AssetCategoryReport") ||
                                        activeSubMenuItem?.includes("/Reports/Asset/AssetDepartmentReport") ||
                                        activeSubMenuItem?.includes("/Reports/Asset/AssetUnitBranchReport") ||
                                        activeSubMenuItem?.includes("/Reports/Asset/AssetLinkedReport") ||
                                        activeSubMenuItem?.includes("/Reports/Status/Repaired") ||
                                        activeSubMenuItem?.includes("/Reports/Status/Broken") ||
                                        activeSubMenuItem?.includes("/Reports/Status/Disposed") ||
                                        activeSubMenuItem?.includes("/Reports/Status/Soled") ||
                                        activeSubMenuItem?.includes("/Reports/Status/Lost") ||
                                        activeSubMenuItem?.includes("/Reports/Status/Donated") ||
                                        activeSubMenuItem?.includes("/Reports/CheckOut/Person") ||
                                        activeSubMenuItem?.includes("/Reports/CheckOut/Asset") ||
                                        activeSubMenuItem?.includes("/Reports/CheckOut/Due") ||
                                        activeSubMenuItem?.includes("/Reports/CheckOut/PastDue") ||
                                        activeSubMenuItem?.includes("/Reports/CheckOut/SiteAndLocation") ||
                                        activeSubMenuItem?.includes("/Reports/CheckOut/TimeFrame") ||
                                        activeSubMenuItem?.includes("/Reports/Maintenance/Schedule") ||
                                        activeSubMenuItem?.includes("/Reports/Maintenance/Asset") ||
                                        activeSubMenuItem?.includes("/Reports/Maintenance/Complete") ||
                                        activeSubMenuItem?.includes("/Reports/Maintenance/Due") ||
                                        activeSubMenuItem?.includes("/Reports/Insurance/Insurance") ||
                                        activeSubMenuItem?.includes("/Reports/Insurance/Asset")

                                        ? "#FFFFFF" : "#000000"
                                }}>
                                    <img
                                        src={SettingsIcon}
                                        alt='SettingsIcon'
                                        style={{
                                            marginRight: '10px',
                                            paddingBottom: '5px',
                                            filter: activeSubMenuItem?.includes("/Reports/Asset/AssetIdReport") ||
                                                activeSubMenuItem?.includes("/Reports/Asset/AssetCategoryReport") ||
                                                activeSubMenuItem?.includes("/Reports/Asset/AssetDepartmentReport") ||
                                                activeSubMenuItem?.includes("/Reports/Asset/AssetUnitBranchReport") ||
                                                activeSubMenuItem?.includes("/Reports/Asset/AssetLinkedReport") ||
                                                activeSubMenuItem?.includes("/Reports/Status/Repaired") ||
                                                activeSubMenuItem?.includes("/Reports/Status/Broken") ||
                                                activeSubMenuItem?.includes("/Reports/Status/Disposed") ||
                                                activeSubMenuItem?.includes("/Reports/Status/Soled") ||
                                                activeSubMenuItem?.includes("/Reports/Status/Lost") ||
                                                activeSubMenuItem?.includes("/Reports/Status/Donated") ||
                                                activeSubMenuItem?.includes("/Reports/CheckOut/Person") ||
                                                activeSubMenuItem?.includes("/Reports/CheckOut/Asset") ||
                                                activeSubMenuItem?.includes("/Reports/CheckOut/Due") ||
                                                activeSubMenuItem?.includes("/Reports/CheckOut/PastDue") ||
                                                activeSubMenuItem?.includes("/Reports/CheckOut/SiteAndLocation") ||
                                                activeSubMenuItem?.includes("/Reports/CheckOut/TimeFrame") ||
                                                activeSubMenuItem?.includes("/Reports/Maintenance/Schedule") ||
                                                activeSubMenuItem?.includes("/Reports/Maintenance/Asset") ||
                                                activeSubMenuItem?.includes("/Reports/Maintenance/Complete") ||
                                                activeSubMenuItem?.includes("/Reports/Maintenance/Due") ||
                                                activeSubMenuItem?.includes("/Reports/Insurance/Insurance") ||
                                                activeSubMenuItem?.includes("/Reports/Insurance/Asset")
                                                ? "invert(0)" : "invert(1)"
                                        }}
                                    />
                                    Reports
                                </span>
                            }
                            className="menuItem1 fontweightSemiBold"
                            style={{
                                fontWeight: "500",
                                color: activeSubMenuItem?.includes("/Reports/Asset/AssetIdReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetCategoryReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetDepartmentReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetUnitBranchReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetLinkedReport") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Repaired") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Broken") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Disposed") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Soled") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Lost") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Donated") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/Person") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/Asset") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/Due") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/PastDue") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/SiteAndLocation") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/TimeFrame") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Schedule") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Asset") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Complete") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Due") ||
                                    activeSubMenuItem?.includes("/Reports/Insurance/Insurance") ||
                                    activeSubMenuItem?.includes("/Reports/Insurance/Asset")
                                    ? "#FFFFFF" : "#000000",
                                background: activeSubMenuItem?.includes("/Reports/Asset/AssetIdReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetCategoryReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetDepartmentReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetUnitBranchReport") ||
                                    activeSubMenuItem?.includes("/Reports/Asset/AssetLinkedReport") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Repaired") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Broken") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Disposed") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Soled") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Lost") ||
                                    activeSubMenuItem?.includes("/Reports/Status/Donated") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/Person") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/Asset") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/Due") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/PastDue") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/SiteAndLocation") ||
                                    activeSubMenuItem?.includes("/Reports/CheckOut/TimeFrame") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Schedule") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Asset") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Complete") ||
                                    activeSubMenuItem?.includes("/Reports/Maintenance/Due") ||
                                    activeSubMenuItem?.includes("/Reports/Insurance/Insurance") ||
                                    activeSubMenuItem?.includes("/Reports/Insurance/Asset")
                                    ? "#605BFF" : "",
                            }}
                            defaultOpen={isSubMenuOpenReports}
                        >
                            <SubMenu
                                label={<span style={{ marginLeft: '20px', color: activeSubMenuItem?.includes("/Assets") ? "#605BFF" : "#000000" }}>Assets</span>}
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("/Assets") ? "#FFFFFF" : "",
                                }}
                                defaultOpen={isSubMenuOpenReports}
                            >
                                <Link to="/Reports/Asset/AssetIdReport" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Asset/AssetIdReport")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Asset/AssetIdReport") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Asset/AssetIdReport") ? "#605BFF" : "#000000",
                                        }}>Asset ID</span>
                                    </MenuItem>
                                </Link>

                                <Link to="/Reports/Asset/AssetCategoryReport" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Asset/AssetCategoryReport")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Asset/AssetCategoryReport") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Asset/AssetCategoryReport") ? "#605BFF" : "#000000",
                                        }}>Asset Category</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Asset/AssetDepartmentReport" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Asset/AssetDepartmentReport")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Asset/AssetDepartmentReport") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Asset/AssetDepartmentReport") ? "#605BFF" : "#000000",
                                        }}>Asset Department</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Asset/AssetUnitBranchReport" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Asset/AssetUnitBranchReport")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Asset/AssetUnitBranchReport") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            whiteSpace: "nowrap",
                                            color: activeSubMenuItem?.includes("/Reports/Asset/AssetUnitBranchReport") ? "#605BFF" : "#000000",
                                        }}>Asset Unit/Branch</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Asset/AssetLinkedReport" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Asset/AssetLinkedReport")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Asset/AssetLinkedReport") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            whiteSpace: "nowrap",
                                            color: activeSubMenuItem?.includes("/Reports/Asset/AssetLinkedReport") ? "#605BFF" : "#000000",
                                        }}>Asset Linked</span>
                                    </MenuItem>
                                </Link>
                            </SubMenu>
                            <SubMenu
                                label={<span style={{ marginLeft: '20px', color: activeSubMenuItem?.includes("/Status") ? "#605BFF" : "#000000" }}>Status</span>}
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("/Status") ? "#FFFFFF" : "",
                                }}
                                defaultOpen={isSubMenuOpenReports}
                            >
                                <Link to="/Reports/Status/Repaired" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Status/Repaired")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Status/Repaired") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Status/Repaired") ? "#605BFF" : "#000000",
                                        }}>Repaired</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Status/Broken" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Status/Broken")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Status/Broken") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Status/Broken") ? "#605BFF" : "#000000",
                                        }}>Broken</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Status/Disposed" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Status/Disposed")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Status/Disposed") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Status/Disposed") ? "#605BFF" : "#000000",
                                        }}>Disposed</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Status/Soled" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Status/Soled")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Status/Soled") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Status/Soled") ? "#605BFF" : "#000000",
                                        }}>Soled</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Status/Lost" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Status/Lost")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Status/Lost") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Status/Lost") ? "#605BFF" : "#000000",
                                        }}>Lost</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Status/Donated" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Status/Donated")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Status/Donated") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Status/Donated") ? "#605BFF" : "#000000",
                                        }}>Donated</span>
                                    </MenuItem>
                                </Link>
                            </SubMenu>
                            <SubMenu
                                label={<span style={{ marginLeft: '20px', color: activeSubMenuItem?.includes("Reports/CheckOut") ? "#605BFF" : "#000000" }}>Check-Out</span>}
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("Reports/CheckOut") ? "#FFFFFF" : "",
                                }}
                                defaultOpen={isSubMenuOpenReports}
                            >
                                <Link to="/Reports/CheckOut/Person" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/CheckOut/Person")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/CheckOut/Person") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/CheckOut/Person") ? "#605BFF" : "#000000",
                                        }}>Person</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/CheckOut/Asset" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/CheckOut/Asset")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/CheckOut/Asset") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/CheckOut/Asset") ? "#605BFF" : "#000000",
                                        }}>Asset</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/CheckOut/Due" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/CheckOut/Due")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/CheckOut/Due") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/CheckOut/Due") ? "#605BFF" : "#000000",
                                        }}>Due</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/CheckOut/PastDue" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/CheckOut/PastDue")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/CheckOut/PastDue") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/CheckOut/PastDue") ? "#605BFF" : "#000000",
                                        }}>Past Due</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/CheckOut/SiteAndLocation" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/CheckOut/SiteAndLocation")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/CheckOut/SiteAndLocation") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/CheckOut/SiteAndLocation") ? "#605BFF" : "#000000",
                                        }}>Site/Location</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/CheckOut/TimeFrame" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/CheckOut/TimeFrame")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/CheckOut/TimeFrame") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/CheckOut/TimeFrame") ? "#605BFF" : "#000000",
                                        }}>Time Frame </span>
                                    </MenuItem>
                                </Link>
                            </SubMenu>
                            <SubMenu
                                label={<span style={{ marginLeft: '20px', color: activeSubMenuItem?.includes("Reports/Maintenance") ? "#605BFF" : "#000000" }}>Maintenance</span>}
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("Reports/Maintenance") ? "#FFFFFF" : "",
                                }}
                                defaultOpen={isSubMenuOpenReports}
                            >
                                <Link to="/Reports/Maintenance/Schedule" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Maintenance/Schedule")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Maintenance/Schedule") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Maintenance/Schedule") ? "#605BFF" : "#000000",
                                        }}>Schedule</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Maintenance/Asset" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Maintenance/Asset")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Maintenance/Asset") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Maintenance/Asset") ? "#605BFF" : "#000000",
                                        }}>Asset</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Maintenance/Complete" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Maintenance/Complete")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Maintenance/Complete") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Maintenance/Complete") ? "#605BFF" : "#000000",
                                        }}>Complete</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Maintenance/Due" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Maintenance/Due")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Maintenance/Due") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Maintenance/Due") ? "#605BFF" : "#000000",
                                        }}>Due</span>
                                    </MenuItem>
                                </Link>
                            </SubMenu>
                            <SubMenu
                                label={<span style={{ marginLeft: '20px', color: activeSubMenuItem?.includes("Reports/Insurance") ? "#605BFF" : "#000000" }}>Insurance</span>}
                                className="menuItem1 fontweightSemiBold"
                                style={{
                                    fontWeight: "500",
                                    background: activeSubMenuItem?.includes("Reports/Insurance") ? "#FFFFFF" : "",
                                }}
                                defaultOpen={isSubMenuOpenReports}
                            >
                                <Link to="/Reports/Insurance/Insurance" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Insurance/Insurance")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Insurance/Insurance") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Insurance/Insurance") ? "#605BFF" : "#000000",
                                        }}>Insurance ID</span>
                                    </MenuItem>
                                </Link>
                                <Link to="/Reports/Insurance/Asset" className='no-underline' onClick={() => handleSubMenuItemClick("/Reports/Insurance/Asset")}>
                                    <MenuItem
                                        className="menuItem1 fontweightSemiBold"
                                        style={{
                                            fontWeight: "500",
                                            background: activeSubMenuItem?.includes("/Reports/Insurance/Asset") ? "#FFFFFF" : "",
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: "500",
                                            marginLeft: "34px",  // Increase margin for nested items
                                            color: activeSubMenuItem?.includes("/Reports/Insurance/Asset") ? "#605BFF" : "#000000",
                                        }}>Asset ID</span>
                                    </MenuItem>
                                </Link>
                            </SubMenu>
                        </SubMenu>
                    )}

                </Menu>
            </Sidebar>
        </div>
    );
};

SideMenuNew.propTypes = {
    activeMenuItem: PropTypes.string.isRequired,
    setActiveMenuItem: PropTypes.func.isRequired,
};

export default SideMenuNew;
