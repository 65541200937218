import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import GetUserAccess from '../../../CommomPages/GetUserAccess';
import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import {
    getCategory,
    deleteCategory,
    createCategory,
    updateCategory,
    getSubCategory,
    createSubCategory,
    updateSubCategory,
    deleteSubCategory,
    getBrand,
    createBrand,
    updateBrand,
    deleteBrand,
} from '../../../Services/AssetCategoriesAndBrandService';
import { exportDataCategory, exportDataSubCategory, exportDataBrand } from './ExportAssetCategoriesAndBrand';

import './AssetCategoriesAndBrandCss.css';

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import nodataFound from '../../../assets/img/nodatafound.png';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#B0ACAC',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};


const AssetCategoriesAndBrand = () => {
    const location = useLocation();
    const [userScreens, setUserScreens] = useState({});

    const [tabActive, setTabActive] = useState('AssetCategory');
    const [activeScreen, setActiveScreen] = useState('Asset');
    const [tabStatusActive, setTabStatusActive] = useState('Asset Category');
    const [isLoading, setIsLoading] = useState(true);

    // Category Section
    const [categoryArray, setCategoryArray] = useState([]);
    const [pageCategory, setPageCategory] = useState(0);
    const [rowsCategoryPerPage, setRowsCategoryPerPage] = useState(5);
    const [categoryID, setCategoryID] = useState(0);
    const [isCategoryView, setIsCategoryView] = useState(false);
    const [searchQueryCategory, setSearchQueryCategory] = useState('');
    const fileCategoryInputRef = useRef(null);
    const {
        register: registerCategory,
        handleSubmit: handleSubmitCategory,
        formState: { errors: errorsCategory },
        setValue: setValueCategory,
        reset: resetCategory,
        watch: watchCategory
    } = useForm({
        defaultValues: {
            categoryName: '',
            categoryCode: '',
            categoryDescription: '',
            categoryStatus: true,
        }
    });

    const statusWatchCategory = watchCategory('categoryStatus');

    // Sub Category Section
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [pageSubCategory, setPageSubCategory] = useState(0);
    const [rowsSubCategoryPerPage, setRowsSubCategoryPerPage] = useState(5);
    const [subCategoryID, setSubCategoryID] = useState(0);
    const [isSubCategoryView, setIsSubCategoryView] = useState(false);
    const [searchQuerySubCategory, setSearchQuerySubCategory] = useState('');
    const [categorySelectID, setCategorySelectID] = useState(null);
    const fileSubCategoryInputRef = useRef(null);
    const {
        register: registerSubCategory,
        handleSubmit: handleSubmitSubCategory,
        formState: { errors: errorsSubCategory },
        setValue: setValueSubCategory,
        reset: resetSubCategory,
        watch: watchSubCategory,
        control: controlSUBCategory,
        trigger: triggerSubCategory
    } = useForm({
        defaultValues: {
            categorySelectID: null,
            subCategoryName: '',
            subCategoryCode: '',
            subCategoryDescription: '',
            subCategoryStatus: true,
        }
    });

    const statusWatchSubCategory = watchSubCategory('subCategoryStatus');

    // Add useEffect to trigger validation when the form loads
    useEffect(() => {
        triggerSubCategory('categorySelectID');
    }, [triggerSubCategory]);


    // Brand Section
    const [brandArray, setBrandArray] = useState([]);
    const [pageBrand, setPageBrand] = useState(0);
    const [rowsBrandPerPage, setRowsBrandPerPage] = useState(5);
    const [brandID, setBrandID] = useState(0);
    const [isBrandView, setIsBrandView] = useState(false);
    const [searchQueryBrand, setSearchQueryBrand] = useState('');
    const [brandCategorySelectID, setBrandCategorySelectID] = useState(null);
    const [brandSubCategorySelectID, setBrandSubCategorySelectID] = useState(null);
    const fileBrandInputRef = useRef(null);
    const {
        register: registerBrand,
        handleSubmit: handleSubmitBrand,
        formState: { errors: errorsBrand },
        setValue: setValueBrand,
        reset: resetBrand,
        watch: watchBrand,
        control: controlBrand,
        trigger: triggerBrand
    } = useForm({
        defaultValues: {
            brandCategorySelectID: null,
            brandSubCategorySelectID: null,
            brandName: '',
            brandDescription: '',
            brandStatus: true,
            brandLevel: 'Level 1',
        }
    });

    const statusWatchBrand = watchBrand('brandStatus');

    // Add useEffect to trigger validation when the form loads
    useEffect(() => {
        triggerBrand('brandCategorySelectID');
        triggerBrand('brandSubCategorySelectID');
    }, [triggerBrand]);

    useEffect(() => {
        fetchUserScreens();
        const queryParams = getQueryParams(location.search);
        const tabName = queryParams.get('tab');
        selectedTab(tabName);
        const fetchData = async () => {
            await getCategoryDetails();
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const getCategoryDetails = async () => {
        setIsLoading(true);
        const CategoryData = await getCategory();
        const modifiedCategoryData = CategoryData?.map((category, index) => ({
            ...category,
            Rowid: index + 1
        }));
        setCategoryArray(modifiedCategoryData);
        await getSubCategoryDetails(modifiedCategoryData);
        setIsLoading(false);
        setPageCategory(0);
    };

    const getSubCategoryDetails = async (_CategoryValue) => {
        const SubCategoryData = await getSubCategory();
        const modifiedSubCategoryData = SubCategoryData?.map((subCategory, index) => ({
            categoryName: _CategoryValue.find(x => x.id === subCategory.categoryId)?.categoryName,
            ...subCategory,
            Rowid: index + 1
        }));
        setSubCategoryArray(modifiedSubCategoryData);
        await getBrandDetails(modifiedSubCategoryData);
        setIsLoading(false);
        setPageSubCategory(0);
    };

    const getBrandDetails = async (_SubCategoryValue) => {
        const BrandData = await getBrand();
        const modifiedBrandData = BrandData?.map((brand, index) => ({
            categoryName: _SubCategoryValue.find(x => x.id === brand.categoryId)?.categoryName,
            subCategoryName: _SubCategoryValue.find(x => x.id === brand.subCategoryId)?.subCategoryName,
            ...brand,
            Rowid: index + 1
        }));
        setBrandArray(modifiedBrandData);
        setPageBrand(0);
    };

    const selectedTab = (tabValue) => {
        setTabActive(tabValue);
        if (tabValue === "AssetCategory") {
            setTabStatusActive("Asset Category")
        }
        else if (tabValue === "SubCategory") {
            setTabStatusActive("Sub Category")
        }
        else if (tabValue === "Brand") {
            setTabStatusActive("Brand")
        }
    };


    // Category Section
    const handleCategorySubmit = async (data) => {
        if (!userScreens["Category"]?.Add || !userScreens["Category"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const { categoryName, categoryCode, categoryDescription, categoryStatus } = data;
        const categoryExists = categoryArray.some(x => x.categoryName === categoryName);
        const isUpdating = categoryID !== 0;

        if (isUpdating) {
            const currentCategory = categoryArray.find(x => x.id === categoryID);
            if (categoryExists && currentCategory.categoryName !== categoryName) {
                toast.error("Category name already exists.");
                return;
            }
        } else if (categoryExists) {
            toast.error("Category name already exists.");
            return;
        }

        setIsLoading(true);
        const payload = {
            id: categoryID || 0,
            categoryName: categoryName?.trim(),
            categoryCode: categoryCode?.trim(),
            description: categoryDescription?.trim(),
            status: categoryStatus ? 'Active' : 'Inactive'
        };
        const response = isUpdating ? await updateCategory(payload) : await createCategory(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getCategoryDetails();
            toast.success(`Category ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            if (isUpdating) setPageCategory(0);
            closeCategory();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const addNewCategory = () => {
        if (!userScreens["Category"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsCategoryView(false);
        setActiveScreen("CategoryEdit");
        resetCategory();
        setCategoryID(0);
    };

    const editCategory = (categoryID) => {
        if (!userScreens["Category"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsCategoryView(false);
        setCategoryID(categoryID);
        const categoryData = categoryArray.find(x => x.id === categoryID);
        setValueCategory('categoryName', categoryData.categoryName);
        setValueCategory('categoryCode', categoryData.categoryCode);
        setValueCategory('categoryDescription', categoryData.description);
        setValueCategory('categoryStatus', categoryData.status === 'Active');
        setActiveScreen("CategoryEdit");
    };

    const viewCategory = (categoryID) => {
        if (!userScreens["Category"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsCategoryView(true);
        setCategoryID(categoryID);
        const categoryData = categoryArray.find(x => x.id === categoryID);
        setValueCategory('categoryName', categoryData.categoryName);
        setValueCategory('categoryCode', categoryData.categoryCode);
        setValueCategory('categoryDescription', categoryData.description);
        setValueCategory('categoryStatus', categoryData.status === 'Active');
        setActiveScreen("CategoryEdit");
    };

    const DeleteCategory = async (categoryID) => {
        if (!userScreens["Category"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            const subCategoryData = await getSubCategory();
            const isAssociated = subCategoryData.some(subCat => subCat.categoryId === categoryID);
            if (isAssociated) {
                toast.error(`Cannot delete the Category as it is associated with one or more sub-Categorys.`);
                return;
            }

            const brandData = await getBrand();
            const isAssociatedBrand = brandData.some(brand => brand.categoryId === categoryID);
            if (isAssociatedBrand) {
                toast.error(`Cannot delete the Category as it is associated with one or more Brands.`);
                return;
            }

            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteCategory(categoryID);
                if (response === "Deleted") {
                    toast.success("Category Deleted Successfully");
                    getCategoryDetails();
                } else {
                    toast.error(response);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting category:", error);
            toast.error(`An error occurred while deleting the category.`);
        } finally {
            setIsLoading(false);
        }
    };

    const closeCategory = () => {
        setIsCategoryView(false);
        resetCategory();
        setActiveScreen("Asset");
    };

    const handleCategoryImportClick = () => {
        if (!userScreens["Category"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        fileCategoryInputRef.current.click();
    };

    const handleCategoryFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let categoryArrayData = [];
            let categoryErrorArrayCount = [];

            jsonData.forEach((e) => {
                const categoryExists = categoryArray.some(x => x?.categoryName === e.CategoryName);
                if (categoryExists) {
                    toast.error(`${e.CategoryName} already exists.`);
                    categoryErrorArrayCount.push(e);
                } else {
                    categoryArrayData.push(e);
                }
            });

            event.target.value = null;
            if (categoryArrayData.length > 0 && categoryErrorArrayCount.length === 0) {
                uploadCategoryData(categoryArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadCategoryData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data.map(async (e) => {
                const payload = {
                    id: 0,
                    categoryName: e.CategoryName?.trim(),
                    categoryCode: e.CategoryCode?.trim(),
                    description: e.CategoryDescription?.trim(),
                    status: e.CategoryStatus ? 'Active' : 'Inactive'
                }
                const response = await createCategory(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            getCategoryDetails();
            closeCategory();
            setPageCategory(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const exportCategory = (event) => {
        if (!userScreens["Category"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportCategory(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportCategory = (format) => {
        exportDataCategory(categoryArray, format);
    };


    // Sub Category Section
    const handleSubCategorySubmit = async (data) => {
        if (!userScreens["Sub Category"]?.Add || !userScreens["Sub Category"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const { subCategoryName, subCategoryCode, subCategoryDescription, subCategoryStatus } = data;
        const subCategoryExists = subCategoryArray.some(x => x.subCategoryName === subCategoryName && x.categoryId === categorySelectID);
        const isUpdating = subCategoryID !== 0;

        if (isUpdating) {
            const currentSubCategory = subCategoryArray.find(x => x.id === subCategoryID);
            if (subCategoryExists && currentSubCategory.subCategoryName !== subCategoryName) {
                toast.error("Sub-Category name already exists.");
                return;
            }
        } else if (subCategoryExists) {
            toast.error("Sub-Category name already exists.");
            return;
        }

        setIsLoading(true);
        const payload = {
            id: subCategoryID || 0,
            categoryId: categorySelectID || 0,
            subCategoryName: subCategoryName?.trim(),
            subCategoryCode: subCategoryCode?.trim(),
            description: subCategoryDescription?.trim(),
            status: subCategoryStatus ? 'Active' : 'Inactive'
        };

        const response = isUpdating ? await updateSubCategory(payload) : await createSubCategory(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getCategoryDetails();
            toast.success(`Sub-Category ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            closeSubCategory();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const addNewSubCategory = () => {
        if (!userScreens["Sub Category"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsSubCategoryView(false);
        setActiveScreen("SubCategoryEdit");
        resetSubCategory();
        setCategorySelectID(0);
        setSubCategoryID(0);
    };

    const editSubCategory = (subCategoryID) => {
        if (!userScreens["Sub Category"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsSubCategoryView(false);
        setSubCategoryID(subCategoryID);
        const subCategoryData = subCategoryArray.find(x => x.id === subCategoryID);
        const categoryData = categoryArray.find(x => x.id === subCategoryData?.categoryId);
        setCategorySelectID(categoryData?.id);
        setValueSubCategory('categorySelectID', categoryData?.id);
        setValueSubCategory('subCategoryName', subCategoryData?.subCategoryName);
        setValueSubCategory('subCategoryCode', subCategoryData?.subCategoryCode);
        setValueSubCategory('subCategoryDescription', subCategoryData?.description);
        setValueSubCategory('subCategoryStatus', subCategoryData?.status === 'Active');
        setActiveScreen("SubCategoryEdit");
    };

    const viewSubCategory = (subCategoryID) => {
        if (!userScreens["Sub Category"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsSubCategoryView(true);
        setSubCategoryID(subCategoryID);
        const subCategoryData = subCategoryArray.find(x => x.id === subCategoryID);
        const categoryData = categoryArray.find(x => x.id === subCategoryData?.categoryId);
        setCategorySelectID(categoryData?.id);
        setValueSubCategory('categorySelectID', categoryData?.id);
        setValueSubCategory('subCategoryName', subCategoryData?.subCategoryName);
        setValueSubCategory('subCategoryCode', subCategoryData?.subCategoryCode);
        setValueSubCategory('subCategoryDescription', subCategoryData?.description);
        setValueSubCategory('subCategoryStatus', subCategoryData?.status === 'Active');
        setActiveScreen("SubCategoryEdit");
    };

    const DeleteSubCategory = async (subCategoryID) => {
        if (!userScreens["Sub Category"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            const brandData = await getBrand();
            const isAssociatedBrand = brandData.some(brand => brand.subCategoryId === subCategoryID);
            if (isAssociatedBrand) {
                toast.error(`Cannot delete the Sub-Category as it is associated with one or more Brands.`);
                return;
            }

            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteSubCategory(subCategoryID);
                if (response === "Deleted") {
                    getCategoryDetails();
                    toast.success("Sub Category Deleted Sucessfully");
                } else {
                    toast.error(response);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting Sub-Category:", error);
            toast.error(`An error occurred while deleting the Sub-Category.`);
        } finally {
            setIsLoading(false);
        }
    };

    const closeSubCategory = () => {
        setIsSubCategoryView(false);
        resetSubCategory();
        setActiveScreen("Asset");
    };

    const handleSubCategoryImportClick = () => {
        if (!userScreens["Sub Category"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        fileSubCategoryInputRef.current.click();
    };

    const handleSubCategoryFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let subCategoryArrayData = [];
            let subCategoryErrorArrayCount = [];

            jsonData.forEach((e) => {
                const categoryExists = categoryArray.find(x => x.categoryName === e.CategoryName);
                const subCategoryExists = subCategoryArray.some(x => x.subCategoryName === e.SubCategoryName && x.categoryId === categoryExists?.id);

                if (subCategoryExists) {
                    toast.error(`${e.SubCategoryName} already exists.`);
                    subCategoryErrorArrayCount.push(e);
                } else if (!categoryExists) {
                    toast.error(`Category name doesn't exist.`);
                    subCategoryErrorArrayCount.push(e);
                } else {
                    e.CategoryId = categoryExists.id;
                    subCategoryArrayData.push(e);
                }
            });

            event.target.value = null;
            if (subCategoryArrayData.length > 0 && subCategoryErrorArrayCount.length === 0) {
                await uploadSubCategoryData(subCategoryArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadSubCategoryData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data.map(async (e) => {
                const payload = {
                    id: 0,
                    categoryId: e.CategoryId || 0,
                    subCategoryName: e.SubCategoryName?.trim(),
                    subCategoryCode: e.SubCategoryCode?.trim(),
                    description: e.SubCategoryDescription?.trim(),
                    status: e.SubCategoryStatus ? 'Active' : 'Inactive'
                };
                const response = await createSubCategory(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            getCategoryDetails();
            closeSubCategory();
            setPageSubCategory(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const exportSubCategory = (event) => {
        if (!userScreens["Sub Category"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportSubCategory(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportSubCategory = (format) => {
        exportDataSubCategory(subCategoryArray, format);
    };


    // Brand Section
    const handleBrandSubmit = async (data) => {
        if (!userScreens["Brand"]?.Add || !userScreens["Brand"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const { brandName, modelName, brandDescription, brandStatus } = data;
        const brandExists = brandArray.some(x => x.brandName === brandName);
        const isUpdating = brandID !== 0;

        if (isUpdating) {
            const currentBrand = brandArray.find(x => x.id === brandID);
            if (brandExists && currentBrand.brandName !== brandName) {
                toast.error("Brand name already exists.");
                return;
            }
        } else if (brandExists) {
            toast.error("Brand name already exists.");
            return;
        }

        setIsLoading(true);

        const payload = {
            id: brandID || 0,
            brandName: brandName?.trim(),
            modelName: modelName?.trim(),
            categoryId: brandCategorySelectID || 0,
            subCategoryId: brandSubCategorySelectID || 0,
            description: brandDescription?.trim() || "",
            status: brandStatus ? 'Active' : 'Inactive'
        };
        console.log("payload in brand submit", payload);
        const response = isUpdating ? await updateBrand(payload) : await createBrand(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            getCategoryDetails();
            toast.success(`Brand ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            if (isUpdating) setPageBrand(0);
            closeBrand();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const addNewBrand = () => {
        if (!userScreens["Brand"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsBrandView(false);
        setActiveScreen("BrandEdit");
        resetBrand();
        setBrandID(0);
        setBrandCategorySelectID(0);
        setBrandSubCategorySelectID(0);
    };


    const editBrand = (brandID) => {
        if (!userScreens["Brand"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsBrandView(false);
        setBrandID(brandID);
        const brandData = brandArray.find(x => x.id === brandID);
        const brandCategoryData = categoryArray.find(x => x.id === brandData?.categoryId);
        const brandSubategoryData = subCategoryArray.find(x => x.id === brandData?.subCategoryId);
        setBrandCategorySelectID(brandCategoryData?.id);
        setBrandSubCategorySelectID(brandSubategoryData?.id);
        setValueBrand('brandCategorySelectID', brandCategoryData?.id);
        setValueBrand('brandSubCategorySelectID', brandSubategoryData?.id);
        setValueBrand('brandName', brandData.brandName);
        setValueBrand('modelName', brandData.modelName);
        setValueBrand('brandDescription', brandData.description);
        setValueBrand('brandStatus', brandData.status === 'Active');
        setActiveScreen("BrandEdit");
    };

    const viewBrand = (brandID) => {
        if (!userScreens["Brand"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsBrandView(true);
        setBrandID(brandID);
        const brandData = brandArray.find(x => x.id === brandID);
        const brandCategoryData = categoryArray.find(x => x.id === brandData?.categoryId);
        const brandSubategoryData = subCategoryArray.find(x => x.id === brandData?.subCategoryId);
        setBrandCategorySelectID(brandCategoryData?.id);
        setBrandSubCategorySelectID(brandSubategoryData?.id);
        setValueBrand('brandCategorySelectID', brandCategoryData?.id);
        setValueBrand('brandSubCategorySelectID', brandSubategoryData?.id);
        setValueBrand('brandName', brandData.brandName);
        setValueBrand('modelName', brandData.modelName);
        setValueBrand('brandDescription', brandData.description);
        setValueBrand('brandStatus', brandData.status === 'Active');
        setActiveScreen("BrandEdit");
    };

    const closeBrand = () => {
        setIsBrandView(false);
        resetBrand();
        setActiveScreen("Asset");
    };

    const DeleteBrand = async (brandID) => {
        if (!userScreens["Brand"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const response = await deleteBrand(brandID);
            if (response === "Deleted") {
                toast.success("Brand Deleted Successfully");
                getCategoryDetails();
            } else {
                toast.error(response);
            }
            setIsLoading(false);
        }
    };

    const handleBrandImportClick = () => {
        if (!userScreens["Brand"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        fileBrandInputRef.current.click();
    };

    const handleBrandFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let brandArrayData = [];
            let brandErrorArrayCount = [];

            jsonData.forEach((e) => {
                const categoryExists = categoryArray.find(x => x?.categoryName === e.CategoryName);
                if (!categoryExists) {
                    toast.error(`Category name doesn't exist.`);
                    brandErrorArrayCount.push(e);
                } else {
                    const subCategoryExists = subCategoryArray.find(x => x.subCategoryName === e.SubCategoryName && x.categoryId === categoryExists.id);
                    if (!subCategoryExists) {
                        toast.error(`Sub-Category name doesn't exist.`);
                        brandErrorArrayCount.push(e);
                    } else {
                        const brandExists = brandArray?.some(x => x.brandName === e.BrandName && x.subCategoryId === subCategoryExists.id);
                        if (brandExists) {
                            toast.error(`${e.BrandName} already exists.`);
                            brandErrorArrayCount.push(e);
                        } else {
                            const { brandCategoryID, brandSubCategoryID, brandName, modelName, brandDescription, brandStatus } = data;

                            brandArrayData.push({
                                id: brandID || 0,
                                categoryId: brandCategoryID || 0,
                                subCategoryId: brandSubCategoryID || 0,
                                brandName: brandName?.trim(),
                                modelName: modelName?.trim(),
                                description: brandDescription?.trim() || "",
                                status: brandStatus ? 'Active' : 'Inactive'
                            });
                        }
                    }
                }
            });

            event.target.value = null;
            if (brandArrayData.length > 0 && brandErrorArrayCount.length === 0) {
                uploadBrandData(brandArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadBrandData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data?.map(async (e) => {
                const payload = {
                    id: brandID || 0,
                    categoryId: e.categoryId || 0,
                    subCategoryId: e.subCategoryId || 0,
                    brandName: e.brandName?.trim() || "",
                    modelName: e.modelName?.trim(),
                    description: e.description?.trim(),
                    status: e.status ? 'Active' : 'Inactive'
                };
                const response = await createBrand(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            getCategoryDetails();
            closeBrand();
            setPageBrand(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const exportBrand = (event) => {
        if (!userScreens["Brand"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportBrand(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportBrand = (format) => {
        exportDataBrand(brandArray, format);
    };


    // Category Pagination
    const handleCategorySearchChange = (event) => {
        setSearchQueryCategory(event.target.value);
        setPageCategory(0);
    };

    const filteredCategory = categoryArray.filter((category) =>
        category?.categoryName?.toLowerCase()?.includes(searchQueryCategory?.toLowerCase()) ||
        category?.categoryCode?.toLowerCase()?.includes(searchQueryCategory?.toLowerCase()) ||
        category?.status?.toLowerCase()?.startsWith(searchQueryCategory?.toLowerCase())
    );

    const handleRowsPerPageChange = (event) => {
        setRowsCategoryPerPage(parseInt(event.target.value));
        setPageCategory(0);
    };

    const handleCategoryFirstPage = () => {
        handleCategoryChangePagePagination({ selected: 0 });
    };

    const handleCategoryLastPage = () => {
        handleCategoryChangePagePagination({ selected: Math.ceil(filteredCategory.length / rowsCategoryPerPage) - 1 });
    };

    const handleCategoryChangePagePagination = ({ selected }) => {
        setPageCategory(selected);
    };

    const totalCategory = filteredCategory?.length;
    const startCategoryEntry = pageCategory * rowsCategoryPerPage + 1;
    const endCategoryEntry = Math.min((pageCategory + 1) * rowsCategoryPerPage, totalCategory);


    // SubCategory Pagination
    const handleSubCategorySearchChange = (event) => {
        setSearchQuerySubCategory(event.target.value);
        setPageSubCategory(0);
    };


    const searchQuerySub = searchQuerySubCategory?.toLowerCase() || '';
    const filteredSubCategory = subCategoryArray.filter((subCategory) =>
        subCategory?.categoryName?.toLowerCase()?.includes(searchQuerySub) ||
        subCategory?.subCategoryName?.toLowerCase()?.includes(searchQuerySub) ||
        subCategory?.subCategoryCode?.toLowerCase()?.includes(searchQuerySub) ||
        subCategory?.status?.toLowerCase()?.startsWith(searchQuerySub)
    );

    const handleRowsPerpageSubCategoryChange = (event) => {
        setRowsSubCategoryPerPage(parseInt(event.target.value));
        setPageSubCategory(0);
    };

    const handleSubCategoryFirstPage = () => {
        handleSubCategoryChangePagePagination({ selected: 0 });
    };

    const handleSubCategoryLastPage = () => {
        handleSubCategoryChangePagePagination({ selected: Math.ceil(filteredSubCategory.length / rowsSubCategoryPerPage) - 1 });
    };

    const handleSubCategoryChangePagePagination = ({ selected }) => {
        setPageSubCategory(selected);
    };

    const totalSubCategory = filteredSubCategory?.length;
    const startSubCategoryEntry = pageSubCategory * rowsSubCategoryPerPage + 1;
    const endSubCategoryEntry = Math.min((pageSubCategory + 1) * rowsSubCategoryPerPage, totalSubCategory);


    // Brand Pagination
    const handleBrandSearchChange = (event) => {
        setSearchQueryBrand(event.target.value);
        setPageBrand(0);
    };


    const searchQuery = searchQueryBrand?.toLowerCase() || '';
    const filteredBrand = brandArray.filter((brand) =>
        brand?.categoryName?.toLowerCase()?.includes(searchQuery) ||
        brand?.subCategoryName?.toLowerCase()?.includes(searchQuery) ||
        brand?.brandName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        brand?.modelName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        brand?.status?.toLowerCase()?.startsWith(searchQuery?.toLowerCase())
    );

    const handleRowsPerpageBrandChange = (event) => {
        setRowsBrandPerPage(parseInt(event.target.value));
        setPageBrand(0);
    };

    const handleBrandFirstPage = () => {
        handleBrandChangePagePagination({ selected: 0 });
    };

    const handleBrandLastPage = () => {
        handleBrandChangePagePagination({ selected: Math.ceil(filteredBrand.length / rowsBrandPerPage) - 1 });
    };

    const handleBrandChangePagePagination = ({ selected }) => {
        setPageBrand(selected);
    };

    const totalBrand = filteredBrand?.length;
    const startBrandEntry = pageBrand * rowsBrandPerPage + 1;
    const endBrandEntry = Math.min((pageBrand + 1) * rowsBrandPerPage, totalBrand);


    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCategory">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Asset - {tabStatusActive}<div><InfoOutlinedIcon /></div></div>
                    </div>
                    {activeScreen === "Asset" && (
                        <div className="card mt-4">
                            <div className="card-body-category">
                                <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                    <div className="HeaderTab">

                                        {userScreens["Category"]?.View && (
                                            <a href="#AssetCategory" style={{ marginLeft: "40px", width: "187px", height: "40px", textAlign: "center" }} className={`${tabActive === 'AssetCategory' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('AssetCategory')}>
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    Asset Category
                                                </div>
                                            </a>
                                        )}

                                        {userScreens["Sub Category"]?.View && (
                                            <a href="#SubCategory" style={{ width: "177px", height: "40px", textAlign: "center" }} className={`${tabActive === 'SubCategory' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('SubCategory')}>
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    Sub Category
                                                </div>
                                            </a>
                                        )}

                                        {userScreens["Brand"]?.View && (
                                            <a href="#Brand" style={{ marginRight: "40px", width: "80px", height: "40px", textAlign: "center" }} className={`${tabActive === 'Brand' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('Brand')}>
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    Brand
                                                </div>
                                            </a>
                                        )}
                                    </div>

                                    {(userScreens["Category"]?.View && tabActive === 'AssetCategory') && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsCategoryPerPage} onChange={handleRowsPerPageChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryCategory}
                                                            onChange={handleCategorySearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileCategoryInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleCategoryFileChange}
                                                    />

                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewCategory()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleCategoryImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportCategory} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "25%" }}>Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Category Code</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "8%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredCategory.length > 0 ? (
                                                            filteredCategory.slice(pageCategory * rowsCategoryPerPage, (pageCategory + 1) * rowsCategoryPerPage).map((category, index) => (
                                                                <tr key={category.Rowid} className="text-left">
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{category.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={category.categoryName}>{category.categoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={category.categoryCode}>{category.categoryCode}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ color: category.status === 'Active' ? '#2ED47A' : '#EC2020' }}>{category.status}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewCategory" onClick={() => viewCategory(category.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editCategory" onClick={() => editCategory(category.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteCategory" onClick={() => DeleteCategory(category.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {(userScreens["Sub Category"]?.View && tabActive === 'SubCategory') && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsSubCategoryPerPage} onChange={handleRowsPerpageSubCategoryChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQuerySubCategory}
                                                            onChange={handleSubCategorySearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileSubCategoryInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleSubCategoryFileChange}
                                                    />
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewSubCategory()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleSubCategoryImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportSubCategory} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "15%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "23%" }}>Sub Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Sub Category Code</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "13%" }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredSubCategory.length > 0 ? (
                                                            filteredSubCategory.slice(pageSubCategory * rowsSubCategoryPerPage, (pageSubCategory + 1) * rowsSubCategoryPerPage).map((subCategory, index) => (
                                                                <tr key={subCategory.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{subCategory.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subCategory.categoryName}>{subCategory.categoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subCategory.subCategoryName}>{subCategory.subCategoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subCategory.subCategoryCode}>{subCategory.subCategoryCode}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ color: subCategory.status === 'Active' ? '#2ED47A' : '#EC2020' }}>{subCategory.status}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewSubCategory" onClick={() => viewSubCategory(subCategory.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editSubCategory" onClick={() => editSubCategory(subCategory.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteSubCategory" onClick={() => DeleteSubCategory(subCategory.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {(userScreens["Brand"]?.View && tabActive === 'Brand') && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsBrandPerPage} onChange={handleRowsPerpageBrandChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryBrand}
                                                            onChange={handleBrandSearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileBrandInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleBrandFileChange}
                                                    />

                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewBrand()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleBrandImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportBrand} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "18%" }}>Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "19%" }}>Sub Category Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Brand Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Model Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredBrand.length > 0 ? (
                                                            filteredBrand.slice(pageBrand * rowsBrandPerPage, (pageBrand + 1) * rowsBrandPerPage).map((brand, index) => (
                                                                <tr key={brand.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{brand.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={brand.categoryName}>{brand.categoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={brand.subCategoryName}>{brand.subCategoryName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={brand.brandName}>{brand.brandName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={brand.modelName}>{brand.modelName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ color: brand.status === 'Active' ? '#2ED47A' : '#EC2020' }}>{brand.status}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewBrand" onClick={() => viewBrand(brand.id)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editBrand" onClick={() => editBrand(brand.id)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteBrand" onClick={() => DeleteBrand(brand.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {(userScreens["Category"]?.View && tabActive === 'AssetCategory') && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startCategoryEntry} to ${endCategoryEntry} of ${totalCategory} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleCategoryFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredCategory.length / rowsCategoryPerPage)}
                                                    forcePage={pageCategory}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleCategoryChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleCategoryLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {(userScreens["Sub Category"]?.View && tabActive === 'SubCategory') && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startSubCategoryEntry} to ${endSubCategoryEntry} of ${totalSubCategory} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleSubCategoryFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredSubCategory.length / rowsSubCategoryPerPage)}
                                                    forcePage={pageSubCategory}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleSubCategoryChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleSubCategoryLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {(userScreens["Brand"]?.View && tabActive === 'Brand') && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startBrandEntry} to ${endBrandEntry} of ${totalBrand} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleBrandFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredBrand.length / rowsBrandPerPage)}
                                                    forcePage={pageBrand}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleBrandChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleBrandLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {(userScreens["Category"]?.View && activeScreen === "CategoryEdit") && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Category Details
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmitCategory(handleCategorySubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Category Name<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter the Category Name"
                                                {...registerCategory('categoryName', { required: 'Please Enter Category Name' })}
                                                disabled={isCategoryView}
                                            />
                                            {errorsCategory.categoryName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsCategory.categoryName.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Category Code<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter the Category Code"
                                                {...registerCategory('categoryCode', {
                                                    required: 'Please Enter Category Code',
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Code cannot exceed 10 characters'
                                                    }
                                                })}
                                                maxLength={10}
                                                disabled={isCategoryView}
                                            />
                                            {errorsCategory.categoryCode && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsCategory.categoryCode.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row pTop24">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Description</div>
                                            <textarea
                                                className="textareaCustomAsset"
                                                placeholder="Enter the Category Description"
                                                {...registerCategory('categoryDescription')}
                                                disabled={isCategoryView}
                                            />
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6" style={{ paddingTop: '10px' }}>
                                            <div>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                    Status
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                    <a
                                                        href="#status"
                                                        className="switch-container"
                                                        onClick={!isCategoryView ? () => setValueCategory('categoryStatus', !statusWatchCategory) : undefined}
                                                        style={{ cursor: isCategoryView ? 'not-allowed' : 'pointer' }}
                                                        disabled={isCategoryView}
                                                    >
                                                        <div className={`switch ${statusWatchCategory ? 'active' : ''}`}>
                                                            <div className="handle"></div>
                                                        </div>
                                                    </a>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                        {statusWatchCategory ? 'Active' : 'Inactive'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                        {!isCategoryView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Submit'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeCategory}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {(userScreens["Sub Category"]?.View && activeScreen === "SubCategoryEdit") && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Sub Category Details
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmitSubCategory(handleSubCategorySubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Category<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="categorySelectID"
                                                control={controlSUBCategory}
                                                rules={{ required: 'Please select a category' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={categoryArray.find(c => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                            setCategorySelectID(selectedOption ? selectedOption.id : null); // Update state
                                                        }}
                                                        options={categoryArray}
                                                        getOptionLabel={(option) => option.categoryName}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Category"
                                                        isClearable
                                                        isSearchable
                                                        className={`${errorsSubCategory.categorySelectID ? 'is-invalid' : ''}`}
                                                        isDisabled={isSubCategoryView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsSubCategory.categorySelectID && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsSubCategory.categorySelectID.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Sub Category Name<span className="mandatoryCss"> *</span>
                                            </div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Sub Category Name"
                                                {...registerSubCategory('subCategoryName', { required: 'Please Enter Sub Category Name' })}
                                                disabled={isSubCategoryView}
                                            />
                                            {errorsSubCategory.subCategoryName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsSubCategory.subCategoryName.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row pTop24">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Sub Category Code<span className="mandatoryCss"> *</span>
                                            </div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Sub Category Code"
                                                {...registerSubCategory('subCategoryCode', {
                                                    required: 'Please Enter Sub Category Code',
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Code cannot exceed 10 characters'
                                                    }
                                                })}
                                                maxLength={10}
                                                disabled={isSubCategoryView}
                                            />
                                            {errorsSubCategory.subCategoryCode && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsSubCategory.subCategoryCode.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Description
                                            </div>
                                            <textarea
                                                className="textareaCustomAsset"
                                                placeholder="Enter the Sub Category Description"
                                                {...registerSubCategory('subCategoryDescription')}
                                                disabled={isSubCategoryView}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: '20px' }}>
                                        <div>
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                Status
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <a
                                                    href="#status"
                                                    className="switch-container"
                                                    onClick={!isSubCategoryView ? () => setValueSubCategory('subCategoryStatus', !statusWatchSubCategory) : undefined}
                                                    style={{ cursor: isSubCategoryView ? 'not-allowed' : 'pointer' }}
                                                    disabled={isSubCategoryView}
                                                >
                                                    <div className={`switch ${statusWatchSubCategory ? 'active' : ''}`}>
                                                        <div className="handle"></div>
                                                    </div>
                                                </a>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                    {statusWatchSubCategory ? 'Active' : 'Inactive'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                        {!isSubCategoryView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Submit'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeSubCategory}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {(userScreens["Brand"]?.View && activeScreen === "BrandEdit") && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Brand Details
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmitBrand(handleBrandSubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Category<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="brandCategorySelectID"
                                                control={controlBrand}
                                                rules={{ required: 'Please select a category' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={categoryArray.find(c => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                            setBrandCategorySelectID(selectedOption ? selectedOption.id : null); // Update state
                                                        }}
                                                        options={categoryArray}
                                                        getOptionLabel={(option) => option.categoryName}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Category"
                                                        isClearable
                                                        isSearchable
                                                        className={`${errorsBrand.brandCategorySelectID ? 'is-invalid' : ''}`}
                                                        isDisabled={isBrandView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsBrand.brandCategorySelectID && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsBrand.brandCategorySelectID.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Sub Category<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="brandSubCategorySelectID"
                                                control={controlBrand}
                                                rules={{ required: 'Please select a sub category' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={subCategoryArray.find(c => c.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                            setBrandSubCategorySelectID(selectedOption ? selectedOption.id : null); // Update state
                                                        }}
                                                        options={subCategoryArray}
                                                        getOptionLabel={(option) => option.subCategoryName}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Sub Category"
                                                        isClearable
                                                        isSearchable
                                                        className={`${errorsBrand.brandSubCategorySelectID ? 'is-invalid' : ''}`}
                                                        isDisabled={isBrandView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsBrand.brandSubCategorySelectID && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsBrand.brandSubCategorySelectID.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Brand Name<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Brand Name"
                                                {...registerBrand('brandName', { required: 'Please Enter Brand Name' })}
                                                disabled={isBrandView}
                                            />
                                            {errorsBrand.brandName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsBrand.brandName.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Model Name</div>
                                            <input
                                                className="textinputCustomAsset"
                                                placeholder="Enter Model Name"
                                                {...registerBrand('modelName')}
                                                disabled={isBrandView}
                                            />
                                            {errorsBrand.modelName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsBrand.modelName.message}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Description</div>
                                            <textarea
                                                className="textareaCustomAsset"
                                                placeholder="Enter the Brand Description"
                                                {...registerBrand('brandDescription')}
                                                disabled={isBrandView}
                                            />
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6" style={{ paddingTop: '10px' }}>
                                            <div>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                    Status
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                    <a
                                                        href="#status"
                                                        className="switch-container"
                                                        onClick={!isBrandView ? () => setValueBrand('brandStatus', !statusWatchBrand) : undefined}
                                                        style={{ cursor: isBrandView ? 'not-allowed' : 'pointer' }}
                                                        disabled={isBrandView}
                                                    >
                                                        <div className={`switch ${statusWatchBrand ? 'active' : ''}`}>
                                                            <div className="handle"></div>
                                                        </div>
                                                    </a>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                        {statusWatchBrand ? 'Active' : 'Inactive'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "5%" }}>
                                        {!isBrandView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Submit'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeBrand}>Cancel</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    )}

                </div >
            )}
        </div >
    );
};

export default AssetCategoriesAndBrand;
