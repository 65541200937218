const GetUserAccess = () => {
    return new Promise((resolve, reject) => {
        try {
            const rawScreens = localStorage.getItem('UserScreens');
            const screens = JSON.parse(rawScreens);

            const filteredScreens = Object.keys(screens).reduce((acc, key) => {
                if (screens[key]?.View) {
                    acc[key] = screens[key];
                }
                return acc;
            }, {});

            resolve(filteredScreens);
        } catch (error) {
            console.error('Error fetching user access data:', error);
            reject(error);
        }
    });
};

export default GetUserAccess;
