import './App.css';
import RoutesPage from './Routes/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/saga-blue/theme.css'; // Choose your theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function App() {
  return (
    <div>
      <RoutesPage />
    </div>
  );
}

export default App;
