import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

// Request Category
export async function getRequestCategory() {
    try {
        const response = await axios.get(API_BASE_URL + 'RequestCategory/GetRequestCategory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Request Category:', error);
        throw error;
    }
}

export async function createRequestCategory(category) {
    try {
        const response = await axios.post(API_BASE_URL + 'RequestCategory/CreateRequestCategory', category, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Request Category:', error);
        throw error;
    }
}

export async function updateRequestCategory(category) {
    try {
        const response = await axios.post(API_BASE_URL + 'RequestCategory/UpdateRequestCategory', category, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update Request Category:', error);
        throw error;
    }
}

export async function deleteRequestCategory(categoryID) {
    try {
        const response = await axios.post(`${API_BASE_URL}RequestCategory/DeleteRequestCategory?RequestCatId=${categoryID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Request Category:', error);
        throw error;
    }
}


// Request Sub Category
export async function getRequestSubCategory() {
    try {
        const response = await axios.get(API_BASE_URL + 'RequestSubCategory/GetRequestSubCategory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching RequestSubCategory:', error);
        throw error;
    }
}

export async function createRequestSubCategory(subCategory) {
    try {
        const response = await axios.post(API_BASE_URL + 'RequestSubCategory/CreateRequestSubCategory', subCategory, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create RequestSubCategory:', error);
        throw error;
    }
}

export async function updateRequestSubCategory(SubCategory) {
    try {
        const response = await axios.post(API_BASE_URL + 'RequestSubCategory/UpdateRequestSubCategory', SubCategory, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update RequestSubCategory:', error);
        throw error;
    }
}

export async function deleteRequestSubCategory(subCategoryID) {
    try {
        const response = await axios.post(`${API_BASE_URL}RequestSubCategory/DeleteRequestSubCategory?RequestSubCatId=${subCategoryID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete RequestSubCategory:', error);
        throw error;
    }
}

// Request Team
export async function getRequestTeam() {
    try {
        const response = await axios.get(API_BASE_URL + 'RequestTeam/GetRequestTeam', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Request Team:', error);
        throw error;
    }
}

export async function createRequestTeam(team) {
    try {
        const response = await axios.post(API_BASE_URL + 'RequestTeam/CreateRequestTeam', team, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Request Team:', error);
        throw error;
    }
}

export async function updateRequestTeam(team) {
    try {
        const response = await axios.post(API_BASE_URL + 'RequestTeam/UpdateRequestTeam', team, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update Request Team:', error);
        throw error;
    }
}

export async function deleteRequestTeam(teamID) {
    try {
        const response = await axios.post(`${API_BASE_URL}RequestTeam/DeleteRequestTeam?RequestTeamId=${teamID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Request Team:', error);
        throw error;
    }
}

// Request Configuration Settings

export async function getRequestSettings() {
    try {
        const response = await axios.get(API_BASE_URL + 'RequestConfiguration/GetRequestConfiguration', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Request Configuration Settings:', error);
        throw error;
    }
}

export async function updateRequestSettings(payload) {
    try {
        const response = await axios.post(API_BASE_URL + 'RequestConfiguration/UpdateRequestConfiguration', payload, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating Request Configuration Settings:', error);
        throw error;
    }
}

export async function addDefaultPriority(Priority) {
    try {
        const response = await axios.post(`${API_BASE_URL}RequestConfiguration/InsertDefaultPriority/priorityName/${Priority}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Default Priority:', error);
        throw error;
    }
}

export async function getDefaultPriority() {
    try {
        const response = await axios.get(API_BASE_URL + 'RequestConfiguration/GetDefaultPriority', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Default Priority:', error);
        throw error;
    }
}