export async function getCountriesList() {
    try {
        const countriesList = JSON.parse(localStorage.getItem('Countries'));
        const countryArray = [];
        for (const element of countriesList) {
            const value = { label: element.cntrY_NAME, id: element.id };
            countryArray.push(value);
        }
        localStorage.setItem('CountryDropDown', JSON.stringify(countryArray))
        return countryArray;
    } catch (error) {
        console.error('Error fetching Countries:', error);
        throw error;
    }
}

export async function getStatesList() {
    try {
        const StatesList = JSON.parse(localStorage.getItem('States'));
        const StateArray = [];
        for (const element of StatesList) {
            const value = {
                label: element.statE_NAME
                , id: element.id, countryId: element.cntrY_ID
            };
            StateArray.push(value);
        }
        localStorage.setItem('StateDropDown', JSON.stringify(StateArray))
        return StateArray;
    } catch (error) {
        console.error('Error fetching Countries:', error);
        throw error;
    }
}

export async function getCitiesList() {
    try {
        const CitiesList = JSON.parse(localStorage.getItem('Cities'));
        const CitiesArray = [];
        for (const element of CitiesList) {
            const value = {
                 label: element.citY_NAME
                , id: element.id, stateId: element.statE_ID
            };
            CitiesArray.push(value);
        }
        localStorage.setItem('CityDropDown', JSON.stringify(CitiesArray))
        return CitiesArray;
    } catch (error) {
        console.error('Error fetching Countries:', error);
        throw error;
    }
}


