import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import GetUserAccess from '../../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { getContarctMethod, getServiceRequestMethod, getWarrantiesMethod, getInsuranceMethod } from '../../../CommomPages/Config';

import './SettingsCss.css';

import smsIcon from '../../../assets/icon/S-sms.svg';
import editIcon from '../../../assets/icon/S-edit.svg';
import scanIcon from '../../../assets/icon/scan.svg';
import avatarImg from '../../../assets/icon/S-profile.svg';
import peopleIcon from '../../../assets/icon/S-people.svg';
import monitorImg from '../../../assets/icon/S-monitor.svg';
import SettingsIcon from '../../../assets/icon/S-setting.svg';
import cubeImg from '../../../assets/icon/S-convert-3d-cube.svg';
import textIcon from '../../../assets/icon/S-clipboard-text.svg';
import boardImg from '../../../assets/icon/S-clipboard-text-board.svg';



const SettingsPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [contract, setContract] = useState('');
    const [serviceRequest, setServiceRequest] = useState('');
    const [warranties, setWarranties] = useState('');
    const [insurance, setInsurance] = useState('');
    const [userScreens, setUserScreens] = useState({});

    setTimeout(() => {
        setIsLoading(false);
    }, 3000);

    useEffect(() => {
        fetchUserScreens();
        const fetchData = async () => {
            const getContarct = await getContarctMethod();
            const getServiceRequest = await getServiceRequestMethod();
            const getWarranties = await getWarrantiesMethod();
            const getInsurance = await getInsuranceMethod();
            setContract(getContarct);
            setServiceRequest(getServiceRequest);
            setWarranties(getWarranties);
            setInsurance(getInsurance);
        };
        fetchData();
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };


    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div className="customScrollbar">
                    <div className="pagetitleSettings">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Settings<div><InfoOutlinedIcon /></div></div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-body">
                            <div className="row" style={{ paddingTop: "20px" }}>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={avatarImg} alt="profileImg" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Account Details
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Business Entity"]?.View && (
                                                <a href="/BusinessEntityNew?tab=BussinessEntity">
                                                    <span className="sideMenuTextLink">Business Entity</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Business Unit"]?.View && (
                                                <a href="/BusinessEntityNew?tab=BussinessUnit">
                                                    <span className="sideMenuTextLink">Business Unit</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Business Branch"]?.View && (
                                                <a href="/BusinessEntityNew?tab=BussinessBranch">
                                                    <span className="sideMenuTextLink">Business Branch</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Country"]?.View && (
                                                <a href="/Country?tab=Country">
                                                    <span className="sideMenuTextLink">Country</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["State"]?.View && (
                                                <a href="/Country?tab=State">
                                                    <span className="sideMenuTextLink">State</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["City"]?.View && (
                                                <a href="/Country?tab=City">
                                                    <span className="sideMenuTextLink">City</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Department"]?.View && (
                                                <a href="/DepartmentsAndTeam?tab=Department">
                                                    <span className="sideMenuTextLink">Department</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Sub Department"]?.View && (
                                                <a href="/DepartmentsAndTeam?tab=SubDepartment">
                                                    <span className="sideMenuTextLink">Sub Department</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Team"]?.View && (
                                                <a href="/DepartmentsAndTeam?tab=Team">
                                                    <span className="sideMenuTextLink">Team</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={SettingsIcon} alt="SettingsIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                General Settings
                                            </div>
                                        </div>
                                        {userScreens["General Settings"]?.View && (
                                            <a href="/GeneralSettings">
                                                <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                                    <span className="sideMenuTextLink">Time Zone</span> <span className="blueLine">|</span>
                                                    <span className="sideMenuTextLink">Date Format</span> <span className="blueLine">|</span>
                                                    <span className="sideMenuTextLink">Time Format</span> <span className="blueLine">|</span>
                                                </div>
                                                <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                                    <span className="sideMenuTextLink">Financial Year</span>
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                </div>

                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={peopleIcon} alt="peopleIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                User Management
                                            </div>
                                        </div>
                                        {userScreens["Role Management"]?.View && (
                                            <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                                <a href="/RolesList"><span className="sideMenuTextLink">Role Management</span><span className="blueLine">|</span></a>
                                            </div>
                                        )}
                                        {userScreens["User Access Permission"]?.View && (
                                            <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                                <a href="/UserAccessPermission"><span className="sideMenuTextLink">User Access Permission</span><span className="blueLine">|</span></a>
                                            </div>
                                        )}
                                        {userScreens["Employee Management"]?.View && (
                                            <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                                <a href="/EmployeeManagement"><span className="sideMenuTextLink">Employee Management</span><span className="blueLine">|</span></a>
                                            </div>
                                        )}
                                        {userScreens["Supplier Management"]?.View && (
                                            <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                                <a href="/SupplierMaster"><span className="sideMenuTextLink">Supplier Management</span></a>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={textIcon} alt="textIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Asset
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Asset Configuration"]?.View && (
                                                <a href="/AssetSettings"><span className="sideMenuTextLink">Asset Configuration</span><span className="blueLine">|</span></a>
                                            )}
                                            {userScreens["Category"]?.View && (
                                                <a href="/AssetCategoriesAndBrand?tab=AssetCategory"><span className="sideMenuTextLink">Category</span><span className="blueLine">|</span></a>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Sub Category"]?.View && (
                                                <a href="/AssetCategoriesAndBrand?tab=SubCategory"><span className="sideMenuTextLink">Sub Category</span><span className="blueLine">|</span></a>
                                            )}
                                            {userScreens["Brand"]?.View && (
                                                <a href="/AssetCategoriesAndBrand?tab=Brand"><span className="sideMenuTextLink">Brand</span><span className="blueLine">|</span></a>
                                            )}
                                        </div>
                                        {userScreens["Action Form Configuration"]?.View && (
                                            <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                                <a href="/ActionFormConfiguration"><span className="sideMenuTextLink">Action Form Configuration</span></a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={cubeImg} alt="cubeImg" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Module Configuration
                                            </div>
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Asset Management"]?.View && (
                                                <a href="/AssetCreationView">
                                                    <span className="sideMenuTextLink">Asset Management</span>
                                                    <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Leave Management"]?.View && (
                                                <a href="#LeaveManagement">
                                                    <span className="sideMenuTextLink">Leave Management</span>
                                                    <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Warranty Management"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Warranty Management</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["Task Management"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Task Management</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Service Desk Management"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Service Desk Management</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Maintenance Management"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Maintenance Management</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Knowledge Base Management"]?.View && (
                                                <span className="sideMenuTextLink">Knowledge Base Management</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={scanIcon} alt="scanIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Request
                                            </div>
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Request Configuration"]?.View && (
                                                <a href="/RequestSettings" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Request Configuration</span>
                                                    <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Request Category"]?.View && (
                                                <a href="/RequestCategoriesAndTeam?tab=RequestCategory" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Request Category</span>
                                                    <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Request Sub Category"]?.View && (
                                                <a href="/RequestCategoriesAndTeam?tab=RequestSubCategory" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Request Sub Category</span>
                                                    <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Request Team Mapping"]?.View && (
                                                <a href="/RequestCategoriesAndTeam?tab=RequestTeam" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Request Team Mapping</span>
                                                    <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={scanIcon} alt="scanIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Maintenance
                                            </div>
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Maintenance Configuration"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Maintenance Configuration</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Maintenance Type"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Maintenance Type</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Maintenance Sub Type"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Maintenance Sub Type</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Manage Maintenance"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Manage Maintenance</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Maintenance Stage and Status"]?.View && (
                                                <span className="sideMenuTextLink">Maintenance Stage and Status</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={scanIcon} alt="scanIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Contract
                                            </div>
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Contract Configuration"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Contract Configuration</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Contract Type"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Contract Type</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["Manage Contract"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Manage Contract</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>

                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Contract Status"]?.View && (
                                                <span className="sideMenuTextLink">Contract Status</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={editIcon} alt="editIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Process Form Config
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Contract"]?.View && (
                                                <a href={contract === "No" ? "#" : "/ProcessFormConfigurationContract"} style={{ cursor: contract === "No" ? 'not-allowed' : 'pointer' }}>
                                                    <span className="sideMenuTextLink">Contract</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Maintenance"]?.View && (
                                                <a href="/ProcessFormConfigurationMaintenance?tab=AddNew">
                                                    <span className="sideMenuTextLink">Maintenance</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Warranties"]?.View && (
                                                <a href={warranties === "No" ? "#" : "/ProcessFormConfigurationWarranty?tab=AddNew"} style={{ cursor: warranties === "No" ? 'not-allowed' : 'pointer' }}>
                                                    <span className="sideMenuTextLink">Warranties</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Service Request"]?.View && (
                                                <a href={serviceRequest === "No" ? "#" : "/ProcessFormConfigurationServiceRequest?tab=AddNew"} style={{ cursor: serviceRequest === "No" ? 'not-allowed' : 'pointer' }}>
                                                    <span className="sideMenuTextLink">Service Request</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Insurance"]?.View && (
                                                <a href={insurance === "No" ? "#" : "/ProcessFormConfigurationInsurance?tab=AddNew"} style={{ cursor: insurance === "No" ? 'not-allowed' : 'pointer' }}>
                                                    <span className="sideMenuTextLink">Insurance</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={editIcon} alt="editIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Customisation
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px" }}>
                                            {userScreens["Asset Management"]?.View && (
                                                <a href="#AssetManagement" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Asset Management</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px" }}>
                                            {userScreens["Maintenance Management"]?.View && (
                                                <a href="#MaintenanceManagement" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Maintenance Management</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px" }}>
                                            {userScreens["Contract Management"]?.View && (
                                                <a href="#ContractManagement" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Contract Management</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                            {userScreens["Custom Form"]?.View && (
                                                <a href="#CustomForm" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Custom Form</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px" }}>
                                            {userScreens["Forms Management"]?.View && (
                                                <a href="#FormsManagement" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Forms Management</span> <span className="blueLine">|</span>
                                                </a>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px" }}>
                                            {userScreens["Service Desk Management"]?.View && (
                                                <a href="#ServiceDeskManagement" style={{ cursor: 'pointer' }}>
                                                    <span className="sideMenuTextLink">Service Desk Management</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={monitorImg} alt="monitorImg" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Automation
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Alert"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Alert</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["Notification"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Notification</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["SLA"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">SLA</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["Workflow"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Workflow</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Approval"]?.View && (
                                                <span className="sideMenuTextLink">Approval</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={boardImg} alt="boardImg" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Templates
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Email Template"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Email Template</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["Alert Templates"]?.View && (
                                                <span className="sideMenuTextLink">Alert Templates</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={smsIcon} alt="smsIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                Mail Server Setting
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Incoming"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Incoming</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["Outgoing"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Outgoing</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["Spam Filter"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">Spam Filter</span>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["Delimier"]?.View && (
                                                <span className="sideMenuTextLink">Delimier</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3 col-md-3 col-lg-3 col-sm-12">
                                    <div className="AccountDetails">
                                        <div style={{ display: "flex" }}>
                                            <img src={scanIcon} alt="scanIcon" style={{ height: '50px', width: "50px", margin: "12px 10px 14px 10px" }} />
                                            <div className="fontFamily fontweightMedium fontsize16" style={{ alignContent: "center" }}>
                                                QR
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["QR Properties Settings"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">QR Properties Settings</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["QR Configuration"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">QR Configuration</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["QR Mapping"]?.View && (
                                                <span className="sideMenuTextLink">QR Mapping</span>
                                            )}
                                        </div>
                                        <div style={{ display: "flex", paddingLeft: "10px", marginBottom: "4px" }}>
                                            {userScreens["QR Tracking"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">QR Tracking</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["QR Printing"]?.View && (
                                                <>
                                                    <span className="sideMenuTextLink">QR Printing</span>
                                                    <span className="blueLine">|</span>
                                                </>
                                            )}
                                            {userScreens["QR Labelling"]?.View && (
                                                <span className="sideMenuTextLink">QR Labelling</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SettingsPage;
