import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

export async function insertRoleData(RoleData) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateRole', RoleData , { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error inserting Role Data:', error);
        throw error;
    }
}


export async function getRoleData() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getRole' , { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Role Data:', error);
        throw error;
    }
}


export async function updateRoleData(RoleData) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateRole', RoleData , { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating Role:', error);
        throw error;
    }
}


export async function deleteRoleData(RoleId) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteRole?RoleID=${RoleId}`,null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error deleting Role:', error);
        throw error;
    }
}

