import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportAttachmentData = (data, format) => {
    const headers = ["SI.No","Document Type","Document Link"];
    const rows = data.map(Emp => {
        return [
            Emp.Rowid,
            Emp.DocumentTypeName,
            Emp.documentLink
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Attachment_List_${formattedDate}.${fileExtension}`);
};

export const exportTeamData = (data, format) => {
    const headers = ["SI.No","Team Name", "Team Description","Level","Start Date","End Date"];
    const rows = data.map(Emp => {
        return [
            Emp.Rowid,
            Emp.teamName?.trim(),
            Emp.teamDescription?.trim(),
            Emp.Level,
            Emp.Start || "-",
            Emp.End || "-"
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Team_List_${formattedDate}.${fileExtension}`);
};

export const exportRoleData = (data, format) => {
    const headers = ["SI.No","Role Name","Start Date"," End Date","Status"];
    const rows = data.map(Emp => {
        return [
            Emp.Rowid,
            Emp.UserRoleName,
            Emp.Start || "-",
            Emp.Date || "-",
            Emp.status
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Role_List_${formattedDate}.${fileExtension}`);
};
