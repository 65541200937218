import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportRoleData = (data, format) => {
    const headers = ["SI.No", "Role", "Status"];

    const rows = data.map(role => {
        return [
            role.Rowid || '',
            role.rolE_User_Role || '',
            role.rolE_Status || ''
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Roles_Data_${formattedDate}.${fileExtension}`);
};
