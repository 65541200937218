import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

// Department
export async function getDepartment() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getDepartment', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Department:', error);
        throw error;
    }
}

export async function createDepartment(department) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateDepartment', department, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Department:', error);
        throw error;
    }
}

export async function updateDepartment(department) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateDepartment', department, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update Department:', error);
        throw error;
    }
}

export async function deleteDepartment(departmentID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteDepartment?department=${departmentID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Department:', error);
        throw error;
    }
}


// Sub Department
export async function getSubDepartment() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getSubDepartment', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching SubDepartment:', error);
        throw error;
    }
}

export async function createSubDepartment(subDepartment) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateSubDepartment', subDepartment, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create SubDepartment:', error);
        throw error;
    }
}

export async function updateSubDepartment(SubDepartment) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateSubDepartment', SubDepartment, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update SubDepartment:', error);
        throw error;
    }
}

export async function deleteSubDepartment(subDepartmentID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteSubDepartment?subDepartmentId=${subDepartmentID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete SubDepartment:', error);
        throw error;
    }
}


// Team
export async function getTeam() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getTeam', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching getTeam:', error);
        throw error;
    }
}

export async function createTeam(Team) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateTeam', Team, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Team:', error);
        throw error;
    }
}

export async function updateTeam(Team) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateTeam', Team, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Team:', error);
        throw error;
    }
}

export async function deleteTeam(TeamID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteTeam?teamId=${TeamID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Team:', error);
        throw error;
    }
}