import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const exportDataCountry = (data, format) => {
    const headers = ["SI.No", "Country"];

    const rows = data.map(Country => {
        return [
            Country.Rowid || '',
            Country.cntrY_NAME || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    switch (format) {
        case 'csv': {
            const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
            fileData = csvContent;
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = 'csv';
            break;
        }
        case 'excel': {
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
            fileExtension = 'xlsx';
            break;
        }
        case 'pdf': {
            const doc = new jsPDF('landscape', 'mm', 'a4');
            doc.setFontSize(10);
            doc.autoTable({
                head: [headers],
                body: rows,
                styles: { overflow: 'linebreak', fontSize: 8 },
                margin: { top: 20 },
                tableWidth: 'auto'
            });
            fileData = doc.output('blob');
            fileType = 'application/pdf';
            fileExtension = 'pdf';
            break;
        }
        default:
            return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Country_Data_${formattedDate}.${fileExtension}`);
};

export const exportDataState = (data, format) => {
    const headers = ["SI.No", "Country" , "State"];

    const rows = data.map(State => {
        return [
            State.Rowid || '',
            State.countryName || '',
            State.statE_NAME || ''
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    switch (format) {
        case 'csv': {
            const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
            fileData = csvContent;
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = 'csv';
            break;
        }
        case 'excel': {
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
            fileExtension = 'xlsx';
            break;
        }
        case 'pdf': {
            const doc = new jsPDF('landscape', 'mm', 'a4');
            doc.setFontSize(10);
            doc.autoTable({
                head: [headers],
                body: rows,
                styles: { overflow: 'linebreak', fontSize: 8 },
                margin: { top: 20 },
                tableWidth: 'auto'
            });
            fileData = doc.output('blob');
            fileType = 'application/pdf';
            fileExtension = 'pdf';
            break;
        }
        default:
            return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `State_Data_${formattedDate}.${fileExtension}`);
};

export const exportDataCity = (data, format) => {
    const headers = ["SI.No", "Country" , "State" ,"City"];

    const rows = data.map(City => {
        return [
            City.Rowid || '',
            City.countryName || '',
            City.stateName || '',
            City.citY_NAME || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    switch (format) {
        case 'csv': {
            const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
            fileData = csvContent;
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = 'csv';
            break;
        }
        case 'excel': {
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
            fileExtension = 'xlsx';
            break;
        }
        case 'pdf': {
            const doc = new jsPDF('landscape', 'mm', 'a4');
            doc.setFontSize(10);
            doc.autoTable({
                head: [headers],
                body: rows,
                styles: { overflow: 'linebreak', fontSize: 8 },
                margin: { top: 20 },
                tableWidth: 'auto'
            });
            fileData = doc.output('blob');
            fileType = 'application/pdf';
            fileExtension = 'pdf';
            break;
        }
        default:
            return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `City_Data_${formattedDate}.${fileExtension}`);
};