import React, { useEffect, useState } from 'react';
import { differenceInMonths, isBefore, format } from 'date-fns';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getDateFormat, getRegexForDateFormat, validateDate } from '../../../CommomPages/Config';

import XMarksvgIcon from '../../../assets/icon/X mark.svg';
import datesvgIcon from "../../../assets/icon/CalendarWithBG.svg";
import rupeeWithBG from "../../../assets/icon/RupeeWithBG.svg";

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    })
};

export const FoundSection = ({ onSubmit, isFoundView, dateFormat, calendarsvgIcon, onCancel, isFound }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            dateFound: '',
            notes: '',
        },
    });

    return (
        <Modal show={isFound} onHide={handleClose} centered size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Found
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Date Found<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="dateFound"
                                    control={control}
                                    rules={{
                                        required: "Date Found is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Date Found is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate > today) {
                                                    return "Date Found cannot be in the future";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + ")";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isFoundView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isFoundView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errors.dateFound && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errors.dateFound.message}
                                </div>
                            )}
                        </div>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...register('notes')}
                                disabled={isFoundView}
                            />
                        </div>
                    </div>
                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isFoundView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={handleClose} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const LostSection = ({ onSubmit, isLostView, dateFormat, calendarsvgIcon, onCancel, isLost }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register: registerLost,
        handleSubmit: handleSubmitLost,
        formState: { errors: errorsLost },
        control: controlLost,
    } = useForm({
        defaultValues: {
            dateLost: '',
            notes: '',
        },
    });

    return (
        <Modal show={isLost} onHide={handleClose} centered size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Lost
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitLost(onSubmit)}>
                    <div className='row'>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Date Lost<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="dateLost"
                                    control={controlLost}
                                    rules={{
                                        required: "Date Lost is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Date Lost is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate > today) {
                                                    return "Date Lost cannot be in the future";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isLostView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isLostView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsLost.dateLost && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsLost.dateLost.message}
                                </div>
                            )}
                        </div>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerLost('notes')}
                                disabled={isLostView}
                            />
                        </div>
                    </div>
                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isLostView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const BrokenSection = ({ onSubmit, isBrokenView, dateFormat, calendarsvgIcon, onCancel, isBroken }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register: registerBroken,
        handleSubmit: handleSubmitBroken,
        formState: { errors: errorsBroken },
        control: controlBroken,
    } = useForm({
        defaultValues: {
            dateBroken: '',
            notes: '',
        },
    });

    return (
        <Modal show={isBroken} onHide={handleClose} centered size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Broken
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitBroken(onSubmit)}>
                    <div className='row'>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Date Broken<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="dateBroken"
                                    control={controlBroken}
                                    rules={{
                                        required: "Date Broken is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Date Broken is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate > today) {
                                                    return "Date Broken cannot be in the future";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isBrokenView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isBrokenView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsBroken.dateBroken && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsBroken.dateBroken.message}
                                </div>
                            )}
                        </div>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerBroken('notes')}
                                disabled={isBrokenView}
                            />
                        </div>
                    </div>
                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isBrokenView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const DisposeSection = ({ onSubmit, isDisposeView, dateFormat, calendarsvgIcon, onCancel, isDispose }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register: registerDispose,
        handleSubmit: handleSubmitDispose,
        formState: { errors: errorsDispose },
        control: controlDispose,
    } = useForm({
        defaultValues: {
            dateDispose: '',
            disposeTo: '',
            notes: '',
        },
    });

    return (
        <Modal show={isDispose} onHide={handleClose} centered size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">Dispose </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitDispose(onSubmit)}>
                    <div className='row'>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Date Disposed<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="dateDispose"
                                    control={controlDispose}
                                    rules={{
                                        required: "Date Disposed is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Date Disposed is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate > today) {
                                                    return "Date Disposed cannot be in the future";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isDisposeView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isDisposeView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsDispose.dateDispose && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsDispose.dateDispose.message}
                                </div>
                            )}
                        </div>
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Dispose to<span className="mandatoryCss"> *</span></div>
                            <input
                                className={isDisposeView ? "textBoxNewView" : "textBoxNew"}
                                {...registerDispose('disposeTo', { required: "Dispose to is required" })}
                                disabled={isDisposeView}
                            />
                            {errorsDispose.disposeTo && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsDispose.disposeTo.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerDispose('notes')}
                                disabled={isDisposeView}
                            />
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">

                        </div>
                    </div>

                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isDisposeView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const CheckOutSection = ({ onSubmit, isCheckOutView, dateFormat, calendarsvgIcon, onCancel, isCheckOut, employeeArray, unitArray, branchArray, departmentArray }) => {
    const [show, setShow] = useState(false);
    const [IsSendMail, setIsSendMail] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const handleSendmailCheckBox = (e) => {
        if (e.target.checked) {
            setIsSendMail(true);
        } else {
            setIsSendMail(false);
        }
    };

    const {
        register: registerCheckOut,
        handleSubmit: handleSubmitCheckOut,
        formState: { errors: errorsCheckOut },
        control: controlCheckOut,
        watch: watchCheckOut,
    } = useForm({
        defaultValues: {
            checkOutDate: '',
            employee: false,
            unit: false,
            dueDate: '',
            assignTo: 0,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
            checkOutNotes: '',
            sendEmail: '',
            sendEmailCheck: false,
        },
    });

    const isEmployeeChecked = watchCheckOut('employee');
    const isUnitChecked = watchCheckOut('unit');

    return (
        <Modal show={isCheckOut} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Check Out
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitCheckOut(onSubmit)}>
                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Check-out Date <span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="checkOutDate"
                                    control={controlCheckOut}
                                    rules={{
                                        required: "Check-out Date is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Check-out Date is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate > today) {
                                                    return "Check-out Date cannot be in the future";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isCheckOutView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isCheckOutView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsCheckOut.checkOutDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.checkOutDate.message}
                                </div>
                            )}
                        </div>

                        <div className="col-6 col-md-6 col-lg-6">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Check-Out Assets<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='d-flex'>
                                <div className='d-flex align-items-center mTop12 mBottom10'>
                                    <Controller
                                        name="employee"
                                        control={controlCheckOut}
                                        rules={{
                                            validate: (value) => value || watchCheckOut('unit') || "At least one asset must be selected",
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    type="checkbox"
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                    disabled={isCheckOutView}
                                                />
                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight45'>
                                                    Employee
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className='d-flex pLeft12 align-items-center mTop12 mBottom10'>
                                    <Controller
                                        name="unit"
                                        control={controlCheckOut}
                                        rules={{
                                            validate: (value) => value || watchCheckOut('employee') || "At least one asset must be selected",
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    type="checkbox"
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                    disabled={isCheckOutView}
                                                />
                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                    Unit
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            {errorsCheckOut.employee && errorsCheckOut.unit && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.employee.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6 col-md-6 col-lg-6'>
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                Assign to{isEmployeeChecked && (<span className="mandatoryCss"> *</span>)}
                            </div>
                            <Controller
                                name="assignTo"
                                control={controlCheckOut}
                                rules={{
                                    required: isEmployeeChecked ? 'Assign to is required' : false,
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={employeeArray.find(repo => repo.id === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                        options={employeeArray}
                                        getOptionLabel={(option) => option.EmployeeName}
                                        getOptionValue={(option) => option.id}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isCheckOutView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsCheckOut.assignTo && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.assignTo.message}
                                </div>
                            )}
                        </div>
                        <div className='col-6 col-md-6 col-lg-6'>
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Due Date<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="dueDate"
                                    control={controlCheckOut}
                                    rules={{
                                        required: "Due Date is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Due Date is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate < today) {
                                                    return "Due Date cannot be in the past";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isCheckOutView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isCheckOutView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsCheckOut.dueDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.dueDate.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24 " >
                                Unit{isUnitChecked && (<span className="mandatoryCss"> *</span>)}
                            </div>
                            <Controller
                                name="unitId"
                                control={controlCheckOut}
                                rules={{
                                    required: isUnitChecked ? 'Unit is required' : false,
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={unitArray.find(unit => unit.bU_ID === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bU_ID : null); }}
                                        options={unitArray}
                                        getOptionLabel={(option) => option.bU_NAME}
                                        getOptionValue={(option) => option.bU_ID}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isCheckOutView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsCheckOut.unitId && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.unitId.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                Branch<span className="mandatoryCss"> *</span>
                            </div>
                            <Controller
                                name="branchId"
                                control={controlCheckOut}
                                rules={{ required: 'Branch is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={branchArray.find(branch => branch.bR_ID === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bR_ID : null); }}
                                        options={branchArray}
                                        getOptionLabel={(option) => option.bR_NAME}
                                        getOptionValue={(option) => option.bR_ID}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isCheckOutView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsCheckOut.branchId && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.branchId.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                Department<span className="mandatoryCss"> *</span>
                            </div>
                            <Controller
                                name="departmentId"
                                control={controlCheckOut}
                                rules={{ required: 'Department is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={departmentArray.find(dept => dept.id === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                        options={departmentArray}
                                        getOptionLabel={(option) => option.departmentName}
                                        getOptionValue={(option) => option.id}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isCheckOutView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsCheckOut.departmentId && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.departmentId.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Check-out Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerCheckOut('checkOutNotes')}
                                disabled={isCheckOutView}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6 col-md-6 col-lg-6 col-sm-12'>
                            <div className='d-flex'>
                                <div className='d-flex align-items-center grayTextColor'>
                                    <Controller
                                        name="sendEmailCheck"
                                        control={controlCheckOut}
                                        // rules={IsSendMail ? {
                                        //     required: 'Please Enter Email',
                                        //     pattern: {
                                        //         value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        //         message: 'Please Enter a Valid Email Address'
                                        //     }
                                        // } : undefined}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    type="checkbox"
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.checked);
                                                        handleSendmailCheckBox(e);
                                                    }}
                                                    disabled={isCheckOutView}
                                                />
                                                <div className='grayTextColor fontFamily fontsize16 fontweightRegular pLeft8'>
                                                    Send Email{IsSendMail && (<span className="mandatoryCss"> *</span>)}
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <input
                                className={isCheckOutView ? "textBoxNewView" : "textBoxNew"}
                                {...registerCheckOut('sendEmail', {
                                    required: IsSendMail ? 'Please Enter Email' : false,
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Please Enter a Valid Email Address'
                                    }
                                })}
                                disabled={isCheckOutView}
                            />
                            {errorsCheckOut.sendEmail && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckOut.sendEmail.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isCheckOutView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const CheckInSection = ({ onSubmit, isCheckInView, dateFormat, calendarsvgIcon, onCancel, isCheckIn, unitArray, branchArray, departmentArray }) => {
    const [show, setShow] = useState(false);
    const [IsSendMail, setIsSendMail] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const handleSendmailCheckBox = (e) => {
        if (e.target.checked) {
            setIsSendMail(true);
        } else {
            setIsSendMail(false);
        }
    };

    const {
        register: registerCheckIn,
        handleSubmit: handleSubmitCheckIn,
        formState: { errors: errorsCheckIn },
        control: controlCheckIn,
        watch: watchCheckIn,
    } = useForm({
        defaultValues: {
            returnDate: '',
            employee: false,
            unit: false,
            unitId: '',
            branchId: '',
            departmentId: '',
            checkInNotes: '',
            sendEmail: '',
            sendEmailCheck: false,
        },
    });

    return (
        <Modal show={isCheckIn} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Check In
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitCheckIn(onSubmit)}>
                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24 " >
                                Unit<span className="mandatoryCss"> *</span>
                            </div>
                            <Controller
                                name="unitId"
                                control={controlCheckIn}
                                rules={{ required: 'Unit is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={unitArray.find(unit => unit.bU_ID === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bU_ID : null); }}
                                        options={unitArray}
                                        getOptionLabel={(option) => option.bU_NAME}
                                        getOptionValue={(option) => option.bU_ID}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isCheckInView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsCheckIn.unitId && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckIn.unitId.message}
                                </div>
                            )}
                        </div>

                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Return Date<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="returnDate"
                                    control={controlCheckIn}
                                    rules={{
                                        required: "Return Date is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Return Date is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate < today) {
                                                    return "Return Date cannot be in the past";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isCheckInView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isCheckInView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsCheckIn.returnDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckIn.returnDate.message}
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                Department<span className="mandatoryCss"> *</span>
                            </div>
                            <Controller
                                name="departmentId"
                                control={controlCheckIn}
                                rules={{ required: 'Department is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={departmentArray.find(dept => dept.id === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                        options={departmentArray}
                                        getOptionLabel={(option) => option.departmentName}
                                        getOptionValue={(option) => option.id}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isCheckInView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsCheckIn.departmentId && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckIn.departmentId.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                Branch<span className="mandatoryCss"> *</span>
                            </div>
                            <Controller
                                name="branchId"
                                control={controlCheckIn}
                                rules={{ required: 'Branch is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={branchArray.find(branch => branch.bR_ID === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bR_ID : null); }}
                                        options={branchArray}
                                        getOptionLabel={(option) => option.bR_NAME}
                                        getOptionValue={(option) => option.bR_ID}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isCheckInView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsCheckIn.branchId && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckIn.branchId.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-6 col-md-6 col-lg-6 col-sm-12'>
                            <div className='d-flex'>
                                <div className='d-flex align-items-center grayTextColor'>
                                    <Controller
                                        name="sendEmailCheck"
                                        control={controlCheckIn}
                                        // rules={IsSendMail ? {
                                        //     required: 'Please Enter Email',
                                        //     pattern: {
                                        //         value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        //         message: 'Please Enter a Valid Email Address'
                                        //     }
                                        // } : undefined}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    type="checkbox"
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.checked);
                                                        handleSendmailCheckBox(e);
                                                    }}
                                                    disabled={isCheckInView}
                                                />
                                                <div className='grayTextColor fontFamily fontsize16 fontweightRegular pLeft8'>
                                                    Send Email<span className="mandatoryCss"> *</span>
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <input
                                className={isCheckInView ? "textBoxNewView" : "textBoxNew"}
                                {...registerCheckIn('sendEmail', {
                                    required: IsSendMail ? 'Please Enter Email' : false,
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Please Enter a Valid Email Address'
                                    }
                                })}
                                disabled={isCheckInView}
                            />
                            {errorsCheckIn.sendEmail && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCheckIn.sendEmail.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Check-In Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerCheckIn('checkInNotes')}
                                disabled={isCheckInView}
                            />
                        </div>
                    </div>

                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isCheckInView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const RepairSection = ({ onSubmit, isRepairView, dateFormat, calendarsvgIcon, onCancel, isRepair, employeeArray, isRepairUpdate, existingRepairData, currentEmployeeId }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register: registerRepair,
        handleSubmit: handleSubmitRepair,
        formState: { errors: errorsRepair },
        control: controlRepair,
        watch: watchRepair,
    } = useForm({
        defaultValues: {
            scheduleDate: isRepairUpdate ? existingRepairData?.scheduleDate?.split('T')[0] : null,
            assignTo: isRepairUpdate ? existingRepairData?.assignTo : currentEmployeeId,
            repairCompleted: isRepairUpdate ? existingRepairData?.repairCompleted?.split('T')[0] : null,
            repairCost: isRepairUpdate ? existingRepairData?.repairCost : '',
            notes: isRepairUpdate ? existingRepairData?.notes?.trim() : '',
        },
    });

    return (
        <Modal show={isRepair} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Repair
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitRepair(onSubmit)}>
                    <div className='row'>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Schedule Date<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="scheduleDate"
                                    control={controlRepair}
                                    rules={{
                                        required: "Schedule Date is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Schedule Date is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate < today) {
                                                    return "Schedule Date cannot be in the past";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isRepairView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isRepairView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsRepair.scheduleDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsRepair.scheduleDate.message}
                                </div>
                            )}
                        </div>

                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                Assign to
                            </div>
                            <Controller
                                name="assignTo"
                                control={controlRepair}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={employeeArray.find(repo => repo.id === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                        options={employeeArray}
                                        getOptionLabel={(option) => option.EmployeeName}
                                        getOptionValue={(option) => option.id}
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        isDisabled={isRepairView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsRepair.assignTo && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsRepair.assignTo.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Repair Completed {isRepairUpdate && <span className="mandatoryCss">*</span>}
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="repairCompleted"
                                    control={controlRepair}
                                    rules={isRepairUpdate
                                        ? {
                                            required: "Repair Completed is required",
                                            validate: {
                                                validDate: (value) => {
                                                    if (!value) return "Repair Completed is required";
                                                    // Check if the value is a valid date object
                                                    const dateValue = new Date(value);
                                                    if (isNaN(dateValue.getTime())) {
                                                        return "Invalid date value";
                                                    }

                                                    // Format the date and validate it
                                                    const formattedDate = format(dateValue, dateFormat);
                                                    const [day, month, year] = formattedDate.split('/');
                                                    const selectedDate = validateDate(dateFormat, day, month, year);
                                                    const scheduleDate = new Date(watchRepair('scheduleDate'));
                                                    if (selectedDate < scheduleDate) {
                                                        return "Repair Completed cannot be before Schedule Date";
                                                    }
                                                    const regex = getRegexForDateFormat(dateFormat);
                                                    if (!regex.test(formattedDate)) {
                                                        return "Invalid date format (" + dateFormat + "})";
                                                    }
                                                    return true;
                                                },
                                            },
                                        }
                                        : {} // No validation rules if isRepairUpdate is false
                                    }
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isRepairView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isRepairView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsRepair.repairCompleted && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsRepair.repairCompleted.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Repair Cost
                            </div>
                            <input
                                type="number"
                                min={0}
                                className={isRepairView ? "textBoxNewView" : "textBoxNew"}
                                {...registerRepair('repairCost', {
                                    valueAsNumber: true,
                                    min: {
                                        value: 0,
                                        message: "Repair cost cannot be negative"
                                    }
                                })}
                                disabled={isRepairView}
                            />
                            {errorsRepair.repairCost && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsRepair.repairCost.message}
                                </div>
                            )}
                        </div>

                    </div>

                    <div className='row'>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerRepair('notes')}
                                disabled={isRepairView}
                                maxLength={500}
                            />
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">

                        </div>
                    </div>

                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isRepairView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const DonateSection = ({ onSubmit, isDonateView, dateFormat, calendarsvgIcon, onCancel, isDonate }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register: registerDonate,
        handleSubmit: handleSubmitDonate,
        formState: { errors: errorsDonate },
        control: controlDonate,
    } = useForm({
        defaultValues: {
            dateDonate: '',
            donateTo: '',
            donatedValue: '',
            deductible: '',
            notes: '',
        },
    });

    return (
        <Modal show={isDonate} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Donate
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitDonate(onSubmit)}>
                    <div className='row'>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Date Donated<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="dateDonate"
                                    control={controlDonate}
                                    rules={{
                                        required: "Date Donated is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) return "Date Donated is required";
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate > today) {
                                                    return "Date Donated cannot be in the future";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isDonateView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isDonateView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsDonate.dateDonate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsDonate.dateDonate.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Donate to<span className="mandatoryCss"> *</span></div>
                            <input
                                className={isDonateView ? "textBoxNewView" : "textBoxNew"}
                                {...registerDonate('donateTo', { required: "Donate to is required" })}
                                disabled={isDonateView}
                            />
                            {errorsDonate.donateTo && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsDonate.donateTo.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Donated Value<span className="mandatoryCss"> *</span>
                            </div>
                            <input
                                type="number"
                                min={0}
                                className={isDonateView ? "textBoxNewView" : "textBoxNew"}
                                {...registerDonate('donatedValue', {
                                    required: "Donated Value is required",
                                    valueAsNumber: true,
                                    min: {
                                        value: 0,
                                        message: "Donated Value cannot be negative"
                                    }
                                })}
                                disabled={isDonateView}
                            />
                            {errorsDonate.donatedValue && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsDonate.donatedValue.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Deductible<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='d-flex mTop12 mBottom10'>
                                <div className='d-flex align-items-center'>
                                    <Controller
                                        name="deductible"
                                        control={controlDonate}
                                        rules={{
                                            required: "Deductible is required",
                                            validate: value =>
                                                value === "Yes" || value === "No" || "Deductible must be either Yes or No"
                                        }}
                                        render={({ field }) => (
                                            <>

                                                <input
                                                    type="radio"
                                                    value="Yes"
                                                    {...field}
                                                    checked={field.value === 'Yes'}
                                                    onChange={() => field.onChange('Yes')}
                                                />
                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                    Yes
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className='d-flex pLeft12 align-items-center'>
                                    <Controller
                                        name="deductible"
                                        control={controlDonate}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    type="radio"
                                                    value="No"
                                                    {...field}
                                                    checked={field.value === 'No'}
                                                    onChange={() => field.onChange('No')}
                                                />
                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                    No
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            {errorsDonate.deductible && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsDonate.deductible.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerDonate('notes')}
                                disabled={isDonateView}
                            />
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">

                        </div>
                    </div>

                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isDonateView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const SellSection = ({ onSubmit, isSellView, dateFormat, calendarsvgIcon, onCancel, isSell, rupeesvgIcon }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register: registerSell,
        handleSubmit: handleSubmitSell,
        formState: { errors: errorsSell },
        control: controlSell,
    } = useForm({
        defaultValues: {
            saleDate: '',
            soldTo: '',
            saleAmount: '',
            notes: '',
        },
    });

    return (
        <Modal show={isSell} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Sell
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitSell(onSubmit)}>
                    <div className='row'>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Date Sell<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <Controller
                                    name="saleDate"
                                    control={controlSell}
                                    rules={{
                                        required: "Sale Date is required",
                                        validate: {
                                            validDate: (value) => {
                                                if (!value) {
                                                    return "Sale Date is required";
                                                }
                                                // Check if the value is a valid date object
                                                const dateValue = new Date(value);
                                                if (isNaN(dateValue.getTime())) {
                                                    return "Invalid date value";
                                                }

                                                // Format the date and validate it
                                                const formattedDate = format(dateValue, dateFormat);
                                                const [day, month, year] = formattedDate.split('/');
                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0);
                                                if (selectedDate > today) {
                                                    return "Sale Date cannot be in the future";
                                                }
                                                const regex = getRegexForDateFormat(dateFormat);
                                                if (!regex.test(formattedDate)) {
                                                    return "Invalid date format (" + dateFormat + "})";
                                                }
                                                return true;
                                            },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            dateFormat={dateFormat}
                                            placeholderText={dateFormat.toUpperCase()}
                                            className={isSellView ? "DatePickerDisable" : "DatePicker"}
                                            disabled={isSellView}
                                            ref={(input) => field.ref(input?.inputElement)}
                                        />
                                    )}
                                />
                                <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                            </div>
                            {errorsSell.saleDate && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsSell.saleDate.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Sold to<span className="mandatoryCss"> *</span></div>
                            <input
                                className={isSellView ? "textBoxNewView" : "textBoxNew"}
                                {...registerSell('soldTo', { required: "Sold to is required" })}
                                disabled={isSellView}
                            />
                            {errorsSell.soldTo && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsSell.soldTo.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Sale Amount<span className="mandatoryCss"> *</span>
                            </div>
                            <div className='position-relative'>
                                <input
                                    type="number"
                                    min={0}
                                    className={isSellView ? "textBoxNewView" : "textBoxNew"}
                                    {...registerSell('saleAmount', {
                                        required: "Sale Amount Value is required",
                                        valueAsNumber: true,
                                        min: {
                                            value: 0,
                                            message: "Sale Amount Value cannot be negative"
                                        }
                                    })}
                                    disabled={isSellView}
                                />
                                <img className="eyeContent" src={rupeesvgIcon} alt="rupeesvgIcon" />
                            </div>
                            {errorsSell.saleAmount && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsSell.saleAmount.message}
                                </div>
                            )}
                        </div>
                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeight"
                                {...registerSell('notes')}
                                disabled={isSellView}
                            />
                        </div>
                    </div>

                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isSellView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const AssetAttachmentSection = ({ onSubmit, isAttachmentView, attachmentDropArray, onCancel, isAttachment }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
        resetAttachment();
    };

    const {
        register: registerAttachment,
        handleSubmit: handleSubmitAttachment,
        setValue: setValueAttachment,
        reset: resetAttachment,
        control: controlAttachment
    } = useForm({
        defaultValues: {
            documentType: null,
            documentLink: '',
            documents: '',
        }
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG, PNG, and PDF files are allowed');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;
            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setValueAttachment('documents', base64String);
                setValueAttachment('documentLink', fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };


    const clearFileInput = () => {
        setValueAttachment('documentLink', '');
        document.getElementById('fileInput').value = null;
    };

    return (
        <Modal show={isAttachment || isAttachmentView} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Upload
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitAttachment(onSubmit)}>
                    <div className="fontFamily fontweightMedium fontsize14 blackTextColor">
                        Upload
                    </div>
                    <div className="BoxShadow pAttchment">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-sm-12 d-flex">
                                <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                    <button type="button" style={{ cursor: isAttachmentView ? 'not-allowed' : 'pointer' }} disabled={isAttachmentView} className="coloredButtonSave" onClick={() => document.getElementById('fileInput').click()}>
                                        Browse
                                    </button>
                                </div>
                                <input
                                    id="fileInput"
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept=".jpg,.jpeg,.png,.pdf"
                                    onChange={handleFileChange}
                                />
                                <p className='fontFamily fontweightRegular fontsize14 grayTextColor pLeft30'>Image / Doc Type (insurance copy, warranty copy, Maintenance receipt, Repair Bill, Invoice bill)</p>
                            </div>
                        </div>

                        <div className="col-10 col-md-10 col-lg-10 col-sm-12">
                            <div style={{ position: 'relative', marginTop: "16px" }}>
                                <input
                                    {...registerAttachment('documentLink', { required: "Please Add Document" })}
                                    style={{
                                        paddingLeft: '12px', backgroundColor: isAttachmentView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '32px'
                                    }}
                                    disabled={isAttachmentView}
                                />
                                <button
                                    type="button"
                                    onClick={clearFileInput}
                                    disabled={isAttachmentView}
                                    style={{
                                        position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: isAttachmentView ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    <img src={XMarksvgIcon} alt="clear" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-8 col-md-8 col-lg-8 col-sm-12 mTop20">
                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                            Document Type
                        </div>
                        <Controller
                            name="documentType"
                            control={controlAttachment}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    rules={{ required: 'Document Type is required' }}
                                    value={attachmentDropArray.find(repo => repo.id === field.value) || null}
                                    onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null) }}
                                    options={attachmentDropArray}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isClearable
                                    isSearchable
                                    isDisabled={isAttachmentView}
                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            )}
                        />
                    </div>
                    <div className="text-center mTop150 mBottom50">
                        {!isAttachmentView && (
                            <button type="submit" className="coloredButtonSave">
                                Save
                            </button>)}
                        <button type="button" className="outlineButton" onClick={onCancel}>
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const AssetLinkSection = ({ onSubmit, isLinkView, childDropArray, assetNameAndID, selectedLinkValue, onCancel, isLink }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
        resetLink();
    };

    const {
        handleSubmit: handleSubmitLink,
        reset: resetLink,
        formState: { errors: errorsLink },
        control: controlLink
    } = useForm({
        defaultValues: {
            selectChildParentAsset: null,
            linkValue: selectedLinkValue,
        }
    });

    return (
        <Modal show={isLink} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Link {selectedLinkValue} Asset
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitLink(onSubmit)}>
                    <div className='row mBottom20'><div className='col-4 col-md-4 col-lg-4 col-sm-12 text-end fontFamily fontweightRegular fontsize16 grayTextColor'>{selectedLinkValue === 'Child' ? 'Parent Asset :' : 'Child Asset :'}</div>
                        <div className='col-8 col-md-8 col-lg-8 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>{assetNameAndID}</div></div>
                    <div className='row mBottom100'>
                        <div className='col-4 col-md-4 col-lg-4 col-sm-12 d-flex justify-content-end align-items-center fontFamily fontweightRegular fontsize16 grayTextColor'>
                            {selectedLinkValue === 'Parent' ? 'Parent Asset' : 'Child Asset'}<span className="mandatoryCss"> *</span>:
                        </div>
                        <div className='col-8 col-md-8 col-lg-8 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                            <Controller
                                name="selectChildParentAsset"
                                control={controlLink}
                                rules={{ required: selectedLinkValue + ' field is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={childDropArray.find(repo => repo.id === field.value) || null}
                                        onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null) }}
                                        options={childDropArray}
                                        placeholder=""
                                        getOptionLabel={(option) => option.assetName}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        isSearchable
                                        isDisabled={isLinkView}
                                        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )}
                            />
                            {errorsLink.selectChildParentAsset && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsLink.selectChildParentAsset.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="text-center">
                        {!isLinkView && (
                            <button type="submit" className="coloredButtonLink">
                                Link Asset
                            </button>)}
                        <button type="button" className="outlineButton" onClick={onCancel}>
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const AssetDepreciationSection = ({ onSubmit, isDepreciationView, depreciationMethodOptions, DepreciationValue, dateFormat, onCancel, isDepreciation }) => {
    const [show, setShow] = useState(isDepreciation);

    const handleClose = () => {
        setShow(false);
        onCancel();
        resetDepreciation();
    };

    const {
        register: registerDepreciation,
        handleSubmit: handleSubmitDepreciation,
        reset: resetDepreciation,
        formState: { errors: errorsDepreciation },
        control: controlDepreciation,
        resetField: resetFieldDepreciation,
        getValues: getValuesDepreciation,
        watch: watchDepreciation
    } = useForm({
        defaultValues: {
            depreciableAsset: DepreciationValue.depreciableAsset,
            depreciableCost: DepreciationValue.depreciableCost,
            salvageValue: DepreciationValue.salvageValue,
            assetLife: DepreciationValue.assetLife,
            depreciationMethod: DepreciationValue.depreciationMethod,
            dateAcquired: DepreciationValue.dateAcquired,
        }
    });

    const depreciableAsset = watchDepreciation("depreciableAsset");

    return (
        <Modal show={show} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Manage Asset Depreciation
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitDepreciation(onSubmit)}>
                    <div className='row mBottom20'><div className='col-5 col-md-5 col-lg-5 col-sm-12 text-start pLeft30  fontFamily fontweightRegular fontsize16 grayTextColor'>
                        Depreciable Asset
                    </div>
                        <div className='col-7 col-md-7 col-lg-7 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                            <div className="d-flex mBottom20">
                                <div className="d-flex align-items-center">
                                    <Controller
                                        name="depreciableAsset"
                                        control={controlDepreciation}
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <input
                                                    type="radio"
                                                    value="Yes"
                                                    checked={value === "Yes"}
                                                    onChange={(e) => {
                                                        onChange(e.target.value);
                                                    }}
                                                    disabled={isDepreciationView}
                                                />
                                                <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12">
                                                    Yes
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="d-flex pLeft12 align-items-center">
                                    <Controller
                                        name="depreciableAsset"
                                        control={controlDepreciation}
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <input
                                                    type="radio"
                                                    value="No"
                                                    checked={value === "No"}
                                                    onChange={(e) => {
                                                        onChange(e.target.value);
                                                        if (e.target.value === "No") {
                                                            resetFieldDepreciation("depreciableCost");
                                                            resetFieldDepreciation("assetLife");
                                                            resetFieldDepreciation("salvageValue");
                                                            resetFieldDepreciation("depreciationMethod");
                                                            resetFieldDepreciation("dateAcquired");
                                                        }
                                                    }}
                                                    disabled={isDepreciationView}
                                                />
                                                <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12">
                                                    No
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {depreciableAsset === "Yes" && (
                        <div>
                            <div className='row mBottom20'><div className='col-5 col-md-5 col-lg-5 col-sm-12 text-start pLeft30 d-flex align-items-center fontFamily fontweightRegular fontsize16 grayTextColor'>
                                Depreciable Cost
                            </div>
                                <div className='col-7 col-md-7 col-lg-7 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            placeholder=""
                                            {...registerDepreciation("depreciableCost", {
                                                validate: (value) => {
                                                    if (DepreciationValue.depreciableAsset === "Yes") {
                                                        if (!value) {
                                                            return "Depreciable Cost is required";
                                                        }
                                                        if (isNaN(value)) {
                                                            return "Invalid Depreciable Cost, only numeric characters are allowed";
                                                        }
                                                        if (Number(value) < 0) {
                                                            return "Depreciable Cost cannot be negative";
                                                        }
                                                        return true;
                                                    }
                                                    // If depreciableAsset is not "Yes", no validation error for this field
                                                    return true;
                                                }
                                            })}
                                            className={isDepreciationView ? "textBoxNewView" : "textBoxNew"}
                                            disabled={DepreciationValue.depreciableAsset !== "Yes" || isDepreciationView}
                                        />
                                        <img src={rupeeWithBG} alt="rupeeWithBG" height={45} />
                                    </div>
                                    <div className='d-flex justify-content-end align-items-center fontFamily fontweightRegular fontsize10 grayTextColor'>
                                        Including sales tax, freight and installation
                                    </div>
                                    {errorsDepreciation.depreciableCost && (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errorsDepreciation.depreciableCost.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mBottom20'><div className='col-5 col-md-5 col-lg-5 col-sm-12 text-start pLeft30 d-flex align-items-center fontFamily fontweightRegular fontsize16 grayTextColor'>
                                Salvage Value
                            </div>
                                <div className='col-7 col-md-7 col-lg-7 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            {...registerDepreciation("salvageValue", {
                                                validate: (value) => {
                                                    if (DepreciationValue.depreciableAsset === "Yes") {
                                                        if (value) {
                                                            // Check if the value is a valid number
                                                            const salvageValue = parseFloat(value);
                                                            if (isNaN(salvageValue)) {
                                                                return "Invalid Salvage Value, only numeric values are allowed";
                                                            }

                                                            // Check if the value is non-negative
                                                            if (salvageValue < 0) {
                                                                return "Salvage Value cannot be negative";
                                                            }

                                                            // Check if the salvage value is greater than the depreciable cost
                                                            const depreciableCost = parseFloat(getValuesDepreciation("depreciableCost"));
                                                            if (salvageValue > depreciableCost) {
                                                                return "Salvage Value cannot be greater than Depreciable Cost";
                                                            }
                                                            return true;
                                                        }
                                                    }

                                                    // If depreciableAsset is not "Yes", no validation error for this field
                                                    return true;
                                                }
                                            })}
                                            className={isDepreciationView ? "textBoxNewView" : "textBoxNew"}
                                            disabled={DepreciationValue.depreciableAsset !== "Yes" || isDepreciationView}
                                        />
                                        <img src={rupeeWithBG} alt="rupeeWithBG" height={45} />
                                    </div>
                                    {errorsDepreciation.salvageValue && (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errorsDepreciation.salvageValue.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mBottom20'><div className='col-5 col-md-5 col-lg-5 col-sm-12 text-start pLeft30 d-flex align-items-center fontFamily fontweightRegular fontsize16 grayTextColor'>
                                Asset Life (months)
                            </div>
                                <div className='col-7 col-md-7 col-lg-7 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                                    <input
                                        type="number"
                                        min={0}
                                        {...registerDepreciation("assetLife", {
                                            validate: (value) => {
                                                if (DepreciationValue.depreciableAsset === "Yes") {
                                                    if (value <= 0) {
                                                        return "Asset Life must be greater than 0";
                                                    }
                                                    if (!Number.isInteger(Number(value))) {
                                                        return "Asset Life must be a positive integer";
                                                    }
                                                    return true;
                                                }
                                                // If depreciableAsset is not "Yes", no validation error for this field
                                                return true;
                                            }
                                        })}
                                        className={isDepreciationView ? "textBoxNewView" : "textBoxNew"}
                                        disabled={DepreciationValue.depreciableAsset !== "Yes" || isDepreciationView}
                                    />
                                    {errorsDepreciation.assetLife && (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errorsDepreciation.assetLife.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mBottom20'><div className='col-5 col-md-5 col-lg-5 col-sm-12 text-start pLeft30 d-flex align-items-center fontFamily fontweightRegular fontsize16 grayTextColor'>
                                Depreciation Method
                            </div>
                                <div className='col-7 col-md-7 col-lg-7 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                                    <Controller
                                        name="depreciationMethod"
                                        control={controlDepreciation}
                                        rules={{
                                            validate: (value) => {
                                                if (DepreciationValue.depreciableAsset === "Yes" && !value) {
                                                    return "Depreciation Method is required";
                                                }
                                                // If depreciableAsset is not "Yes", no validation error for this field
                                                return true;
                                            }
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                value={depreciationMethodOptions.find(option => option.value === field.value) || null}
                                                onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                                                options={depreciationMethodOptions}
                                                placeholder=""
                                                isSearchable
                                                onBlur={field.onBlur}
                                                className="fontFamily fontweightRegular fontsize16"
                                                styles={customStyles}
                                                isDisabled={DepreciationValue.depreciableAsset !== "Yes" || isDepreciationView}
                                            />
                                        )}
                                    />
                                    {errorsDepreciation.depreciationMethod && (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errorsDepreciation.depreciationMethod.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mBottom40'><div className='col-5 col-md-5 col-lg-5 col-sm-12 text-start pLeft30 d-flex align-items-center fontFamily fontweightRegular fontsize16 grayTextColor'>
                                Date Acquired
                            </div>
                                <div className='col-7 col-md-7 col-lg-7 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                                    <div className="d-flex">
                                        <Controller
                                            name="dateAcquired"
                                            control={controlDepreciation}
                                            rules={{
                                                validate: {
                                                    validDate: (value) => {
                                                        if (DepreciationValue.depreciableAsset === "Yes") {
                                                            if (!value) {
                                                                return "Date Acquired is required";
                                                            }

                                                            // Check if value is a valid date
                                                            const dateValue = new Date(value);
                                                            if (isNaN(dateValue.getTime())) {
                                                                return "Invalid date value";
                                                            }

                                                            const formattedDate = format(dateValue, dateFormat);
                                                            const [day, month, year] = formattedDate.split('/');
                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                            const today = new Date();
                                                            today.setHours(0, 0, 0, 0);

                                                            if (selectedDate > today) {
                                                                return "Date Acquired cannot be in the future";
                                                            }

                                                            const regex = getRegexForDateFormat(dateFormat);
                                                            if (!regex.test(formattedDate)) {
                                                                return `Invalid date format (${dateFormat})`;
                                                            }
                                                            return true;
                                                        }
                                                        // If depreciableAsset is not "Yes", no validation error for this field
                                                        return true;
                                                    }
                                                }
                                            }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    selected={field.value}
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                    }}
                                                    dateFormat={dateFormat}
                                                    className={DepreciationValue.depreciableAsset !== "Yes" || isDepreciationView ? "customDatePickerDisable date-picker-input" : "customDatePicker date-picker-input"}
                                                    disabled={DepreciationValue.depreciableAsset !== "Yes" || isDepreciationView}
                                                    ref={(input) => field.ref(input?.inputElement)}
                                                />
                                            )}
                                        />
                                        <img src={datesvgIcon} alt="datesvgIcon" height={45} />
                                    </div>
                                    {errorsDepreciation.dateAcquired && (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errorsDepreciation.dateAcquired.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )};

                    <div className="text-center mBottom20">
                        {!isDepreciationView && (
                            <button type="submit" className="coloredButtonSave">
                                Update
                            </button>)}
                        <button type="button" className="outlineButton" onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const AssetAddSection = ({ onSubmit, onCancel, isAdd }) => {
    const [show, setShow] = useState(isAdd);

    const handleClose = () => {
        setShow(false);
        onCancel();
        resetAdd();
    };

    const {
        register: registerDepreciation,
        handleSubmit: handleSubmitAdd,
        reset: resetAdd,
    } = useForm({
        defaultValues: {
            comments: ""
        }
    });

    return (
        <Modal show={show} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Add Comments
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitAdd(onSubmit)}>
                    <div className='row mBottom40'><div className='col-4 col-md-4 col-lg-4 col-sm-12 text-end fontFamily fontweightRegular fontsize16 grayTextColor'>
                        Comments
                    </div>
                        <div className='col-8 col-md-8 col-lg-8 col-sm-12 fontFamily fontweightMedium fontsize16 blackTextColor'>
                            <textarea
                                placeholder=""
                                className="textareaCustom textAeraHeightAdd"
                                {...registerDepreciation('comments')}
                            />
                        </div>
                    </div>

                    <div className="text-center mBottom20">
                        <button type="submit" className="coloredButtonSave">
                            Save
                        </button>
                        <button type="button" className="outlineButton" onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const CopySection = ({ onSubmit, isCopyAssetView, onCancel, isCopyAsset, assetID }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        onCancel();
    };

    const {
        register: registerCopy,
        handleSubmit: handleSubmitCopy,
        formState: { errors: errorsCopyAsset },
    } = useForm({
        defaultValues: {
            cloneCount: 1,

        },
    });

    return (
        <Modal show={isCopyAsset} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Clone Asset
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitCopy(onSubmit)}>
                    <div className='row'>
                        <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                            <div className="fontFamily fontweightBold fontsize16 pTop24">
                                Do you want to create a copy of the asset?  &lt;{assetID}&gt;
                            </div>
                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                Clone Count
                            </div>
                            <input
                                className={isCopyAssetView ? "textBoxNewView" : "textBoxNew"}
                                {...registerCopy('cloneCount', {
                                    required: "Count is required", min: {
                                        value: 1,
                                        message: "Count must be at least 1"
                                    }
                                })}
                                disabled={isCopyAssetView}
                                type="number"
                            />
                            {errorsCopyAsset.cloneCount && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorsCopyAsset.cloneCount.message}
                                </div>
                            )}

                        </div>
                    </div>
                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                        {!isCopyAssetView && (
                            <button type="submit" className="coloredButtonSave">Save</button>
                        )}
                        <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export const AssetRequestSection = (props) => {

    const [AssetRequestCategoryDropdown, setAssetRequestCategoryDropdown] = useState(props.AssetRequestCategoryDropdown);
    const [AssetRequestSubCategoryDropdown, setAssetRequestSubCategoryDropdown] = useState(props.AssetRequestSubCategoryDropdown);
    const [AssetRequestFilterSubCategoryDropdown, setAssetRequestFilterSubCategoryDropdown] = useState(props.AssetRequestSubCategoryDropdown);
    const [AssetRequestPriorityDropdown, setAssetRequestPriorityDropdown] = useState(props.AssetRequestPriorityDropdown);

    const {
        register: registerRequest,
        handleSubmit: handleSubmitRequest,
        setValue: setValueRequest,
        getValues: getValueRequest,
        reset: resetRequest,
        control: controlRequest,
        resetField: resetFieldRequest,
        formState: { errors: errors },
        clearErrors: clearErrors
    } = useForm({
        defaultValues: {
            requestCategory: 0,
            requestSubCategory: 0,
            requestPriority: 0,
            requestSubject: '',
            requestExplain: '',
            requestDocument: '',
            requestDocumentName: ''
        }
    });

    useEffect(() => {
        if (props.IsAddRequest) {
            const selectedSubCategory = AssetRequestSubCategoryDropdown.find((e) => e.id == props?.AssetRequestSettingsValue?.mapForAsset)

            const filterSubCategory = AssetRequestSubCategoryDropdown.filter((e) => e?.requestCategoryId == selectedSubCategory?.requestCategoryId);
            setAssetRequestFilterSubCategoryDropdown(filterSubCategory)

            setValueRequest('requestCategory', selectedSubCategory?.requestCategoryId);
            setValueRequest('requestSubCategory', props?.AssetRequestSettingsValue?.mapForAsset)
            setValueRequest('requestPriority', props?.AssetRequestSettingsValue?.defaultPriority)
        }
    }, [props.IsAddRequest]);

    const handleClose = () => {
        resetRequest();
        props.onCancel();

    };

    const OnchangeCategory = (event) => {
        const filterSubCategory = AssetRequestSubCategoryDropdown.filter((e) => e?.requestCategoryId == event.id);
        setValueRequest('requestCategory', event.id)
        resetFieldRequest('requestSubCategory');
        setAssetRequestFilterSubCategoryDropdown(filterSubCategory);
    };

    const OnchangeSubCategory = (event) => {
        if (event) {
            clearErrors('requestSubCategory');
        }
        setValueRequest('requestSubCategory', event.id)

    };

    const OnchangePriority = (event) => {
        setValueRequest('requestPriority', event.id)
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            if (!['image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG and PNG files are allowed');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;

            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setValueRequest('requestDocument', base64String);
                setValueRequest('requestDocumentName', fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const clearFileInput = () => {
        setValueRequest('requestDocument', '');
        setValueRequest('requestDocumentName', '');
        document.getElementById('fileInput').value = null;
    };

    return (
        <Modal show={props.IsRequest} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Asset Request
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitRequest(props.onSubmit)}>
                    <div>
                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Request Category<span className='mandatoryCss'>*</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <Controller
                                    name="requestCategory"
                                    control={controlRequest}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={AssetRequestCategoryDropdown.find(repo => repo.id === field.value) || null}
                                            onChange={OnchangeCategory}

                                            options={AssetRequestCategoryDropdown}
                                            getOptionLabel={(option) => option.requestCategoryName}
                                            getOptionValue={(option) => option.id}
                                            placeholder=""
                                            // isClearable
                                            isSearchable
                                            isDisabled={true}
                                            styles={{
                                                menu: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }), // Add this line
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Request Sub <br /> Category<span className='mandatoryCss'>*</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <Controller
                                    name="requestSubCategory"
                                    control={controlRequest}
                                    rules={{
                                        required: 'Request Sub Category is required',
                                        validate: {
                                            validOption: value => {
                                                return value && value.id !== '' || 'Request Sub Category is required';
                                            }
                                        }
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={AssetRequestFilterSubCategoryDropdown.find(repo => repo.id === field.value) || null}
                                            onChange={OnchangeSubCategory}
                                            options={AssetRequestFilterSubCategoryDropdown}
                                            getOptionLabel={(option) => option.requestSubCategoryName}
                                            getOptionValue={(option) => option.id}
                                            placeholder=""
                                            isSearchable
                                            isDisabled={true}
                                            styles={{
                                                menu: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div style={{ marginLeft: '33%' }}>
                                {errors.requestSubCategory && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.requestSubCategory.message}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Request Priority<span className='mandatoryCss'>*</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <Controller
                                    name="requestPriority"
                                    control={controlRequest}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={AssetRequestPriorityDropdown.find(repo => repo.id === field.value) || null}
                                            onChange={OnchangePriority}
                                            options={AssetRequestPriorityDropdown}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder=""
                                            // isClearable
                                            isSearchable
                                            isDisabled={props.IsMaintenanceView}
                                            styles={{
                                                menu: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }), // Add this line
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Subject<span className='mandatoryCss'>*</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <input
                                    className="textinputCustomAsset"
                                    placeholder=""
                                    {...registerRequest('requestSubject', {
                                        required: "Subject is required"
                                    })}

                                    disabled={props.IsMaintenanceView}
                                />
                            </div>
                            <div style={{ marginLeft: '33%' }}>
                                {errors.requestSubject && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.requestSubject.message}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Explain Your Request Details<span className='mandatoryCss'>*</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <textarea
                                    placeholder=""
                                    className="textareaCustom textAeraHeight"
                                    {...registerRequest('requestExplain', {
                                        required: "Request Detail is required"
                                    })}
                                    disabled={props.IsMaintenanceView}
                                />

                            </div>
                            <div style={{ marginLeft: '33%' }}>
                                {errors.requestExplain && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.requestExplain.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Attach Document
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className="col-12 col-md-12 col-lg-12 col-sm-12 d-flex">
                                <div className="BoxShadow pAttchment" style={{ width: '100%' }}>
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12 col-sm-12 d-flex">
                                            <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                                <button
                                                    type="button"
                                                    // style={{ cursor: isAttachmentView ? 'not-allowed' : 'pointer' }} 
                                                    style={{ cursor: 'pointer' }}

                                                    // disabled={isAttachmentView} 
                                                    className="coloredButtonSave" onClick={() => document.getElementById('fileInput').click()}>
                                                    Browse
                                                </button>
                                            </div>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept=".jpg,.jpeg,.png,.pdf"
                                                onChange={handleFileChange}
                                            />
                                            <p className='fontFamily fontweightRegular fontsize14 grayTextColor pLeft30'>Only (JPG, PNG) Max file size 2mb</p>
                                        </div>
                                    </div>

                                    <div className="col-10 col-md-10 col-lg-10 col-sm-12">
                                        <div style={{ position: 'relative', marginTop: "16px" }}>
                                            <input
                                                {...registerRequest('requestDocumentName')}
                                                // style={{
                                                //     paddingLeft: '12px', backgroundColor: isAttachmentView ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '32px'
                                                // }}
                                                style={{
                                                    paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '32px'
                                                }}
                                            // disabled={isAttachmentView}
                                            />
                                            <button
                                                type="button"
                                                onClick={clearFileInput}
                                                // disabled={isAttachmentView}
                                                // style={{
                                                //     position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: isAttachmentView ? 'not-allowed' : 'pointer'
                                                // }}
                                                style={{
                                                    position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: 'pointer'
                                                }}
                                            >
                                                <img src={XMarksvgIcon} alt="clear" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="text-center mTop35">
                        {props.IsAddRequest && (
                            <button type="submit" className="coloredButtonSave">
                                Save
                            </button>)
                        }
                        {props.IsRequestEdit && (
                            <button type="submit" className="coloredButtonSave">
                                Update
                            </button>)
                        }
                        <button type="button" className="outlineButton" onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
};

export const AssetMaintenanceSection = (props) => {
    const maintenanceStatus = [
        { idValue: 'schedule', Value: 'Schedule' },
        { idValue: 'inprogress', Value: 'In-Progress' },
        { idValue: 'onhold', Value: 'On-Hold' },
        { idValue: 'cancelled', Value: 'Cancelled' },
        { idValue: 'completed', Value: 'Completed' },
    ];

    const maintenanceByDropdown = [
        { idValue: 'inhouse', Value: 'In-House' },
        { idValue: 'vendor', Value: 'Vendor' }
    ];

    const {
        register: registerMaintenance,
        handleSubmit: handleSubmitMaintenance,
        setValue: setValueMaintenance,
        getValues: getValueMaintenance,
        reset: resetMaintenance,
        control: controlMaintenance,
        formState: { errors: errors },
    } = useForm({
        defaultValues: {
            maintenanceTitle: '',
            maintenanceDetail: '',
            maintenanceScheduleDate: null,
            maintenanceBy: 'inhouse',
            maintenanceStatus: 'schedule',
            maintenanceEstimateReturnDate: null,
            maintenanceDateCompleted: null,
            maintenanceCost: 0,
        }
    });

    useEffect(() => {
        debugger
        if (props.IsMaintenanceView || props.IsMaintenanceEdit) {
            setValueMaintenance('maintenanceTitle', props.SelectedMaintenanceValue?.title);
            setValueMaintenance('maintenanceDetail', props.SelectedMaintenanceValue?.details);
            setValueMaintenance('maintenanceScheduleDate', props.SelectedMaintenanceValue?.scheduleDate == null ? null : props.SelectedMaintenanceValue?.scheduleDate.split("T")[0]);
            setValueMaintenance('maintenanceBy', props.SelectedMaintenanceValue?.maintenanceBy);
            setValueMaintenance('maintenanceStatus', props.SelectedMaintenanceValue?.maintenanceStatus);
            setValueMaintenance('maintenanceEstimateReturnDate', props.SelectedMaintenanceValue?.estimatedReturnDate == null ? null : props.SelectedMaintenanceValue?.estimatedReturnDate.split("T")[0]);
            setValueMaintenance('maintenanceDateCompleted', props.SelectedMaintenanceValue?.dateCompleted == null ? null : props.SelectedMaintenanceValue?.dateCompleted.split("T")[0]);
            setValueMaintenance('maintenanceCost', props.SelectedMaintenanceValue?.maintenanceCost);
        }
    }, [props.IsMaintenanceView, props.IsMaintenanceEdit]);

    const handleClose = () => {
        props.onCancel();
        resetMaintenance();
    };

    const OnchangeMaintenanceStatus = (event) => {
        setValueMaintenance('maintenanceStatus', event.idValue);
    };

    const OnchangeMaintenanceBy = (event) => {
        setValueMaintenance('maintenanceBy', event.idValue);
    };


    return (
        <Modal show={props.IsMaintenance} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Asset Maintenance
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmitMaintenance(props.onSubmit)}>
                    <div>
                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Title<span className='mandatoryCss'>*</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <input
                                    className="textinputCustomAsset"
                                    placeholder="Enter Title"
                                    {...registerMaintenance("maintenanceTitle", {
                                        required: "Title is required"
                                    })}
                                    disabled={props.IsMaintenanceView}
                                />
                            </div>
                            <div style={{ marginLeft: '33%' }}>
                                {errors.maintenanceTitle && (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {errors.maintenanceTitle.message}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Details
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <textarea
                                    placeholder=""
                                    className="textareaCustom textAeraHeight"
                                    {...registerMaintenance('maintenanceDetail')}
                                    disabled={props.IsMaintenanceView}
                                />

                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Schedule Date
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <div className='position-relative' style={{ width: '100%' }}>
                                    <Controller
                                        name="maintenanceScheduleDate"
                                        control={controlMaintenance}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                dateFormat={props.dateFormat}
                                                placeholderText={props.dateFormat.toUpperCase()}
                                                className={props.IsMaintenanceView ? "DatePickerDisable" : "DatePicker"}
                                                disabled={props.IsMaintenanceView}
                                                ref={(input) => field.ref(input?.inputElement)}
                                            />
                                        )}
                                    />
                                    <img className="eyeContent" src={props.calendarsvgIcon} alt="calendar icon" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Maintenance By
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <Controller
                                    name="maintenanceBy"
                                    control={controlMaintenance}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={maintenanceByDropdown.find(repo => repo.idValue === getValueMaintenance('maintenanceBy')) || null}
                                            onChange={OnchangeMaintenanceBy}
                                            options={maintenanceByDropdown}
                                            getOptionLabel={(option) => option.Value}
                                            getOptionValue={(option) => option.idValue}
                                            placeholder=""
                                            // isClearable
                                            isSearchable
                                            isDisabled={props.IsMaintenanceView}
                                            styles={{
                                                menu: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Maintenance Status
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <Controller
                                    name="maintenanceStatus"
                                    control={controlMaintenance}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={maintenanceStatus.find(repo => repo.idValue === getValueMaintenance('maintenanceStatus')) || null}
                                            onChange={OnchangeMaintenanceStatus}
                                            options={maintenanceStatus}
                                            getOptionLabel={(option) => option.Value}
                                            getOptionValue={(option) => option.idValue}
                                            placeholder=""
                                            // isClearable
                                            isSearchable
                                            isDisabled={props.IsMaintenanceView}
                                            styles={{
                                                menu: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }), // Add this line
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Estimate Returned <br /> Date
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <div className='position-relative' style={{ width: '100%' }}>
                                    <Controller
                                        name="maintenanceEstimateReturnDate"
                                        control={controlMaintenance}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                dateFormat={props.dateFormat}
                                                placeholderText={props.dateFormat.toUpperCase()}
                                                className={props.IsMaintenanceView ? "DatePickerDisable" : "DatePicker"}
                                                disabled={props.IsMaintenanceView}
                                                ref={(input) => field.ref(input?.inputElement)}
                                            />
                                        )}
                                    />
                                    <img className="eyeContent" src={props.calendarsvgIcon} alt="calendar icon" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Date Completed
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <div className='position-relative' style={{ width: '100%' }}>
                                    <Controller
                                        name="maintenanceDateCompleted"
                                        control={controlMaintenance}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                dateFormat={props.dateFormat}
                                                placeholderText={props.dateFormat.toUpperCase()}
                                                className={props.IsMaintenanceView ? "DatePickerDisable" : "DatePicker"}
                                                disabled={props.IsMaintenanceView}
                                                ref={(input) => field.ref(input?.inputElement)}
                                            />
                                        )}
                                    />
                                    <img className="eyeContent" src={props.calendarsvgIcon} alt="calendar icon" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Maintenance Cost
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <div className="d-flex w-100">
                                    <input
                                        type="text"
                                        placeholder=""
                                        // value={maintenanceCost}
                                        {...registerMaintenance('maintenanceCost', {
                                            valueAsNumber: true,
                                            min: {
                                                value: 0,
                                                message: "Maintenance cost cannot be negative"
                                            }
                                        })}
                                        className={props.IsMaintenanceView ? "textBoxNewView" : "textBoxNew"}
                                        disabled={props.IsMaintenanceView}
                                    />
                                    <img src={rupeeWithBG} alt="rupeeWithBG" height={45} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center mTop35">
                        {props.IsAddMaintenance && (
                            <button type="submit" className="coloredButtonSave">
                                Save
                            </button>)
                        }
                        {props.IsMaintenanceEdit && (
                            <button type="submit" className="coloredButtonSave">
                                Update
                            </button>)
                        }
                        <button type="button" className="outlineButton" onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
};

export const AssetWarrantySection = (props) => {
    const [enteredNumber, setEnteredNumber] = useState(null);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        watch
    } = useForm({
        defaultValues: {
            length: 0,
            notes: '',
            document: '',
            documentLink: '',
            expirationDate: '',
            selectedNumbers: [],
        }
    });
    const expirationDate = watch('expirationDate');
    const documentLink = watch('documentLink');

    useEffect(() => {
        if (props.SelectedWarrantyValue) {
            setValue('length', props.SelectedWarrantyValue?.length);
            setValue('notes', props.SelectedWarrantyValue?.notes?.trim());
            setValue('document', props.SelectedWarrantyValue?.document);
            setValue('documentLink', props.SelectedWarrantyValue?.documentLink);
            setValue('expirationDate', props.SelectedWarrantyValue?.expirationDate.split('T')[0]);
            const remindersArray = props.SelectedWarrantyValue?.setReminders?.split(',').map(Number);
            setSelectedNumbers(remindersArray);
            setValue('selectedNumbers', remindersArray);
        }
    }, [props.SelectedWarrantyValue]);

    // Function to handle length calculation based on expiration date
    useEffect(() => {
        if (expirationDate) {
            const currentDate = new Date();
            const purchaseDate = new Date(props?.purchaseDate);
            const selectedDate = new Date(expirationDate);

            // Check if the selected date is valid (not before purchaseDate or in the past)
            if (isBefore(selectedDate, currentDate)) {
                setValue('length', ''); // Reset length if date is in the past
                toast.error("Please select future date");
            } else if (isBefore(selectedDate, purchaseDate)) {
                setValue('length', ''); // Reset length if date is before purchase date
                toast.error("Select date after purchase date");
            } else {
                // Calculate the number of months between current date and expiration date
                const lengthInMonths = differenceInMonths(selectedDate, currentDate);
                setValue('length', lengthInMonths); // Set the calculated length
            }
        }
    }, [expirationDate, setValue, props.purchaseDate]);

    const handleSelectNumber = (event) => {
        const number = parseInt(event.target.value, 10);
        if (number >= 1 && number <= 365) {
            setEnteredNumber(number);
        } else {
            setEnteredNumber(null);
        }
    };

    const handleAddDate = () => {
        if (props.IsWarrantyView) {
            return;
        }
        if (enteredNumber !== null) {
            // Ensure selectedNumbers is always an array before using includes()
            if (!selectedNumbers?.includes(enteredNumber)) {
                const updatedSelectedNumbers = [...selectedNumbers, enteredNumber];
                setSelectedNumbers(updatedSelectedNumbers);
                setValue("selectedNumbers", updatedSelectedNumbers);
                setEnteredNumber(null);
            } else {
                toast.error("You have already entered this number");
            }
        } else {
            toast.error("Please enter a number between 1 and 365");
        }
    };

    const handleRemoveDate = (number) => {
        if (props.IsWarrantyView) {
            return;
        }
        setSelectedNumbers(prevSelected => prevSelected?.filter(selected => selected !== number));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check file size (limit to 2MB)
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            // Disallow PDFs and only allow JPEG, JPG, and PNG
            if (!['image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG, and PNG files are allowed.');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;

            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setValue('document', base64String);
                setValue('documentLink', fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const clearFileInput = () => {
        setValue('document', '');
        setValue('documentLink', '');
        document.getElementById('fileInput').value = null;
    };

    const handleClose = () => {
        props.onClose();
        reset();
        setEnteredNumber(null);
        setSelectedNumbers([]);
    };


    return (
        <Modal show={props.IsWarranty} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Add New Warranty
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(props.onSubmit)}>
                    <div>
                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Length<span className='mandatoryCss'> *</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8 d-flex">
                                <input
                                    type="number"
                                    className="textBoxNewView"
                                    {...register('length', { required: "Length is required" })}
                                    disabled={true}
                                />
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pLeft8 pTop12">Moths</div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Expiries Date<span className="mandatoryCss"> *</span>
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8" >
                                <div className="d-flex">
                                    <Controller
                                        name="expirationDate"
                                        control={control}
                                        rules={{
                                            required: "Expiration Date is required",
                                            validate: {
                                                validDate: (value) => {
                                                    if (!value) return "Expiration Date is required";
                                                    if (!props.SelectedWarrantyValue.expirationDate) {
                                                        const formattedDate = format(value, props.dateFormat);
                                                        const regex = getRegexForDateFormat(props.dateFormat);
                                                        if (!regex.test(formattedDate)) {
                                                            return "Invalid date format (" + props.dateFormat + ")";
                                                        }
                                                    }
                                                    return true;
                                                }
                                            }
                                        }}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                dateFormat={props.dateFormat}
                                                placeholderText={props.dateFormat.toUpperCase()}
                                                className={props.IsWarrantyView ? "DatePickerDisable" : "DatePicker"}
                                                disabled={props.IsWarrantyView}
                                                ref={(input) => field.ref(input?.inputElement)}
                                            />
                                        )}
                                    />
                                    <img src={datesvgIcon} alt="datesvgIcon" height={45} />
                                </div>
                                {errors.expirationDate && (
                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                        {errors.expirationDate.message}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4 col-md-4 col-lg-4 col-sm-4 d-flex">
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                    Notes
                                </div>
                            </div>
                            <div className="col-8 col-md-8 col-lg-8 col-sm-8">
                                <textarea
                                    placeholder=""
                                    className={props.IsWarrantyView ? "textBoxNewView textAeraHeight" : "textBoxNew textAeraHeight"}
                                    {...register('notes')}
                                    disabled={props.IsWarrantyView}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="fontFamily fontweightRegular fontsize16 blackTextColor">
                                Set Reminder before warranty expiry (in days)
                            </div>
                            <div className="d-flex">
                                <input
                                    type="number"
                                    min={1}
                                    max={365}
                                    value={enteredNumber || ''}
                                    className="custom-textBox blackTextColor fontFamily fontweightRegular fontsize12"
                                    onChange={handleSelectNumber}
                                    placeholder="Enter Count"
                                    disabled={props.IsWarrantyView}
                                />
                                <button
                                    type="button"
                                    className="fontFamily fontweightRegular fontsize16 coloredButton mLeft20"
                                    onClick={handleAddDate}
                                >
                                    + Add
                                </button>
                            </div>
                        </div>

                        <div className="card mt-2" style={{ height: '158px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                            <div className="employee-container">
                                {selectedNumbers?.map((number) => (
                                    <div key={number} className="smallCard Card">
                                        <div className="employee-info">
                                            <span className="numberInCard">{number}</span>
                                        </div>
                                        <a href="#remove-icon" className="remove-icon" onClick={() => handleRemoveDate(number)}>
                                            <FontAwesomeIcon icon={faTimes} color='red' />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="fontFamily fontweightRegular fontsize16 blackTextColor mt-2">
                            Attach Document
                        </div>
                        <div className="card mt-2" style={{ height: '100px' }}>
                            <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                <button
                                    type="button"
                                    style={{ cursor: props.IsWarrantyView ? 'not-allowed' : 'pointer', margin: '15px 0px 10px 10px' }}
                                    disabled={props.IsWarrantyView}
                                    className="coloredButtonSave"
                                    onClick={() => document.getElementById('fileInput').click()}
                                >
                                    Browse
                                </button>
                                <div className="fontFamily fontweightRegular grayTextColor fontsize14 mLeft8 mTop12">
                                    Only (JPG, PNG) Max file size 2mb
                                </div>
                            </div>
                            <input
                                id="fileInput"
                                type="file"
                                style={{ display: 'none' }}
                                accept=".jpg,.jpeg,.png"
                                onChange={handleFileChange}
                            />
                            {documentLink && <div style={{ position: 'relative', margin: "10px" }}>
                                <input
                                    {...register('documentLink')}
                                    style={{
                                        padding: '12px',
                                        backgroundColor: props.IsWarrantyView ? '#FAFAFB' : '#FFFFFF',
                                        border: '#00000014 1px solid',
                                        borderRadius: '5px',
                                        fontFamily: '"Nunito", sans-serif',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        width: '100%',
                                        outline: 'none',
                                        height: '32px',
                                    }}
                                    disabled={props.IsWarrantyView}
                                />
                                <button
                                    type="button"
                                    onClick={clearFileInput}
                                    disabled={props.IsWarrantyView}
                                    style={{
                                        position: 'absolute',
                                        right: '8px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        background: 'none',
                                        border: 'none',
                                        cursor: props.IsWarrantyView ? 'not-allowed' : 'pointer',
                                    }}
                                >
                                    <img src={XMarksvgIcon} alt="clear" />
                                </button>
                            </div>
                            }
                        </div>
                    </div>

                    <div className="text-center mTop35">
                        {!props.IsWarrantyView && (
                            <button type="submit" className="coloredButtonSave">
                                Save
                            </button>
                        )}
                        <button type="button" className="outlineButton" onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
};

export const AssetInsuranceSection = (props) => {
    const [show, setShow] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        watch
    } = useForm({
        defaultValues: {
            insuranceTagId: '',
        }
    });

    const handleClose = () => {
        props.onClose();
        reset();
    };


    return (
        <Modal show={props.IsInsurance} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Link an Insurance
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="fontFamily fontsize14 fontweightMedium grayTextColor">
                    Select an insurance from the list below. To define a new insurance go to Settings {">"} insurances.
                </div>
            </Modal.Body>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <div className="row pTop24">
                    <div className="" style={{ margin: '0px 25px', width: '90%' }}>
                        <Controller
                            name="insuranceTagId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    value={props?.insuranceArray.find(repo => repo.insuranceTagId === field.value) || null}
                                    onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.insuranceTagId : null) }}
                                    options={props?.insuranceArray}
                                    placeholder="Insurance ID(Insurance Name)"
                                    getOptionLabel={(option) => option.insuranceTagId + " - " + option.insuranceName}
                                    getOptionValue={(option) => option.insuranceTagId}
                                    isClearable
                                    isSearchable
                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            )}
                        />
                        {errors.insuranceTagId && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                {errors.insuranceTagId.message}
                            </div>
                        )}
                    </div>
                </div>
                <div className="text-center mTop35 mBottom20">
                    <button type="submit" className="coloredButtonSave">
                        Link Insurance
                    </button>
                    <button type="button" className="outlineButton" onClick={handleClose}>
                        Close
                    </button>
                </div>
            </form>
        </Modal >
    );
};

export const SampleExcelDownload = ({ closeModal, showModal, handleDownloadSampleExcel, onClickFileAssetInputRef }) => {
    const [show, setShow] = useState(showModal);

    const handleClose = () => {
        setShow(false);
        closeModal();
    };

    const handleYesClick = () => {
        handleDownloadSampleExcel();
        handleClose();  // Close the modal after download
    };

    const handleNoClick = () => {
        onClickFileAssetInputRef();  // Trigger the file input for importing
        handleClose();  // Close the modal after the user chooses to import
    };

    return (
        <Modal show={show} onHide={handleClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                        Import Asset
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="fontFamily fontsize14 fontweightMedium grayTextColor">
                    Would you like to download the sample Excel file before importing?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="coloredButton" onClick={handleNoClick}>
                    No, Import File
                </button>
                <button className="coloredButtonAdd" onClick={handleYesClick}>
                    Yes, Download Sample
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export const ViewDetailsModal = ({ show, onClose, messages }) => {
    const formattedMessages = Array.isArray(messages) ? messages : [messages];
    return (
        <Modal show={show} onHide={onClose} centered size="md" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Validation Messages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {formattedMessages.length > 0 ? (
                    <div className="fontFamily fontsize16 fontweightMedium grayTextColor">
                        {formattedMessages.join(", ")}
                    </div>
                ) : (
                    <div className="fontFamily fontsize16 fontweightMedium grayTextColor">
                        No validation messages available.
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="outlineButton" onClick={onClose}>Close</button>
            </Modal.Footer>
        </Modal>
    );
};
