import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportDataDepartment = (data, format) => {
    const headers = ["SI.No", "Department", "Code", "Description", "Status"];

    const rows = data.map(Department => {
        return [
            Department.Rowid || '',
            Department.departmentName?.trim() || '',
            Department.departmentCode?.trim() || '',
            Department.description?.trim() || '',
            Department.status || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Department_Data_${formattedDate}.${fileExtension}`);
};

export const exportDataSubDepartment = (data, format) => {
    const headers = ["SI.No", "Department", "Sub Department", "Code", "Description", "Status"];
    const rows = data.map(SubDepartment => {
        return [
            SubDepartment.Rowid || '',
            SubDepartment.departmentName?.trim() || '',
            SubDepartment.subDepartmentName?.trim() || '',
            SubDepartment.subDepartmentCode?.trim() || '',
            SubDepartment.description?.trim() || '',
            SubDepartment.status || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `SubDepartment_Data_${formattedDate}.${fileExtension}`);
};


export const exportDataTeam = (teamData, format, departmentData, subDepartmentData) => {
    const headers = ["SI.No", "Department Name", "Sub-Department Name", "Team Name", "Users", "Status"];

    const getEmployeeCount = (empIdString) => {
        if (!empIdString) return 0;
        return empIdString.split(',').length;
    };

    const getDepartmentNames = (departmentIds, departmentData) => {
        if (!departmentIds) return '';
        const ids = departmentIds.split(',');
        const names = ids.map(id => {
            const department = departmentData.find(dep => dep.id.toString() === id.trim());
            console.log(`Department ID: ${id}, Found: ${department ? department.departmentName : 'Not Found'}`);
            return department ? department.departmentName : '';
        }).filter(departmentName => departmentName !== '');
        return names.join(', ');
    };

    const getSubDepartmentNames = (subDepartmentIds, subDepartmentData) => {
        if (!subDepartmentIds) return '';
        const ids = subDepartmentIds.split(',');
        const names = ids.map(id => {
            const subDepartment = subDepartmentData.find(sub => sub.id.toString() === id.trim());
            console.log(`Sub-Department ID: ${id}, Found: ${subDepartment ? subDepartment.subDepartmentName : 'Not Found'}`);
            return subDepartment ? subDepartment.subDepartmentName : '';
        }).filter(subDepartmentName => subDepartmentName !== '');
        return names.join(', ');
    };

    const rows = teamData.map((team, index) => {
        const departmentNames = getDepartmentNames(team.departmentId, departmentData);
        const subDepartmentNames = getSubDepartmentNames(team.subDepartmentId, subDepartmentData);
        const employeeCount = getEmployeeCount(team.empId);
        return [
            index + 1,
            departmentNames,
            subDepartmentNames,
            team.teamName || '',
            employeeCount || 0,
            team.status || ''
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Team_Data_${formattedDate}.${fileExtension}`);
};
