import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import iTechLogo from '../assets/img/iTech_Logo.png';

export const GenerateReportPDF = (headerColumns, columnsValue, reportName) => {
  const doc = new jsPDF();

  if (reportName == 'Asset ID Report') {
    // const reportName = "Asset By Category";
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add the table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [
        item.rowCount,
        item.assetID,
        item.assetName,
        item.assetBrand,
        item.assetPurchaseDate,
        item.assetPurchaseFrom,
        item.assetCost,
        item.assetStatus,
        item.assetAssignedTo
      ]),
      margin: { top: 40, left: 3, right: 3 },
      startY: 40,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Category Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetName, item.assetBrand, item.assetCategory, item.assetSubCategory, item.assetPurchaseDate, item.assetPurchaseFrom, item.assetCost, item.assetStatus]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Department Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetName, item.assetBrand, item.assetCategory, item.assetSubCategory, item.assetDepartment, item.assetPurchaseDate, item.assetPurchaseFrom, item.assetCost, item.assetStatus]),
      margin: { left: 3, right: 3 },
      startY: 30,
      // columnStyles: {
      //   0: { cellWidth: 15, halign: 'center' },
      //   1: { cellWidth: 20, halign: 'center' },
      //   2: { cellWidth: 20, halign: 'center' },
      //   3: { cellWidth: 20, halign: 'center' },
      //   4: { cellWidth: 20, halign: 'center' },
      //   5: { cellWidth: 20, halign: 'center' },
      //   6: { cellWidth: 20, halign: 'center' },
      //   7: { cellWidth: 20, halign: 'center' },
      //   8: { cellWidth: 20, halign: 'center' },
      //   9: { cellWidth: 20, halign: 'center' },
      //   10: { cellWidth: 20, halign: 'center' },
      // },
      // styles: {
      //     fontSize: 10,
      //     cellPadding: 5,
      //     overflow: 'linebreak'
      // }
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Unit/Branch Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetName, item.assetBrand, item.assetUnit, item.assetBranch, item.assetDepartment, item.assetPurchaseDate, item.assetPurchaseFrom, item.assetCost, item.assetStatus]),
      margin: { left: 3, right: 3 },
      startY: 30,
      // columnStyles: {
      //   0: { cellWidth: 15, halign: 'center' },
      //   1: { cellWidth: 20, halign: 'center' },
      //   2: { cellWidth: 20, halign: 'center' },
      //   3: { cellWidth: 20, halign: 'center' },
      //   4: { cellWidth: 20, halign: 'center' },
      //   5: { cellWidth: 20, halign: 'center' },
      //   6: { cellWidth: 20, halign: 'center' },
      //   7: { cellWidth: 20, halign: 'center' },
      //   8: { cellWidth: 20, halign: 'center' },
      //   9: { cellWidth: 20, halign: 'center' },
      //   10: { cellWidth: 20, halign: 'center' },
      // },
      // styles: {
      //     fontSize: 10,
      //     cellPadding: 5,
      //     overflow: 'linebreak'
      // }
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Linked Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetRelation, item.assetID, item.assetName, item.assetBrand, item.assetUnit, item.assetBranch, item.assetDepartment, item.assetPurchaseDate, item.assetPurchaseFrom, item.assetCost, item.assetStatus]),
      margin: { left: 3, right: 3 },
      startY: 30,
      // columnStyles: {
      //   0: { cellWidth: 15, halign: 'center' },
      //   1: { cellWidth: 20, halign: 'center' },
      //   2: { cellWidth: 20, halign: 'center' },
      //   3: { cellWidth: 20, halign: 'center' },
      //   4: { cellWidth: 20, halign: 'center' },
      //   5: { cellWidth: 20, halign: 'center' },
      //   6: { cellWidth: 20, halign: 'center' },
      //   7: { cellWidth: 20, halign: 'center' },
      //   8: { cellWidth: 20, halign: 'center' },
      //   9: { cellWidth: 20, halign: 'center' },
      //   10: { cellWidth: 20, halign: 'center' },
      // },
      // styles: {
      //     fontSize: 10,
      //     cellPadding: 5,
      //     overflow: 'linebreak'
      // }
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Status Report by Repaired') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.scheduledDate, item.cost, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
      // columnStyles: {
      //   0: { cellWidth: 15, halign: 'center' },
      //   1: { cellWidth: 20, halign: 'center' },
      //   2: { cellWidth: 20, halign: 'center' },
      //   3: { cellWidth: 20, halign: 'center' },
      //   4: { cellWidth: 20, halign: 'center' },
      //   5: { cellWidth: 20, halign: 'center' },
      //   6: { cellWidth: 20, halign: 'center' },
      //   7: { cellWidth: 20, halign: 'center' },
      //   8: { cellWidth: 20, halign: 'center' },
      //   9: { cellWidth: 20, halign: 'center' },
      //   10: { cellWidth: 20, halign: 'center' },
      // },
      // styles: {
      //     fontSize: 10,
      //     cellPadding: 5,
      //     overflow: 'linebreak'
      // }
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Status Report by Broken') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.assetcost, item.purchaseDate, item.brokenDate, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Status Report by Disposed') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.assetcost, item.purchaseDate, item.disposedDate, item.disposedTo, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Status Report by Sold') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.assetCost, item.purchaseDate, item.saleDate, item.saleTo, item.saleAmount, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Status Report by Lost/Missing') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.assetCost, item.purchaseDate, item.lostDate, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Asset Status Report by Donated') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.assetCost, item.purchaseDate, item.donateDate, item.donateTo, item.donateValue, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Check Out by Persons Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.checkoutDate, item.dueDate, item.returnDate, item.assetCost, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Check Out by Asset ID Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.checkoutDate, item.dueDate, item.returnDate, item.assignedTo, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Check Out by Due Date Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.checkoutDate, item.dueDate, item.assignedTo, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Check Out by Past Due Date Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.checkoutDate, item.dueDate, item.assignedTo, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Check Out by Site/Location Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.checkoutDate, item.dueDate, item.assignedTo, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Check Out by Time Frame Report') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.checkoutDate, item.dueDate,item.checkoutDate, item.assignedTo, item.notes]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Maintenance Schedule Report by Asset') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.scheduleDate, item.title,item.maintenanceBy, item.maintainStatus, item.complitionDate]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Maintenance Report by Asset') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.scheduleDate, item.title,item.maintenanceBy, item.maintainStatus, item.complitionDate]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Maintenance Complete Report by Asset') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.scheduleDate, item.title,item.maintenanceBy, item.maintainStatus, item.complitionDate,item.noOfDays]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Maintenance Over Due Report by Asset') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.maintainStatus, item.expires, item.assetID, item.assetDescription,item.title, item.maintenanceDetails]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Insurance Report by Insurance ID') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.purchaseDate, item.purchaseFrom,item.cost, item.insuranceCo,item.startDateInsurance,item.endDateInsurance]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }

  if (reportName == 'Insurance Report by Asset ID') {
    const companyName = "iTech Software Group";

    // Set up PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add the date and time in the top left corner
    // const dateTime = new Date().toLocaleString("en-GB", { hour12: false });
    const date = new Date().toLocaleDateString("en-GB");  // Format as "DD/MM/YYYY"
    doc.setFontSize(10);
    doc.text(date, 10, 10);

    // Center the report title
    doc.setFontSize(14);
    doc.text(reportName, 10, 20);


    // Add the company logo
    const logoImage = iTechLogo; // Replace with the path to your logo image
    doc.addImage(logoImage, "PNG", pageWidth - 40, 10, 40, 20); // Adjust logo position and size as needed

    // Add company name and unit under the logo
    // doc.setFontSize(10);
    // doc.text(companyName, pageWidth - 40, 25, { align: "center" });

    // Add a table
    doc.autoTable({
      head: [headerColumns],
      body: columnsValue.map(item => [item.rowCount, item.assetID, item.assetDescription, item.insuranceCo,item.startDateInsurance,item.endDateInsurance]),
      margin: { left: 3, right: 3 },
      startY: 30,
    });

    // Save the generated PDF
    doc.save(`${reportName}.pdf`);
  }
};
