import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './AssetSettingsCss.css';

import GetUserAccess from '../../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { getDepartment } from '../../../Services/DepartmentsAndTeamService';
import { getBUData, getBRData } from '../../../Services/BusinessEntityNewService';
import { getASTSettings, updateASTSettings } from '../../../Services/AssetSettingsService';

import heartIcon from '../../../assets/icon/heart.svg';
import judgeIcon from '../../../assets/icon/judge.svg';
import vectorIcon from '../../../assets/icon/vector.svg';
import dislikeIcon from '../../../assets/icon/dislike.svg';
import userTickIcon from '../../../assets/icon/user-tick.svg';

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#B0ACAC',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        marginLeft: '10px',
        width: '275px'
    }),
};

const customStylesNew = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#B0ACAC',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        marginLeft: '10px',
        width: '476px'
    }),
};

const AssetSettings = () => {
    const navigate = useNavigate();
    const [userScreens, setUserScreens] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [catValue, setCatValue] = useState('');
    const [prefix, setPrefix] = useState('');
    const [numberStartsFrom, setNumberStartsFrom] = useState('');
    const [padding, setPadding] = useState('');
    const [assetID, setAssetID] = useState('');
    const [businessUnitArray, setBusinessUnitArray] = useState([]);
    const [businessBranchArray, setBusinessBranchArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const defaultOptions = [
        { value: 'Auto Generate', label: 'Auto Generate' },
        { value: 'Company Preference', label: 'Company Preference' },
        { value: 'Category', label: 'Category' },
        { value: 'Sub Category', label: 'Sub Category' },
    ];
    const financialYearOptions = [
        { value: 'Beginning of Financial year', label: 'Beginning of Financial year' }
    ];
    const depreciationMethodOptions = [
        { value: 'Declining Balance', label: 'Declining Balance' },
        { value: 'Straight Line method', label: 'Straight Line method' }
    ];
    const calculationFrequencyOptions = [
        { value: 'quarterly', label: 'quarterly' },
        { value: 'half yearly', label: 'half yearly' },
        { value: 'annually', label: 'annually' }
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        control
    } = useForm({
        defaultValues: {
            defaultDrop: "",
            prefix: "",
            numberStartsFrom: "",
            padding: "",
            optionToReset: "",
            financialYear: "",
            assetDepreciation: "",
            depreciationMethod: "",
            calculationFrequency: "",
            centralizedInventory: "",
            unitId: null,
            branchId: null,
            departmentId: null,
            hierarchyLevels: "",
            contract: "",
            insurance: "",
            warranties: "",
            serviceRequest: "",
            audit: "",
            multiplePhotots: "",
            maximumNumberOfPhotos: "",
            docMultiplePhotots: "",
            docMaximumNumberOfPhotos: "",
            checkOutAssets: "",
            checkInAssets: "",
            lostOrFoundAssets: "",
            foundAssets: "",
            repairAssets: "",
            repairComplete: "",
            brokenAssets: "",
            disposeAssets: "",
            donateAssets: "",
            sellAssets: "",
            linkingOfAssets: ""
        }
    });

    useEffect(() => {
        fetchUserScreens();
        fetchAssetData();
        getBusinessUnit();
        getBusinessBranch();
        getDepartmentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const selectedCategories = ['category', 'subCategory', 'brand']
                .filter(key => value[key])
                .join(',');

            setCatValue(selectedCategories);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const fetchAssetData = async () => {
        try {
            const data = await getASTSettings();
            const filterData = data.find(e => e.id === 1)
            const { assetDepreciation, assetID, audit, branchId, brokenAssets, calculationFrequency, centralizedInventory, checkOutAssets, checkInAssets, contract, departmentId, depreciationMethod, docMaximumNumberOfPhotos, docMultiplePhotots, disposeAssets, donateAssets, financialYear, hierarchyLevels, insurance, linkingOfAssets, lostFoundAssets, foundAssets, maximumNumberOfPhotos, multiplePhotots, numberStartsFrom, optionToReset, padding, prefix, repairAssets, repairComplete, sellAssets, serviceRequest, unitId, warranties } = filterData;
            setAssetID(assetID);
            setPrefix(prefix);
            setPadding(padding);
            setNumberStartsFrom(numberStartsFrom);
            setValue('prefix', prefix);
            setValue('padding', padding);
            setValue('numberStartsFrom', numberStartsFrom);
            setCatValue(hierarchyLevels);
            setValue('category', hierarchyLevels.split(',').includes("category"));
            setValue('subCategory', hierarchyLevels.split(',').includes("subCategory"));
            setValue('brand', hierarchyLevels.split(',').includes("brand"));
            setValue('assetDepreciation', assetDepreciation);
            setValue('audit', audit);
            setValue('branchId', branchId);
            setValue('brokenAssets', brokenAssets);
            setValue('calculationFrequency', calculationFrequency);
            setValue('centralizedInventory', centralizedInventory);
            setValue('checkOutAssets', checkOutAssets);
            setValue('checkInAssets', checkInAssets);
            setValue('contract', contract);
            setValue('defaultDrop', filterData.default);
            setValue('departmentId', departmentId);
            setValue('depreciationMethod', depreciationMethod);
            setValue('docMaximumNumberOfPhotos', docMaximumNumberOfPhotos);
            setValue('docMultiplePhotots', docMultiplePhotots);
            setValue('disposeAssets', disposeAssets);
            setValue('donateAssets', donateAssets);
            setValue('financialYear', financialYear);
            setValue('insurance', insurance);
            setValue('linkingOfAssets', linkingOfAssets);
            setValue('lostOrFoundAssets', lostFoundAssets);
            setValue('foundAssets', foundAssets);
            setValue('maximumNumberOfPhotos', maximumNumberOfPhotos);
            setValue('multiplePhotots', multiplePhotots);
            setValue('optionToReset', optionToReset);
            setValue('repairAssets', repairAssets);
            setValue('repairComplete', repairComplete);
            setValue('sellAssets', sellAssets);
            setValue('serviceRequest', serviceRequest);
            setValue('unitId', unitId);
            setValue('warranties', warranties);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching Asset Setting data:', error);
            setIsLoading(false);
        }
    };

    const getBusinessUnit = async () => {
        const data = await getBUData();
        const finalData = data?.map((unit, index) => {
            return {
                ...unit,
                Rowid: index + 1,
            };
        });
        setBusinessUnitArray(finalData);
    }

    const getBusinessBranch = async () => {
        const data = await getBRData();
        const finalData = data?.map((branch, index) => {
            return {
                ...branch,
                Rowid: index + 1,
            };
        });
        setBusinessBranchArray(finalData);
    }

    const getDepartmentData = async () => {
        const data = await getDepartment();
        const finalData = data?.map((dept, index) => {
            return {
                ...dept,
                Rowid: index + 1,
            };
        });
        setDepartmentArray(finalData);
    }

    const handleCloseAssetSettingsModal = () => {
        navigate("/Settings");
        setValue('', null);
    };

    const onSubmit = async (data) => {
        if (!userScreens["Asset Configuration"]?.Add || !userScreens["Asset Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const { assetDepreciation, audit, branchId, brokenAssets, calculationFrequency, centralizedInventory, checkOutAssets, checkInAssets, contract, defaultDrop, departmentId, depreciationMethod, docMaximumNumberOfPhotos, docMultiplePhotots, disposeAssets, donateAssets, financialYear, insurance, linkingOfAssets, lostOrFoundAssets, foundAssets, maximumNumberOfPhotos, multiplePhotots, numberStartsFrom, optionToReset, padding, prefix, repairAssets, repairComplete, sellAssets, serviceRequest, unitId, warranties } = data;
        try {
            setIsLoading(true);
            const payload = {
                id: 1,
                assetDepreciation: assetDepreciation,
                assetID: assetID,
                audit: audit,
                branchId: branchId,
                brand: true,
                brokenAssets: brokenAssets,
                calculationFrequency: calculationFrequency,
                centralizedInventory: centralizedInventory,
                checkOutAssets: checkOutAssets,
                checkInAssets: checkInAssets,
                contract: contract,
                default: defaultDrop,
                departmentId: departmentId,
                depreciationMethod: depreciationMethod,
                docMaximumNumberOfPhotos: docMaximumNumberOfPhotos,
                docMultiplePhotots: docMultiplePhotots,
                disposeAssets: disposeAssets,
                donateAssets: donateAssets,
                financialYear: financialYear,
                hierarchyLevels: catValue,
                insurance: insurance,
                linkingOfAssets: linkingOfAssets,
                lostFoundAssets: lostOrFoundAssets,
                foundAssets: foundAssets,
                maximumNumberOfPhotos: maximumNumberOfPhotos,
                multiplePhotots: multiplePhotots,
                numberStartsFrom: numberStartsFrom,
                optionToReset: optionToReset,
                repairAssets: repairAssets,
                repairComplete: repairComplete,
                sellAssets: sellAssets,
                padding: padding,
                prefix: prefix,
                serviceRequest: serviceRequest,
                unitId: unitId,
                warranties: warranties
            };
            await updateASTSettings(payload);
            setIsLoading(false);
            toast.success('Settings Updated successfully');
        } catch (error) {
            setIsLoading(false);
            toast.error('Failed to save Settings');
        }
    };

    useEffect(() => {
        const generatedID = generateAssetID(prefix, numberStartsFrom, padding);
        setAssetID(generatedID);
    }, [prefix, numberStartsFrom, padding]);


    const generateAssetID = (prefix, numberStartsFrom, padding) => {
        const zeros = '0'.repeat(parseInt(padding, 10));
        return `${prefix.toUpperCase()}${zeros}${numberStartsFrom}`;
    };


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleAsset">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Asset Settings<div><InfoOutlinedIcon /></div></div>
                    </div>

                    {userScreens["Asset Configuration"]?.View && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="card-body-AssetSettings">
                                    <div className="CustomBoxShadow">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Asset ID Settings</div>
                                        <div className="parentDiv">
                                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginRight: '30px' }}>
                                                    Default
                                                </div>
                                                <Controller
                                                    name="defaultDrop"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={defaultOptions}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStyles}
                                                            placeholder="Select an option"
                                                            isSearchable
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                                            onBlur={field.onBlur}
                                                            value={defaultOptions.find(option => option.value === field.value)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ paddingTop: '16px' }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                    Prefix
                                                </div>
                                                <input
                                                    className="textinputCustomAssetSettings upperCase"
                                                    placeholder=""
                                                    {...register('prefix', { required: 'Please Enter Prefix' })}
                                                    onChange={(e) => setPrefix(e.target.value)}
                                                />
                                                {errors.prefix && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.prefix.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                    Number Starts From
                                                </div>
                                                <input
                                                    className="textinputCustomAssetSettings"
                                                    placeholder=""
                                                    onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                                    {...register('numberStartsFrom', { required: 'Please Enter Number starts from' })}
                                                    onChange={(e) => setNumberStartsFrom(e.target.value)}
                                                />
                                                {errors.numberStartsFrom && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.numberStartsFrom.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="" style={{ paddingTop: '16px' }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12" style={{ width: '625px' }}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                    Padding
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className="textinputCustomAssetSettings"
                                                        placeholder=""
                                                        maxLength="2"
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 2);
                                                            setPadding(e.target.value);
                                                        }}
                                                        {...register('padding', { required: 'Please Enter Padding' })}
                                                    />
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingLeft: '10px', whiteSpace: 'nowrap' }}>
                                                        Zero number of Digits
                                                    </div>
                                                </div>
                                                {errors.padding && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.padding.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '10px', whiteSpace: 'nowrap' }}>
                                                Your Asset ID looks like : <span className='blackTextColor fontFamily fontsize14 fontweightMedium'>{assetID}</span>
                                            </div>
                                        </div>
                                        <div className='d-flex mTop20'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="optionToReset"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Option to reset the code
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="optionToReset"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <Controller
                                                name="financialYear"
                                                control={control}
                                                rules={{ required: 'Please select a financial year' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={financialYearOptions}
                                                        className="fontFamily fontweightRegular fontsize16"
                                                        styles={customStyles}
                                                        placeholder="Select an option"
                                                        isSearchable
                                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                                        onBlur={field.onBlur}
                                                        value={financialYearOptions.find(option => option.value === field.value)}
                                                    />
                                                )}
                                            />
                                        </div>

                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Asset Depreciation</div>
                                        <div className="parentDiv">
                                            <div className="mt-3">
                                                <div className="fontFamily fontweightRegular fontsize18 blackTextColor pLeft12 mBottom20" style={{ marginRight: '30px' }}>
                                                    Want to track how the value of your assets change over time? Enable depreciation tracking by selecting “Yes” and choosing your preferred method and frequency.
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex mBottom20'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="assetDepreciation"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Asset Depreciation
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="assetDepreciation"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2 mb-1 pLeft12">
                                                    Depreciation Method
                                                </div>
                                                <Controller
                                                    name="depreciationMethod"
                                                    control={control}
                                                    rules={{ required: 'Please select a Depreciation Method' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={depreciationMethodOptions.find(option => option.value === field.value)}
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                                            options={depreciationMethodOptions}
                                                            placeholder="Select an option"
                                                            isSearchable
                                                            onBlur={field.onBlur}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStylesNew}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2 mb-1 pLeft12">
                                                    Calculation Frequency
                                                </div>
                                                <Controller
                                                    name="calculationFrequency"
                                                    control={control}
                                                    rules={{ required: 'Please select a Calculation Frequency' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={calculationFrequencyOptions.find(option => option.value === field.value)}
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                                            options={calculationFrequencyOptions}
                                                            placeholder="Select an option"
                                                            isSearchable
                                                            onBlur={field.onBlur}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStylesNew}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Asset Inventory</div>
                                        <div className='d-flex mTop20'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="centralizedInventory"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable Centralized Inventory
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="centralizedInventory"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="parentDiv">
                                            <div className="mt-3">
                                                <div className="fontFamily fontweightRegular fontsize18 blackTextColor pLeft12 mBottom20" style={{ marginRight: '30px' }}>
                                                    When new asset is created, by default map the asset to the following department.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2 mb-1 pLeft12">
                                                    Unit<span className="mandatoryCss"> *</span></div>
                                                <Controller
                                                    name="unitId"
                                                    control={control}
                                                    rules={{ required: 'Please select a Unit' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={businessUnitArray.find(u => u.bU_ID === field.value) || null}
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.bU_ID)}
                                                            options={businessUnitArray}
                                                            getOptionLabel={(option) => option.bU_NAME}
                                                            getOptionValue={(option) => option.bU_ID}
                                                            placeholder="Select Unit"
                                                            isSearchable
                                                            onBlur={field.onBlur}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStylesNew}
                                                        />
                                                    )}
                                                />
                                                {errors.unitId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.unitId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2 mb-1 pLeft12">
                                                    Branch<span className="mandatoryCss"> *</span></div>
                                                <Controller
                                                    name="branchId"
                                                    control={control}
                                                    rules={{ required: 'Please select a Branch' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={businessBranchArray.find(b => b.bR_ID === field.value) || null}
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.bR_ID)}
                                                            options={businessBranchArray}
                                                            getOptionLabel={(option) => option.bR_NAME}
                                                            getOptionValue={(option) => option.bR_ID}
                                                            placeholder="Select Branch"
                                                            isSearchable
                                                            onBlur={field.onBlur}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStylesNew}
                                                        />
                                                    )}
                                                />
                                                {errors.branchId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.branchId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2 mb-1 pLeft12">
                                                    Department<span className="mandatoryCss"> *</span></div>
                                                <Controller
                                                    name="departmentId"
                                                    control={control}
                                                    rules={{ required: 'Please select a Department' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={departmentArray.find(dept => dept.id === field.value) || null}
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.id)}
                                                            options={departmentArray}
                                                            getOptionLabel={(option) => option.departmentName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder="Select Department"
                                                            isSearchable
                                                            onBlur={field.onBlur}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStylesNew}
                                                        />
                                                    )}
                                                />
                                                {errors.departmentId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.departmentId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Asset Hierarchy Settings
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Define the hierarchy levels for asset management.
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="category"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Category
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="subCategory"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Sub Category
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="brand"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Brand
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Contract
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            If you have a contract or agreement for an asset, consider using this option to manage it. This also applies to software licenses.
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="contract"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="contract"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Insurance
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Simplify insurance management for your assets. Enable this option to link insurance information to each asset
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="insurance"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="insurance"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Warranties
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Track warranties & get alerts: Add warranty information for your assets and receive reminders before they expire.
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="warranties"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="warranties"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Service Request
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Link service request to your asset. This lets you easily report issue and track their status.
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="serviceRequest"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="serviceRequest"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Audit
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Check your assets: Run audits to see if your assets are still in the right places. You can then take action if any assets are missing
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="audit"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="audit"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Photos <span className='grayTextColor fontFamily fontsize14 fontweightRegular'>(Maximum document size is 5 MB)</span>
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Allow to add multiple photots
                                        </div>
                                        <div className='d-flex mBottom20'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="multiplePhotots"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="multiplePhotots"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight20'>
                                                    Maximum number of Photos
                                                </div>
                                                <input
                                                    className='textBoxPhotos no-spinner'
                                                    {...register('maximumNumberOfPhotos')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Documents <span className='grayTextColor fontFamily fontsize14 fontweightRegular'>(Maximum document size is 5 MB)</span>
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Allow to add multiple documents
                                        </div>
                                        <div className='d-flex mBottom20'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="docMultiplePhotots"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="docMultiplePhotots"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight20'>
                                                    Maximum number of documents
                                                </div>
                                                <input
                                                    className='textBoxPhotos no-spinner'
                                                    {...register('docMaximumNumberOfPhotos')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Asset Actions Configuration
                                        </div>

                                        <div>
                                            <div className='row'>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={userTickIcon} alt="userTickIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Check-out assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="checkOutAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="checkOutAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={userTickIcon} alt="userTickIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Check-in assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="checkInAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="checkInAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12 mTop32'>

                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={dislikeIcon} alt="dislikeIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Lost assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="lostOrFoundAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="lostOrFoundAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12 mTop32'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={dislikeIcon} alt="dislikeIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Found assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="foundAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="foundAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12 mTop32'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={userTickIcon} alt="userTickIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Repair Assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="repairAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="repairAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12 mTop32'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={userTickIcon} alt="userTickIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Repair Complete
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="repairComplete"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="repairComplete"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12 mTop32'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={userTickIcon} alt="userTickIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Broken Assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="brokenAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="brokenAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12 mTop32'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={vectorIcon} alt="vectorIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Dispose Assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="disposeAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="disposeAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12 mTop32'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={heartIcon} alt="heartIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Donate Assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="donateAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="donateAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-6 col-md-6 col-lg-6 col-sm-12  mTop32'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex' style={{ width: '50%' }}>
                                                            <div>
                                                                <img src={judgeIcon} alt="judgeIcon" style={{ height: '25px', width: "25px", margin: "0px 10px" }} />
                                                            </div>
                                                            <div className='grayTextColor fontFamily fontsize20 fontweightRegular pRight45'>
                                                                Sell Assets
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="sellAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="Yes"
                                                                                    {...field}
                                                                                    checked={field.value === 'Yes'}
                                                                                    onChange={() => field.onChange('Yes')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    Yes
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className='d-flex pLeft12 align-items-center'>
                                                                    <Controller
                                                                        name="sellAssets"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="No"
                                                                                    {...field}
                                                                                    checked={field.value === 'No'}
                                                                                    onChange={() => field.onChange('No')}
                                                                                />
                                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                    No
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Linking of Assets
                                        </div>
                                        <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 mBottom20">
                                            Group your assets: Keep track of related assets by linking them together. Choose a main asset (parent) and link others to it (children). They stay connected and can be.
                                        </div>
                                        <div className='d-flex'>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="linkingOfAssets"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                Enable
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                {...field}
                                                                checked={field.value === 'Yes'}
                                                                onChange={() => field.onChange('Yes')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='d-flex pLeft12 align-items-center'>
                                                <Controller
                                                    name="linkingOfAssets"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                {...field}
                                                                checked={field.value === 'No'}
                                                                onChange={() => field.onChange('No')}
                                                            />
                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: "8%", display: 'flex', justifyContent: 'center', paddingTop: 15, paddingBottom: 10 }}>
                                        <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonSave ButtonSpace" onClick={handleSubmit(onSubmit)}>
                                            Save
                                        </button>
                                        <button type="button" id="btncloseModal" onClick={handleCloseAssetSettingsModal} className="fontFamily fontweightRegular fontsize16 outlineButton" style={{ marginLeft: '5px' }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

}

export default AssetSettings;
