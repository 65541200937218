import axios from "axios";
import api from "../webconfig";

const API_BASE_URL = api;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

export async function getLoginData() {
  try {
    const response = await axios.get(API_BASE_URL + "Login/GetLogin", {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching LoginData:", error);
    throw error;
  }
}

export async function loginUser(userName, password) {
  try {
    const response = await axios.post(
      API_BASE_URL +
        "Login/login/email/" +
        userName +
        "/password/" +
        password +
        ""
    );
    if(response.data.response == "Login Successfully"){
      localStorage.setItem("token", response.data.token);
    
      const getLoginData1 = await getLoginData();
      const loginUserName = getLoginData1.find((e) => e.email === userName);
      if (loginUserName) {
        localStorage.setItem("UserName", loginUserName?.name);
        localStorage.setItem("UserID", loginUserName?.id);
        localStorage.setItem("UserEmpID", loginUserName?.empId);
      }
    }
    
    return response.data.response;
  } catch (error) {
    console.error("Error while loginUser:", error);
    throw error;
  }
}

export async function getCountries() {
  try {
    const response = await axios.get(
      API_BASE_URL + "Configuration/getCountry",
      { headers: getAuthHeader() }
    );
    localStorage.setItem("Countries", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error("Error fetching Countries:", error);
    throw error;
  }
}

export async function getStates() {
  try {
    const response = await axios.get(API_BASE_URL + "Configuration/getState", {
      headers: getAuthHeader(),
    });
    localStorage.setItem("States", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error("Error fetching States:", error);
    throw error;
  }
}

export async function getCities() {
  try {
    const response = await axios.get(API_BASE_URL + "Configuration/getCity", {
      headers: getAuthHeader(),
    });
    localStorage.setItem("Cities", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error("Error fetching Cities:", error);
    throw error;
  }
}

export async function ForgetPasswordSendMail(userName) {
  try {
    const getLoginData1 = await getLoginData();
    const LoginData1 = getLoginData1.find((e) => e.email === userName);
    if (LoginData1 !== "" && LoginData1 != null && LoginData1 !== undefined) {
      const response = await axios.post(
        API_BASE_URL + "Login/SendForgetPasswordLink/email/" + userName + ""
      );
      return response.data;
    } else {
      return "Invalid User";
    }
  } catch (error) {
    console.error("Error while loginUser:", error);
    throw error;
  }
}

export async function passwordReset(forgetPasswordData) {
  try {
    const response = await axios.post(
      API_BASE_URL + "Login/ForgetPassword",
      forgetPasswordData
    );
    return response.data;
  } catch (error) {
    console.error("Error while loginUser:", error);
    throw error;
  }
}

export async function getUserRoleSideMenu(BE, BU, RoleID) {
  try {
    const response = await axios.post(
      API_BASE_URL +
        "Login/GetSideMenuByRoleID?BE=" +
        BE +
        "&BU=" +
        BU +
        "&RoleID=" +
        RoleID +
        ""
    );
    return response.data;
  } catch (error) {
    console.error("Error while getting UserRoleSideMenu:", error);
    throw error;
  }
}
