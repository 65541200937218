import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer } from 'react-toastify';
import { format, set } from 'date-fns';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { useForm, Controller } from "react-hook-form";
import { getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";
import { getAsset, getAssetStatus, getAssetLink } from "../../../Services/AssetSettingsService";
import { getSupplier } from "../../../Services/SupplierMasterService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";
import { GenerateReportPDF } from "../../../CommomPages/ReportPdf";
import CalendarWithBG from "../../../assets/icon/CalendarWithBG.svg";
import nodataFound from "../../../assets/img/nodatafound.png";
import { getCheckOut } from '../../../Services/ActionFormConfigurationService'

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '275px'
    // }),
};

const CheckOutSiteAndLocationReport = () => {
    const [isLoading, setIsLoading] = useState(false);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");
    const [dateFormat, setDateFormat] = useState("");

    const [AssetReportArray, setAssetReportArray] = useState([]);
    const [FilterAssetReportArray, setFilterAssetReportArray] = useState([]);
    const [assetArray, setAssetArray] = useState([]);
    const [assetStatusArray, setAssetStatusArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [supplierArray, setSupplierArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);
    const [CheckoutAssetArray, setCheckoutAssetArray] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            fromDate: null,
            toDate: null,
            purchaseDate: null,
            unitId: 0,
            branchId: [],
            categoryId: [],
            subCategoryId: [],
            departmentId: [],
            empId: 0,
            status: 0,
            date: null
        },
    });

    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                fetchAssetData()
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dataInitialization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchAssetData = async () => {
        try {
            const dateCurrentFormat = await getDateFormat();
            setDateFormat(dateCurrentFormat);

            const assetData = await getAsset();

            const unitData = await getBUData();
            const _unitArray = unitData.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
            setUnitArray(_unitArray);

            const branchData = await getBRData();
            const _branchArray = branchData.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
            setBranchArray(_branchArray);

            const assetStatus = await getAssetStatus();
            setAssetStatusArray(assetStatus);

            const _brandArray = await getBrand();
            setBrandArray(_brandArray);

            const _supplierArray = await getSupplier();
            setSupplierArray(_supplierArray);

            const _categoryArray = await getCategory();
            setCategoryArray(_categoryArray);

            const _subCategoryArray = await getSubCategory();
            setSubCategoryArray(_subCategoryArray);

            const _departmentArray = await getDepartment();
            setDepartmentArray(_departmentArray);

            const empData = await getEmployee();
            const _employeeArray = empData.map((Emp, index) => {
                return {
                    ...Emp,
                    EmployeeName: Emp?.firstName + " " + Emp?.lastName,
                };
            });
            const sortedEmployeeData = _employeeArray.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
            setEmployeeArray(sortedEmployeeData);

            const _checkOutAsset = await getCheckOut();
            setCheckoutAssetArray(_checkOutAsset);

            const finalData = assetData?.length > 0 ? assetData.map((asset, index) => {
                const brandAsset = _brandArray?.find(x => x.id === asset?.brandId);
                const formattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const formattedDateAcquired = asset.dateAcquired ? format(new Date(asset.dateAcquired), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);
                const supplierAsset = _supplierArray?.find(e => e.id == asset?.supplierId);
                const employeeAsset = _employeeArray?.find(e => e.id == asset?.empId);
                const categoryAsset = _categoryArray?.find(e => e.id == asset?.categoryId);
                const subcategoryAsset = _subCategoryArray?.find(e => e.id == asset?.subCategoryId);
                const departmentAsset = _departmentArray?.find(e => e.id == asset?.departmentId);
                const unitAsset = _unitArray?.find(e => e.bU_ID == asset?.unitId);
                const branchAsset = _branchArray?.find(e => e.bR_ID == asset?.branchId);

                let purchaseDate = null;
                let filterPurchaseDateFormat = null;
                if (asset.purchaseDate != null) {
                    purchaseDate = new Date(asset.purchaseDate)
                    let date = purchaseDate.getDate();
                    let fullDate = date < 10 ? '0' + date : date;
                    let month = purchaseDate.getMonth() + 1;
                    let fullMonth = month < 10 ? '0' + month : month
                    let year = purchaseDate.getFullYear();
                    filterPurchaseDateFormat = "" + year + "-" + fullMonth + "-" + fullDate + ""
                }
                return {
                    ...asset,
                    Rowid: index + 1,
                    brandName: brandAsset ? brandAsset?.brandName : '',
                    unitName: unitAsset ? unitAsset?.bU_NAME : '',
                    branchName: branchAsset ? branchAsset?.bR_NAME : '',
                    categoryName: categoryAsset ? categoryAsset?.categoryCode : '',
                    subcategoryName: subcategoryAsset ? subcategoryAsset?.subCategoryName : '',
                    departmentName: departmentAsset ? departmentAsset?.departmentName : '',
                    formatedPurchaseDate: formattedDate,
                    formattedDateAcquired: formattedDateAcquired,
                    purchaseFrom: supplierAsset ? supplierAsset.supplierName : '',
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue,
                    assignedTo: employeeAsset ? employeeAsset.EmployeeName : '',
                    filterPurchaseDateFormat: filterPurchaseDateFormat
                };
            }) : [];
            setAssetArray(finalData);

            setAssetReportArray(finalData);
            // setFilterAssetReportArray(finalData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        }
    };

    const handleSearchChange = (inputFieldsValue) => {

        let inputDate = null;
        let formattedInputDate = null;
        if (inputFieldsValue.date != null) {
            inputDate = new Date(inputFieldsValue?.date)
            let date = inputDate.getDate();
            let fullDate = date < 10 ? '0' + date : date;
            let month = inputDate.getMonth() + 1;
            let fullMonth = month < 10 ? '0' + month : month
            let year = inputDate.getFullYear();
            formattedInputDate = "" + year + "-" + fullMonth + "-" + fullDate + ""
        }
        let FrominputDate = null;
        let formattedFromInputDate = null;
        if (inputFieldsValue.fromDate != null) {
            FrominputDate = new Date(inputFieldsValue?.fromDate)
            let date = FrominputDate.getDate();
            let fullDate = date < 10 ? '0' + date : date;
            let month = FrominputDate.getMonth() + 1;
            let fullMonth = month < 10 ? '0' + month : month
            let year = FrominputDate.getFullYear();
            formattedFromInputDate = "" + year + "-" + fullMonth + "-" + fullDate + ""
        }
        let ToinputDate = null;
        let formattedToInputDate = null;
        if (inputFieldsValue.toDate != null) {
            ToinputDate = new Date(inputFieldsValue?.toDate)
            let date = ToinputDate.getDate();
            let fullDate = date < 10 ? '0' + date : date;
            let month = ToinputDate.getMonth() + 1;
            let fullMonth = month < 10 ? '0' + month : month
            let year = ToinputDate.getFullYear();
            formattedToInputDate = "" + year + "-" + fullMonth + "-" + fullDate + ""
        }
        let filterData = AssetReportArray.filter(record => {
            return (
                (formattedFromInputDate === null || record.filterPurchaseDateFormat >= formattedFromInputDate) &&
                (formattedToInputDate === null || record.filterPurchaseDateFormat <= formattedToInputDate) &&
                (formattedInputDate === null || record.filterPurchaseDateFormat === formattedInputDate) &&
                (inputFieldsValue.unitId === 0 || inputFieldsValue.unitId === null || record.unitId === inputFieldsValue.unitId) &&
                (inputFieldsValue.branchId.length === 0 || inputFieldsValue.branchId.includes(record.branchId)) &&
                (inputFieldsValue.categoryId.length === 0 || inputFieldsValue.categoryId.includes(record.categoryId)) &&
                (inputFieldsValue.subCategoryId.length === 0 || inputFieldsValue.subCategoryId.includes(record.subCategoryId)) &&
                (inputFieldsValue.departmentId.length === 0 || inputFieldsValue.departmentId.includes(record.departmentId)) &&
                (inputFieldsValue.empId === 0 || inputFieldsValue.empId === null || record.empId === inputFieldsValue.empId) &&
                (inputFieldsValue.status === 0 || inputFieldsValue.status === null || record.statusValue === inputFieldsValue.status)

            );
        });
        let finalFilterData = [];
        const CheckoutAssetarrayList = filterData.map((asset, index) => {
            const checkoutAsset = CheckoutAssetArray.filter((x) => x.assetID == asset.id);
            if (checkoutAsset != null && checkoutAsset != undefined && checkoutAsset != '' && checkoutAsset.length > 0) {

                for (let i = 0; i < checkoutAsset.length; i++) {

                    const Checkoutdate = new Date(checkoutAsset[i]?.checkOutDate);
                    const checkOutformattedDate = `${Checkoutdate.getMonth() <= 8 ? '0' + (Checkoutdate.getMonth() + 1) : Checkoutdate.getMonth() + 1}/${Checkoutdate.getDate() <= 9 ? '0' + Checkoutdate.getDate() : Checkoutdate.getDate()}/${Checkoutdate.getFullYear()}`;

                    const Duedate = new Date(checkoutAsset[i]?.dueDate);
                    const DueformattedDate = `${Duedate.getMonth() <= 8 ? '0' + (Duedate.getMonth() + 1) : Duedate.getMonth() + 1}/${Duedate.getDate() <= 9 ? '0' + Duedate.getDate() : Duedate.getDate()}/${Duedate.getFullYear()}`;

                    let lostArrayObj = {
                        assetID: asset?.assetID,
                        description: asset?.assetName,
                        checkoutDate: checkoutAsset[i]?.checkOutDate == null ? '' : checkOutformattedDate,
                        dueDate: checkoutAsset[i]?.dueDate == null ? '' : DueformattedDate,
                        assignedTo: checkoutAsset[i]?.isEmployee ? employeeArray.find((x) => x.id == checkoutAsset[i].assignTo).EmployeeName : '',
                        notes: checkoutAsset[i]?.checkOutNotes
                    }
                    finalFilterData.push(lostArrayObj)
                }
                // return SoldarrayList
            }
        }).filter(item => item !== undefined);
        setFilterAssetReportArray(finalFilterData);
    };

    const handleSearchClear = () => {
        reset();
        setFilterAssetReportArray([]);
    };

    const exportAsset = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== "") {
            handleExportAsset(selectedOption);
            event.target.value = "";
        }
    };

    const handleExportAsset = (format) => {
        const headerColumns = ['SI.No', 'Asset ID', 'Description', 'Check-out Date', 'Due Date', 'Return Date', 'Notes'];
        const columnsValue = FilterAssetReportArray.map((assetReport, index) => {
            return {
                rowCount: index + 1,
                assetID: assetReport.assetID,
                assetDescription: assetReport.description,
                checkoutDate: assetReport.checkoutDate,
                dueDate: assetReport.dueDate,
                assignedTo: assetReport.assignedTo,
                notes: assetReport.notes,
            }
        })

        GenerateReportPDF(headerColumns, columnsValue, 'Check Out by Site/Location Report');
    };

    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        setPageAsset(0);
        setSearchQueryAsset(event.target.value);
    };

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(assetArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = FilterAssetReportArray?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Asset Check-out Site/Location Report<div><InfoOutlinedIcon /></div></div>
                    </div>
                    <div className="card mt-4 customScrollbar">
                        <div className="card-body-category">
                            <div className="pLeft30 pRight20">
                                {/* <div className="blackTextColor fontFamily fontsize16 fontweightRegular mTop20 mBottom20">
                                    Search Fields
                                </div>
                                <input
                                    type="text"
                                    placeholder="Asset ID, Brand, Description, Model, Purchased from etc..."
                                    value={searchQueryAsset}
                                    onChange={handleSearchChangeAsset}
                                    className="searchTextBoxCustom"
                                /> */}

                                <div className="row" style={{ paddingTop: "24px" }}>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            From Date
                                        </div>
                                        <div className="d-flex">
                                            <Controller
                                                name="fromDate"
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        validDate: (value) => {
                                                            // If the value is empty, it's valid because the field is optional
                                                            if (!value) {
                                                                return true;
                                                            }

                                                            // Check if the value is a valid date object
                                                            const dateValue = new Date(value);
                                                            if (isNaN(dateValue.getTime())) {
                                                                return "Invalid date value";
                                                            }

                                                            // Format the date and validate it
                                                            const formattedDate = format(dateValue, dateFormat);
                                                            const [day, month, year] = formattedDate.split('/');
                                                            const selectedDate = validateDate(dateFormat, day, month, year);

                                                            // Get today's date for comparison
                                                            const today = new Date();
                                                            today.setHours(0, 0, 0, 0);

                                                            // Check if selected date is in the future
                                                            if (selectedDate > today) {
                                                                return "Date cannot be in the future";
                                                            }

                                                            // Validate date format using regex
                                                            const regex = getRegexForDateFormat(dateFormat);
                                                            if (!regex.test(formattedDate)) {
                                                                return "Invalid date format (" + dateFormat + ")";
                                                            }
                                                            return true;
                                                        },
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => { field.onChange(date); }}
                                                        dateFormat={dateFormat}
                                                        className="customDatePicker date-picker-input"
                                                        ref={(input) => field.ref(input?.inputElement)}
                                                    />
                                                )}
                                            />
                                            <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            To Date
                                        </div>
                                        <div className="d-flex">
                                            <Controller
                                                name="toDate"
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        validDate: (value) => {
                                                            // If the value is empty, it's valid because the field is optional
                                                            if (!value) {
                                                                return true;
                                                            }

                                                            // Check if the value is a valid date object
                                                            const dateValue = new Date(value);
                                                            if (isNaN(dateValue.getTime())) {
                                                                return "Invalid date value";
                                                            }

                                                            // Format the date and validate it
                                                            const formattedDate = format(dateValue, dateFormat);
                                                            const [day, month, year] = formattedDate.split('/');
                                                            const selectedDate = validateDate(dateFormat, day, month, year);

                                                            // Get today's date for comparison
                                                            const today = new Date();
                                                            today.setHours(0, 0, 0, 0);

                                                            // Check if selected date is in the future
                                                            if (selectedDate > today) {
                                                                return "Date cannot be in the future";
                                                            }

                                                            // Validate date format using regex
                                                            const regex = getRegexForDateFormat(dateFormat);
                                                            if (!regex.test(formattedDate)) {
                                                                return "Invalid date format (" + dateFormat + ")";
                                                            }
                                                            return true;
                                                        },
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => { field.onChange(date); }}
                                                        dateFormat={dateFormat}
                                                        className="customDatePicker date-picker-input"
                                                        ref={(input) => field.ref(input?.inputElement)}
                                                    />
                                                )}
                                            />
                                            <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Unit
                                        </div>
                                        <Controller
                                            name="unitId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={unitArray.find((c) => c.bU_ID === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                    }}
                                                    options={unitArray}
                                                    getOptionLabel={(option) => option.bU_NAME}
                                                    getOptionValue={(option) => option.bU_NAME}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Branch
                                        </div>
                                        <Controller
                                            name="branchId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={branchArray.filter((c) => field.value.includes(c.bR_ID)) || []}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map((option) => option.bR_ID) : []);
                                                    }}
                                                    options={branchArray}
                                                    getOptionLabel={(option) => option.bR_NAME}
                                                    getOptionValue={(option) => option.bR_ID}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    // closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Department
                                        </div>
                                        <Controller
                                            name="departmentId"
                                            control={control}
                                            render={({ field }) => (

                                                <Select
                                                    {...field}
                                                    value={departmentArray.filter((c) => field.value.includes(c.id)) || []}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map((option) => option.id) : []);
                                                    }}
                                                    options={departmentArray}
                                                    getOptionLabel={(option) => option.departmentName}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    // closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Employee
                                        </div>
                                        <Controller
                                            name="empId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={employeeArray.find(emp => emp.id === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                    }}
                                                    options={employeeArray}
                                                    getOptionLabel={(option) => option.EmployeeName}
                                                    getOptionValue={(option) => option.EmployeeName}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.empId && (
                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                {errors.empId.message}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row" style={{ paddingTop: "24px" }}>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Category
                                        </div>
                                        <Controller
                                            name="categoryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={categoryArray.filter((c) => field.value.includes(c.id)) || []}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map((option) => option.id) : []);
                                                    }}
                                                    options={categoryArray}
                                                    getOptionLabel={(option) => option.categoryName}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    // closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Sub Category
                                        </div>
                                        <Controller
                                            name="subCategoryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={subCategoryArray.filter((c) => field.value.includes(c.id)) || []}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map((option) => option.id) : []);
                                                    }}
                                                    options={subCategoryArray}
                                                    getOptionLabel={(option) => option.subCategoryName}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    // closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            Status
                                        </div>
                                        <Controller
                                            name="status"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={assetStatusArray.find(status => status.id === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                    }}
                                                    options={assetStatusArray}
                                                    getOptionLabel={(option) => option.labelAssignedValue}
                                                    getOptionValue={(option) => option.labelAssignedValue}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.status && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.status.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12">
                                        <div className="d-flex align-items-center mTop35">
                                            <button
                                                className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                style={{ marginLeft: "20px" }}
                                                onClick={handleSubmit(handleSearchChange)}
                                            >
                                                Search
                                            </button>
                                            <span>
                                                <button
                                                    className="fontFamily fontweightRegular fontsize16 importBtn"
                                                    onClick={handleSearchClear}
                                                >
                                                    Clear
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-4" style={{ paddingBottom: "8px" }}>
                                    <div className="custom-container">
                                        <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                            <span className="show">Show</span>
                                            <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                {paginateArray.map((n) => (
                                                    <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                        {n}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                Entries
                                            </span>
                                        </span>
                                    </div>

                                    <div className="addbuttondiv">
                                        <select
                                            className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn"
                                            name="language"
                                            id="language"
                                            defaultValue=""
                                            onChange={exportAsset}
                                        >
                                            <option value="" hidden={true}>
                                                Export
                                            </option>
                                            {/* <option value="csv">CSV</option>
                                            <option value="excel">Excel</option> */}
                                            <option value="pdf">Pdf</option>
                                        </select>
                                    </div>
                                </div>

                            </div>


                            <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                <div className="tableScroll" style={{ height: '300px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                    <table className="w-100 mt-0">
                                        <thead>
                                            <tr className="text-left">

                                                <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "13%", textAlign: 'center' }}>Asset ID</th>
                                                <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "12%", textAlign: 'center' }}>Check-out Date</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "12%", textAlign: 'center' }}>Due Date</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "12%", textAlign: 'center' }}>Assigned To</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "12%", textAlign: 'center' }}>Notes</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {FilterAssetReportArray.length > 0 ? (
                                                FilterAssetReportArray.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((AssetReport, index) => (
                                                    <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{AssetReport.assetID}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{AssetReport.description}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{AssetReport.checkoutDate}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{AssetReport.dueDate}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{AssetReport.assignedTo}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{AssetReport.notes}</td>

                                                    </tr>

                                                ))
                                            ) : (
                                                <td colSpan="6" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {totalEntriesAsset > 0 && (
                            <div>
                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                    <div className="text-muted fontFamily fontsize12 fontweightRegular pLeft20">
                                        Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                    </div>
                                    <div>
                                        <div className="pagination-container d-flex align-items-center">
                                            <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                            </button>
                                            <ReactPaginate
                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                forcePage={pageAsset}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handleChangePagePaginationAsset}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                renderOnZeroPageCount={null}
                                            />
                                            <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ padding: "0px 100px" }}></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckOutSiteAndLocationReport;
