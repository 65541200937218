import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Header from '../Header/Header';
import SideMenuNew from '../SideMenu/SideMenuNew';

import '../SideMenu/SideMenu.css';


const Layout = () => {
    const location = useLocation();
    const [activeMenuItem, setActiveMenuItem] = useState(location.pathname);

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <Header />
            <SideMenuNew activeMenuItem={activeMenuItem} setActiveMenuItem={setActiveMenuItem} />
            <main id="main" className="main" style={{ marginLeft: '0px', flexGrow: 1 }}>
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;
