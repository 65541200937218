import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import { parse, format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import GetUserAccess from '../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../CommomPages/LoadingSpinner';
import { getDateFormat, getFinancialYear } from "../../CommomPages/Config";

import { getEmployee } from "../../Services/EmployeeManagementService";
import { getDepartment } from "../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../Services/BusinessEntityNewService";
import { getAsset, getAssetStatus } from "../../Services/AssetSettingsService";
import { getCategory, getSubCategory, getBrand } from "../../Services/AssetCategoriesAndBrandService";
import { getCheckOut, getCheckIn, getRepair, getLost } from '../../Services/ActionFormConfigurationService';

import rupeesvgIcon from "../../assets/icon/Rupee.svg";
import nodataFound from "../../assets/img/nodatafound.png";
import SettingsSvg from '../../assets/icon/settingDashboard.svg';


const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '275px'
    // }),
};

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userScreens, setUserScreens] = useState({});

    const [tabActive, setTabActive] = useState('Checked Out');
    const allTabs = ['Checked Out', 'Checked In', 'Under Repair', 'Lost'];

    const [assetArray, setAssetArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [checkoutAssetArray, setCheckoutAssetArray] = useState([]);
    const [checkinAssetArray, setCheckinAssetArray] = useState([]);
    const [repairAssetArray, setRepairAssetArray] = useState([]);
    const [lostAssetArray, setLostAssetArray] = useState([]);
    const [filterCheckoutAssetArray, setFilterCheckoutAssetArray] = useState([]);
    const [filterCheckinAssetArray, setFilterCheckinAssetArray] = useState([]);
    const [filterRepairAssetArray, setFilterRepairAssetArray] = useState([]);
    const [filterLostAssetArray, setFilterLostAssetArray] = useState([]);
    const [filterCategoryAssetArray, setFilterCategoryAssetArray] = useState([]);

    const [totalNAVCost, setTotalNAVCost] = useState(0);
    const [totalAssetCost, setTotalAssetCost] = useState(0);
    const [totalFinancialYearAssetCost, setFinancialYearTotalAssetCost] = useState(0);
    const [financialYearAssetCount, setFinancialYearAssetCount] = useState(0);

    const monthMapping = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11,
    };


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            unitId: [],
            branchId: [],
            departmentId: [],
            empId: [],
        },
    });

    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                fetchUserScreens(),
                fetchAssetData(),
                getDepartmentData(),
                getUnitBranchDetails(),
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchUserScreens();
        initialDataLoad();
        dataInitialization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const fetchAssetData = async () => {
        try {
            setIsLoading(true);

            // Fetch current financial year start month (e.g., "April" for April–March)
            const currentFinancialYear = await getFinancialYear(); // e.g., "April"

            // Parse start month from the financial year
            const startMonth = monthMapping[currentFinancialYear];
            const currentDate = new Date();
            let financialYearStartDate;

            // Set start date of the financial year
            financialYearStartDate = new Date(currentDate.getFullYear(), startMonth, 1);

            // Adjust the year if the start date is in the future
            if (currentDate < financialYearStartDate) {
                financialYearStartDate.setFullYear(currentDate.getFullYear() - 1);
            }

            const assetData = await getAsset();

            // Calculate the total cost of assets
            const totalCost = assetData?.reduce((sum, asset) => {
                const cost = parseFloat(asset?.cost) || 0;
                return sum + cost;
            }, 0);

            // Format total cost with commas
            setTotalAssetCost(totalCost?.toLocaleString());

            // Calculate total cost and count of assets from last financial year to current date
            const { financialYearCost, financialYearAssetCount } = assetData?.reduce((acc, asset) => {
                const cost = parseFloat(asset?.cost) || 0;
                const purchaseDate = new Date(asset?.purchaseDate);

                if (purchaseDate >= financialYearStartDate && purchaseDate <= currentDate) {
                    acc.financialYearCost += cost;
                    acc.financialYearAssetCount += 1;
                }
                return acc;
            }, { financialYearCost: 0, financialYearAssetCount: 0 });

            // Format and set the financial year total asset cost
            setFinancialYearTotalAssetCost(financialYearCost?.toLocaleString());

            // Set the financial year asset count
            setFinancialYearAssetCount(financialYearAssetCount);

            // Calculate total NAV cost
            const totalNAVCostAssets = assetData.reduce((sum, asset) => {
                if (asset?.depreciableAsset === "Yes") {
                    const depreciableCost = parseFloat(asset?.depreciableCost) || 0;
                    const salvageValue = parseFloat(asset?.salvageValue) || 0;
                    const assetLifeInMonths = parseFloat(asset?.assetLife) || 0;

                    // Convert asset life from months to years
                    const assetLifeInYears = assetLifeInMonths / 12;

                    // Calculate annual depreciation
                    const annualDepreciation = assetLifeInYears
                        ? (depreciableCost - salvageValue) / assetLifeInYears
                        : 0;

                    // Calculate accumulated depreciation based on purchase date
                    const purchaseDate = new Date(asset?.purchaseDate);
                    const yearsElapsed = (currentDate - purchaseDate) / (1000 * 60 * 60 * 24 * 365);
                    const accumulatedDepreciation = Math.min(annualDepreciation * yearsElapsed, depreciableCost);

                    // Calculate NAV
                    const nav = depreciableCost - accumulatedDepreciation;
                    return sum + nav;
                }
                return sum;
            }, 0);

            // Format NAV with commas
            setTotalNAVCost(totalNAVCostAssets?.toLocaleString());

            const brandArray = await getBrand();
            const assetStatus = await getAssetStatus();
            const dateCurrentFormat = await getDateFormat();

            // Map asset data as per previous code for display
            const finalData = assetData?.length > 0 ? assetData.map((asset, index) => {
                const brandAsset = brandArray?.find(x => x.id === asset?.brandId);
                const formattedDate = asset?.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);
                return {
                    ...asset,
                    Rowid: index + 1,
                    formatedPurchaseDate: formattedDate,
                    brandName: brandAsset ? brandAsset.brandName : '',
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue
                };
            }) : [];

            setAssetArray(finalData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const initialDataLoad = async () => {
        setIsLoading(true);
        const assetData = await getAsset();
        const categoryDatas = await getCategory();
        setCategoryArray(categoryDatas);
        const _checkOutAsset = await getCheckOut();
        setCheckoutAssetArray(_checkOutAsset);
        const _checkInAsset = await getCheckIn();
        setCheckinAssetArray(_checkInAsset);
        const _repairAsset = await getRepair();
        setRepairAssetArray(_repairAsset);
        const _lostAsset = await getLost();
        setLostAssetArray(_lostAsset);
        const empData = await getEmployee();
        const finalDataEmployee = empData.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp?.firstName + " " + Emp?.lastName,
            };
        });
        setEmployeeArray(finalDataEmployee);

        let filterData = assetData;
        console.log('filterData', filterData);

        let finalFilterDataCheckout = [];
        const checkoutAssetArrayList = filterData.map((asset, index) => {
            const checkoutAsset = _checkOutAsset.filter((x) => x.assetID == asset.id);
            if (checkoutAsset != null && checkoutAsset != undefined && checkoutAsset != '' && checkoutAsset.length > 0) {
                for (let i = 0; i < checkoutAsset.length; i++) {
                    const Checkoutdate = new Date(checkoutAsset[i]?.checkOutDate);
                    const checkOutformattedDate = `${Checkoutdate.getMonth() <= 8 ? '0' + (Checkoutdate.getMonth() + 1) : Checkoutdate.getMonth() + 1}/${Checkoutdate.getDate() <= 9 ? '0' + Checkoutdate.getDate() : Checkoutdate.getDate()}/${Checkoutdate.getFullYear()}`;

                    const Duedate = new Date(checkoutAsset[i]?.dueDate);
                    const DueformattedDate = `${Duedate.getMonth() <= 8 ? '0' + (Duedate.getMonth() + 1) : Duedate.getMonth() + 1}/${Duedate.getDate() <= 9 ? '0' + Duedate.getDate() : Duedate.getDate()}/${Duedate.getFullYear()}`;

                    let checkoutArrayObj = {
                        assetID: asset?.assetID,
                        description: asset?.assetName,
                        checkOutDate: checkoutAsset[i]?.checkOutDate == null ? '' : checkOutformattedDate,
                        dueDate: checkoutAsset[i]?.dueDate == null ? '' : DueformattedDate,
                        returnDate: checkoutAsset[i]?.checkOutDate == null ? '' : checkOutformattedDate,
                        assignedTo: checkoutAsset[i]?.isEmployee ? finalDataEmployee.find((x) => x.id == checkoutAsset[i].assignTo).EmployeeName : '',
                        notes: checkoutAsset[i]?.checkOutNotes
                    }
                    finalFilterDataCheckout.push(checkoutArrayObj)
                }
            }
        }).filter(item => item !== undefined);
        setFilterCheckoutAssetArray(finalFilterDataCheckout);

        let finalFilterDataCheckin = [];
        const checkinAssetArrayList = filterData.map((asset, index) => {
            const checkinAsset = _checkInAsset.filter((x) => x.assetID == asset.id);
            if (checkinAsset != null && checkinAsset.length > 0) {
                for (let i = 0; i < checkinAsset.length; i++) {
                    const ReturnDate = new Date(checkinAsset[i]?.returnDate);
                    const returnFormattedDate = `${ReturnDate.getMonth() <= 8 ? '0' + (ReturnDate.getMonth() + 1) : ReturnDate.getMonth() + 1}/${ReturnDate.getDate() <= 9 ? '0' + ReturnDate.getDate() : ReturnDate.getDate()}/${ReturnDate.getFullYear()}`;

                    let checkinArrayObj = {
                        assetID: asset?.assetID,
                        description: asset?.assetName,
                        returnDate: checkinAsset[i]?.returnDate == null ? '' : returnFormattedDate,
                        assignedTo: checkinAsset[i]?.isEmployee ? finalDataEmployee.find((x) => x.id == checkinAsset[i].assignTo)?.EmployeeName : '',
                        unitId: checkinAsset[i]?.unitId || null,
                        branchId: checkinAsset[i]?.branchId || null,
                        departmentId: checkinAsset[i]?.departmentId || null,
                        checkInNotes: checkinAsset[i]?.checkInNotes || '',
                        sendEmail: checkinAsset[i]?.isSendEmailCheck ? checkinAsset[i]?.sendEmail : '',
                    };
                    finalFilterDataCheckin.push(checkinArrayObj);
                }
            }
        }).filter(item => item !== undefined);
        setFilterCheckinAssetArray(finalFilterDataCheckin);

        let finalFilterDataRepair = [];
        const repairAssetArrayList = filterData
            .filter(asset => asset.isRepair === true)
            .map((asset, index) => {
                const repairAsset = _repairAsset.filter((x) => x.assetID == asset.id);
                if (repairAsset != null && repairAsset.length > 0) {
                    for (let i = 0; i < repairAsset.length; i++) {
                        const ScheduleDate = new Date(repairAsset[i]?.scheduleDate);
                        const scheduleFormattedDate = `${ScheduleDate.getMonth() <= 8 ? '0' + (ScheduleDate.getMonth() + 1) : ScheduleDate.getMonth() + 1}/${ScheduleDate.getDate() <= 9 ? '0' + ScheduleDate.getDate() : ScheduleDate.getDate()}/${ScheduleDate.getFullYear()}`;

                        const RepairCompletedDate = new Date(repairAsset[i]?.repairCompleted);
                        const repairCompletedFormattedDate = `${RepairCompletedDate.getMonth() <= 8 ? '0' + (RepairCompletedDate.getMonth() + 1) : RepairCompletedDate.getMonth() + 1}/${RepairCompletedDate.getDate() <= 9 ? '0' + RepairCompletedDate.getDate() : RepairCompletedDate.getDate()}/${RepairCompletedDate.getFullYear()}`;

                        let repairArrayObj = {
                            assetID: asset?.assetID,
                            description: asset?.assetName,
                            scheduleDate: repairAsset[i]?.scheduleDate == null ? '' : scheduleFormattedDate,
                            repairCompleted: repairAsset[i]?.repairCompleted == null ? '' : repairCompletedFormattedDate,
                            repairCost: repairAsset[i]?.repairCost || '0',
                            assignedTo: finalDataEmployee.find((x) => x.id == repairAsset[i].assignTo)?.EmployeeName || '',
                            notes: repairAsset[i]?.notes || ''
                        };
                        finalFilterDataRepair.push(repairArrayObj);
                    }
                }
            }).filter(item => item !== undefined);
        setFilterRepairAssetArray(finalFilterDataRepair);

        let finalFilterDataLost = [];
        const lostAssetArrayList = filterData
            .map((asset, index) => {
                const lostAsset = _lostAsset.filter((x) => x.assetID == asset.id);
                if (lostAsset != null && lostAsset.length > 0) {
                    for (let i = 0; i < lostAsset.length; i++) {
                        const DateLost = new Date(lostAsset[i]?.dateLost);
                        const dateLostFormatted = `${DateLost.getMonth() <= 8 ? '0' + (DateLost.getMonth() + 1) : DateLost.getMonth() + 1}/${DateLost.getDate() <= 9 ? '0' + DateLost.getDate() : DateLost.getDate()}/${DateLost.getFullYear()}`;

                        let lostArrayObj = {
                            assetID: asset?.assetID,
                            description: asset?.assetName,
                            dateLost: lostAsset[i]?.dateLost == null ? '' : dateLostFormatted,
                            notes: lostAsset[i]?.notes || ''
                        };
                        finalFilterDataLost.push(lostArrayObj);
                    }
                }
            }).filter(item => item !== undefined);
        setFilterLostAssetArray(finalFilterDataLost);

        let finalFilterDataCategory = [];
        const categoryAssetArrayList = filterData
            .map((asset, index) => {
                const categoryData = categoryDatas.find((cat) => cat.id == asset.categoryId);
                if (categoryData != null) {
                    let categoryArrayObj = {
                        assetID: asset?.assetID,
                        assetName: asset?.assetName,
                        categoryId: categoryData?.id,
                        categoryName: categoryData?.categoryName || '',
                        categoryCode: categoryData?.categoryCode || '',
                        description: categoryData?.description || '',
                        status: categoryData?.status || 'Inactive'
                    };
                    finalFilterDataCategory.push(categoryArrayObj);
                }
            }).filter(item => item !== undefined);
        setFilterCategoryAssetArray(finalFilterDataCategory);
        setIsLoading(false);
    };


    const getUnitBranchDetails = async () => {
        const unitData = await getBUData();
        const sortedUnitData = unitData?.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
        setUnitArray(sortedUnitData);

        const branchData = await getBRData();
        const sortedBranchData = branchData?.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
        setBranchArray(sortedBranchData);
    };

    const getDepartmentData = async () => {
        const data = await getDepartment();
        setDepartmentArray(data);
    };


    const handleSearchChange = (inputFieldsValue) => {
        let filterData = assetArray;
        if (inputFieldsValue) {
            filterData = assetArray.filter(record => {
                return (
                    (inputFieldsValue.unitId.length === 0 || inputFieldsValue.unitId === null || inputFieldsValue.unitId.includes(record.unitId)) &&
                    (inputFieldsValue.branchId.length === 0 || inputFieldsValue.unitId === null || inputFieldsValue.branchId.includes(record.branchId)) &&
                    (inputFieldsValue.departmentId.length === 0 || inputFieldsValue.unitId === null || inputFieldsValue.departmentId.includes(record.departmentId)) &&
                    (inputFieldsValue.empId.length === 0 || inputFieldsValue.empId === null || inputFieldsValue.empId.includes(record.empId))
                );
            });
        }
        console.log('filterData', filterData);

        let finalFilterDataCheckout = [];
        const checkoutAssetArrayList = filterData.map((asset, index) => {
            const checkoutAsset = checkoutAssetArray.filter((x) => x.assetID == asset.id);
            if (checkoutAsset != null && checkoutAsset != undefined && checkoutAsset != '' && checkoutAsset.length > 0) {
                for (let i = 0; i < checkoutAsset.length; i++) {
                    const Checkoutdate = new Date(checkoutAsset[i]?.checkOutDate);
                    const checkOutformattedDate = `${Checkoutdate.getMonth() <= 8 ? '0' + (Checkoutdate.getMonth() + 1) : Checkoutdate.getMonth() + 1}/${Checkoutdate.getDate() <= 9 ? '0' + Checkoutdate.getDate() : Checkoutdate.getDate()}/${Checkoutdate.getFullYear()}`;

                    const Duedate = new Date(checkoutAsset[i]?.dueDate);
                    const DueformattedDate = `${Duedate.getMonth() <= 8 ? '0' + (Duedate.getMonth() + 1) : Duedate.getMonth() + 1}/${Duedate.getDate() <= 9 ? '0' + Duedate.getDate() : Duedate.getDate()}/${Duedate.getFullYear()}`;

                    let checkoutArrayObj = {
                        assetID: asset?.assetID,
                        description: asset?.assetName,
                        checkOutDate: checkoutAsset[i]?.checkOutDate == null ? '' : checkOutformattedDate,
                        dueDate: checkoutAsset[i]?.dueDate == null ? '' : DueformattedDate,
                        returnDate: checkoutAsset[i]?.checkOutDate == null ? '' : checkOutformattedDate,
                        assignedTo: checkoutAsset[i]?.isEmployee ? employeeArray.find((x) => x.id == checkoutAsset[i].assignTo).EmployeeName : '',
                        notes: checkoutAsset[i]?.checkOutNotes
                    }
                    finalFilterDataCheckout.push(checkoutArrayObj)
                }
            }
        }).filter(item => item !== undefined);
        setFilterCheckoutAssetArray(finalFilterDataCheckout);

        let finalFilterDataCheckin = [];
        const checkinAssetArrayList = filterData.map((asset, index) => {
            const checkinAsset = checkinAssetArray.filter((x) => x.assetID == asset.id);
            if (checkinAsset != null && checkinAsset.length > 0) {
                for (let i = 0; i < checkinAsset.length; i++) {
                    const ReturnDate = new Date(checkinAsset[i]?.returnDate);
                    const returnFormattedDate = `${ReturnDate.getMonth() <= 8 ? '0' + (ReturnDate.getMonth() + 1) : ReturnDate.getMonth() + 1}/${ReturnDate.getDate() <= 9 ? '0' + ReturnDate.getDate() : ReturnDate.getDate()}/${ReturnDate.getFullYear()}`;

                    let checkinArrayObj = {
                        assetID: asset?.assetID,
                        description: asset?.assetName,
                        returnDate: checkinAsset[i]?.returnDate == null ? '' : returnFormattedDate,
                        assignedTo: checkinAsset[i]?.isEmployee ? employeeArray.find((x) => x.id == checkinAsset[i].assignTo)?.EmployeeName : '',
                        unitId: checkinAsset[i]?.unitId || null,
                        branchId: checkinAsset[i]?.branchId || null,
                        departmentId: checkinAsset[i]?.departmentId || null,
                        checkInNotes: checkinAsset[i]?.checkInNotes || '',
                        sendEmail: checkinAsset[i]?.isSendEmailCheck ? checkinAsset[i]?.sendEmail : '',
                    };
                    finalFilterDataCheckin.push(checkinArrayObj);
                }
            }
        }).filter(item => item !== undefined);
        setFilterCheckinAssetArray(finalFilterDataCheckin);

        let finalFilterDataRepair = [];
        const repairAssetArrayList = filterData
            .filter(asset => asset.isRepair === true)
            .map((asset, index) => {
                const repairAsset = repairAssetArray.filter((x) => x.assetID == asset.id);
                if (repairAsset != null && repairAsset.length > 0) {
                    for (let i = 0; i < repairAsset.length; i++) {
                        const ScheduleDate = new Date(repairAsset[i]?.scheduleDate);
                        const scheduleFormattedDate = `${ScheduleDate.getMonth() <= 8 ? '0' + (ScheduleDate.getMonth() + 1) : ScheduleDate.getMonth() + 1}/${ScheduleDate.getDate() <= 9 ? '0' + ScheduleDate.getDate() : ScheduleDate.getDate()}/${ScheduleDate.getFullYear()}`;

                        const RepairCompletedDate = new Date(repairAsset[i]?.repairCompleted);
                        const repairCompletedFormattedDate = `${RepairCompletedDate.getMonth() <= 8 ? '0' + (RepairCompletedDate.getMonth() + 1) : RepairCompletedDate.getMonth() + 1}/${RepairCompletedDate.getDate() <= 9 ? '0' + RepairCompletedDate.getDate() : RepairCompletedDate.getDate()}/${RepairCompletedDate.getFullYear()}`;

                        let repairArrayObj = {
                            assetID: asset?.assetID,
                            description: asset?.assetName,
                            scheduleDate: repairAsset[i]?.scheduleDate == null ? '' : scheduleFormattedDate,
                            repairCompleted: repairAsset[i]?.repairCompleted == null ? '' : repairCompletedFormattedDate,
                            repairCost: repairAsset[i]?.repairCost || '0',
                            assignedTo: employeeArray.find((x) => x.id == repairAsset[i].assignTo)?.EmployeeName || '',
                            notes: repairAsset[i]?.notes || ''
                        };
                        finalFilterDataRepair.push(repairArrayObj);
                    }
                }
            }).filter(item => item !== undefined);
        setFilterRepairAssetArray(finalFilterDataRepair);

        let finalFilterDataLost = [];
        const lostAssetArrayList = filterData
            .map((asset, index) => {
                const lostAsset = lostAssetArray.filter((x) => x.assetID == asset.id);
                if (lostAsset != null && lostAsset.length > 0) {
                    for (let i = 0; i < lostAsset.length; i++) {
                        const DateLost = new Date(lostAsset[i]?.dateLost);
                        const dateLostFormatted = `${DateLost.getMonth() <= 8 ? '0' + (DateLost.getMonth() + 1) : DateLost.getMonth() + 1}/${DateLost.getDate() <= 9 ? '0' + DateLost.getDate() : DateLost.getDate()}/${DateLost.getFullYear()}`;

                        let lostArrayObj = {
                            assetID: asset?.assetID,
                            description: asset?.assetName,
                            dateLost: lostAsset[i]?.dateLost == null ? '' : dateLostFormatted,
                            notes: lostAsset[i]?.notes || ''
                        };
                        finalFilterDataLost.push(lostArrayObj);
                    }
                }
            }).filter(item => item !== undefined);
        setFilterLostAssetArray(finalFilterDataLost);

        let finalFilterDataCategory = [];
        const categoryAssetArrayList = filterData
            .map((asset, index) => {
                const categoryData = categoryArray.find((cat) => cat.id == asset.categoryId);
                if (categoryData != null) {
                    let categoryArrayObj = {
                        assetID: asset?.assetID,
                        assetName: asset?.assetName,
                        categoryId: categoryData?.id,
                        categoryName: categoryData?.categoryName || '',
                        categoryCode: categoryData?.categoryCode || '',
                        description: categoryData?.description || '',
                        status: categoryData?.status || 'Inactive'
                    };
                    finalFilterDataCategory.push(categoryArrayObj);
                }
            }).filter(item => item !== undefined);
        setFilterCategoryAssetArray(finalFilterDataCategory);
    };


    const handleSearchClear = () => {
        reset();
        setFilterCheckoutAssetArray([]);
        setFilterCheckinAssetArray([]);
        setFilterRepairAssetArray([]);
        setFilterLostAssetArray([]);
        setFilterCategoryAssetArray([]);
        handleSearchChange(false);
    };

    const getRandomColor = () => {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    };

    const categoryCounts = filterCategoryAssetArray.reduce((acc, asset) => {
        if (asset.categoryId && categoryArray.some(category => category.id === asset.categoryId)) {
            acc[asset.categoryId] = (acc[asset.categoryId] || 0) + 1;
        }
        return acc;
    }, {});

    const totalAssets = Object.values(categoryCounts).reduce((sum, count) => sum + count, 0);

    const pieChartData = Object.entries(categoryCounts)
        .map(([categoryId, count]) => {
            const categoryData = categoryArray.find(cat => cat.id == parseInt(categoryId));
            if (!categoryData) return null;

            const percentage = ((count / totalAssets) * 100).toFixed(2);
            return {
                title: categoryData.categoryName,
                value: parseFloat(percentage),
                color: getRandomColor()
            };
        })
        .filter(data => data !== null);

    const chartLegends = pieChartData.map((data, index) => (
        <div key={index} className="legend-item">
            <span className="legend-color" style={{ backgroundColor: data.color }}></span>
            <span>{data.title} - {data.value}%</span>
        </div>
    ));


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleDashboard">
                        <div className="PageheaderDashboard boxShadow fontFamily fontsize24 fontweightSemiBold">Dashboard</div>
                    </div>
                    <div className="useScrollbar">
                        <div className="card mt-4">
                            <div className="card-body-Dashboard">

                                <div className="PageDashboardHeader mBottom24">
                                    <div className="blackTextColor fontFamily fontweightMedium fontsize20">
                                        Dashboard & Statistics
                                    </div>
                                    <div>
                                        <button type="button" className="fontFamily fontweightRegular fontsize14 manageDashboardBtn">
                                            Manage Dashboard <img src={SettingsSvg} alt="Settings" />
                                        </button>
                                    </div>
                                </div>

                                <div className="row mBottom24">
                                    <div className="col-md-3 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            Unit
                                        </div>
                                        <Controller
                                            name="unitId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={unitArray.filter((c) => field.value.includes(c.bU_ID)) || []}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.map((option) => option.bU_ID) : []);
                                                    }}
                                                    options={unitArray}
                                                    getOptionLabel={(option) => option.bU_NAME}
                                                    getOptionValue={(option) => option.bU_ID}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            Branch
                                        </div>
                                        <Controller
                                            name="branchId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={branchArray.filter((c) => field.value.includes(c.bR_ID)) || []}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map((option) => option.bR_ID) : []);
                                                    }}
                                                    options={branchArray}
                                                    getOptionLabel={(option) => option.bR_NAME}
                                                    getOptionValue={(option) => option.bR_ID}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            Department
                                        </div>
                                        <Controller
                                            name="departmentId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={departmentArray.filter((c) => field.value.includes(c.id)) || []}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map((option) => option.id) : []);
                                                    }}
                                                    options={departmentArray}
                                                    getOptionLabel={(option) => option.departmentName}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            Employee
                                        </div>
                                        <Controller
                                            name="empId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={employeeArray.filter(emp => field.value.includes(emp.id)) || []}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.map((option) => option.id) : []);
                                                    }}
                                                    options={employeeArray}
                                                    getOptionLabel={(option) => option.EmployeeName}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    hideSelectedOptions={false}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="flexEnd mt-1">
                                    <button
                                        className="fontFamily fontweightRegular fontsize16 coloredButton"
                                        style={{ marginLeft: "20px" }}
                                        onClick={handleSubmit(handleSearchChange)}
                                    >
                                        Search
                                    </button>
                                    <span>
                                        <button
                                            className="fontFamily fontweightRegular fontsize16 importBtn"
                                            onClick={handleSearchClear}
                                        >
                                            Clear
                                        </button>
                                    </span>
                                </div>

                                <div className='d-flex mBottom24'>
                                    <div className="dashboardStatisticsDetails mRight20 pTop24 text-center">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20'>
                                            Number of Active
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16'>
                                            {assetArray.length > 0 ? assetArray.length : 0}
                                        </div>
                                    </div>

                                    <div className="dashboardStatisticsDetails mRight20 pTop24 text-center">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20'>
                                            NAV; Net Asset v...
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16 '>
                                            <img src={rupeesvgIcon} alt="rupeesvgIcon" height={15} /> {totalNAVCost}
                                        </div>
                                    </div>

                                    <div className="dashboardStatisticsDetails mRight20 pTop24 text-center">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20'>
                                            Value of Asset
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16 '>
                                            <img src={rupeesvgIcon} alt="rupeesvgIcon" height={15} /> {totalAssetCost}
                                        </div>
                                    </div>

                                    <div className="dashboardStatisticsDetails pTop24">
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize16 pBottom20 text-center'>
                                            Purchase in Fisc...
                                        </div>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16 pBottom5 text-center'>
                                            <img src={rupeesvgIcon} alt="rupeesvgIcon" height={15} /> {totalFinancialYearAssetCost}
                                        </div>
                                        <div className='ashTextColor fontFamily fontweightRegular fontsize14 text-end pRight10'>
                                            {financialYearAssetCount} Assets
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex'>
                                    <div className='dashbordChartBox mRight20'>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize16 pBottom12 pLeft20 pTop12'>
                                            Asset Value
                                        </div>
                                        <hr></hr>
                                        <div>
                                            <div className="pie-chart-wrapper mTop20 pRight12">
                                                <PieChart
                                                    data={pieChartData}
                                                    labelStyle={{ display: 'none' }}
                                                    style={{ height: '250px', width: '250px' }}
                                                />
                                                <div className="customScrollbarPieChart" style={{ padding: "1px" }}>
                                                    <div className="chart-legend">
                                                        {chartLegends}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dashbordAssetBox'>
                                        <div className='blackTextColor fontFamily fontweightRegular fontsize14 pLeft20 pRight20'>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex'>
                                                    <div className='fontFamily fontweightRegular fontsize16 pRight60 pBottom12 pTop12'>
                                                        Feeds
                                                    </div>
                                                    <div className="DashboardTab pRight30 pLeft30">
                                                        {allTabs?.map(tab => (
                                                            <a
                                                                key={tab}
                                                                href={`#${tab.replace(' ', '')}`}
                                                                className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                                                onClick={() => setTabActive(tab)}
                                                            >
                                                                <div className="fontFamily fontweightRegular fontsize20 pRight30 pLeft30">
                                                                    {tab}
                                                                </div>
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                                {/* <div className='d-flex'>
                                                    <img src={SettingsSvg} alt="Settings" />
                                                </div> */}
                                            </div>
                                        </div>
                                        <hr></hr>

                                        <div className='mTop20 mRight20 mLeft20'>
                                            {tabActive === 'Checked Out' && (
                                                <div className="customScrollbarAsset" style={{ padding: "1px" }}>
                                                    <table className="w-100">
                                                        <thead>
                                                            <tr className="text-left useTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>Asset ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Description</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Check-Out Date</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Due Date</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }}>Assigned To</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filterCheckoutAssetArray.length > 0 ? (filterCheckoutAssetArray.map((Asset, index) => (
                                                                <tr key={Asset.Rowid}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 3%" }}>{Asset.assetID}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.description}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.checkOutDate}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.dueDate}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}> {Asset.assignedTo}</td>
                                                                </tr>
                                                            ))
                                                            ) : (
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {tabActive === 'Checked In' && (
                                                <div className="customScrollbarAsset" style={{ padding: "1px" }}>
                                                    <table className="w-100">
                                                        <thead>
                                                            <tr className="text-left useTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>Asset ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Description</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Return Date</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Notes</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }}>Assigned To</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filterCheckinAssetArray.length > 0 ? (filterCheckinAssetArray.map((Asset, index) => (
                                                                <tr key={Asset.Rowid}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 3%" }}>{Asset.assetID}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.description}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.returnDate}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={Asset.checkInNotes}>{Asset.checkInNotes}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}> {Asset.assignedTo}</td>
                                                                </tr>
                                                            ))
                                                            ) : (
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {tabActive === 'Under Repair' && (
                                                <div className="customScrollbarAsset" style={{ padding: "1px" }}>
                                                    <table className="w-100">
                                                        <thead>
                                                            <tr className="text-left useTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>Asset ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Description</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Schedule Date</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Notes</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }}>Assigned To</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filterRepairAssetArray.length > 0 ? (filterRepairAssetArray.map((Asset, index) => (
                                                                <tr key={Asset.Rowid}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 3%" }}>{Asset.assetID}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.description}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.scheduleDate}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={Asset.notes}>{Asset.notes}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}> {Asset.assignedTo}</td>
                                                                </tr>
                                                            ))
                                                            ) : (
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {tabActive === 'Lost' && (
                                                <div className="customScrollbarAsset" style={{ padding: "1px" }}>
                                                    <table className="w-100">
                                                        <thead>
                                                            <tr className="text-left useTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>Asset ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Description</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Date Lost</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Notes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filterLostAssetArray.length > 0 ? (filterLostAssetArray.map((Asset, index) => (
                                                                <tr key={Asset.Rowid}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 3%" }}>{Asset.assetID}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.description}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Asset.dateLost}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={Asset.notes}>{Asset.notes}</td>
                                                                </tr>
                                                            ))
                                                            ) : (
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
