import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const exportDataUnit = (data, format, countries, states, cities) => {
    const headers = ["Unit Name", "Address", "City", "State", "Country", "Mobile", "Email", "Website"];

    const rows = data.map(unit => {
        const country = countries.find(x => x.id === unit.bU_CNTRY_ID)?.label || '';
        const state = states.find(x => x.id === unit.bU_STATE_ID)?.label || '';
        const city = cities.find(x => x.id === unit.bU_CITY_ID)?.label || '';
        return [
            unit.bU_NAME || '',
            unit.bU_ADDR || '',
            city,
            state,
            country,
            unit.bU_TEL || '',
            unit.bU_EMAIL || '',
            unit.bU_WEBSITE || ''
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    switch (format) {
        case 'csv': {
            const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
            fileData = csvContent;
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = 'csv';
            break;
        }
        case 'excel': {
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
            fileExtension = 'xlsx';
            break;
        }
        case 'pdf': {
            const doc = new jsPDF('landscape', 'mm', 'a4');
            doc.setFontSize(10);
            doc.autoTable({
                head: [headers],
                body: rows,
                styles: { overflow: 'linebreak', fontSize: 8 },
                margin: { top: 20 },
                tableWidth: 'auto'
            });
            fileData = doc.output('blob');
            fileType = 'application/pdf';
            fileExtension = 'pdf';
            break;
        }
        default:
            return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `business_units_${formattedDate}.${fileExtension}`);
};

export const exportDataBranch = (branchData, format, countries, states, cities, unitData) => {
    const headers = ["Unit Name", "Branch Name", "Address", "City", "State", "Country", "Mobile", "Email", "Website"];

    const getUnitNameForBranchModel = (unitId) => {
        const unitName = unitData.find(unit => unit.bU_ID === unitId);
        return unitName ? { label: unitName.bU_NAME, value: unitName.bU_NAME } : null;
    };

    if (!Array.isArray(branchData)) {
        console.error('branchData is not an array:', branchData);
        return;
    }

    const rows = branchData.map(branch => {
        const country = countries.find(x => x.id === branch.bR_CNTRY_ID)?.label || '';
        const state = states.find(x => x.id === branch.bR_STATE_ID)?.label || '';
        const city = cities.find(x => x.id === branch.bR_CITY_ID)?.label || '';
        const UnitName = getUnitNameForBranchModel(branch?.bR_BU);

        return [
            UnitName?.label || '',
            branch.bR_NAME || '',
            branch.bR_ADDR || '',
            city,
            state,
            country,
            branch.bR_TEL || '',
            branch.bR_EMAIL || '',
            branch.bR_WEBSITE || ''
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    switch (format) {
        case 'csv': {
            const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
            fileData = csvContent;
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = 'csv';
            break;
        }
        case 'excel': {
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
            fileExtension = 'xlsx';
            break;
        }
        case 'pdf': {
            const doc = new jsPDF('landscape', 'mm', 'a4');
            doc.setFontSize(10);
            doc.autoTable({
                head: [headers],
                body: rows,
                styles: { overflow: 'linebreak', fontSize: 8 },
                margin: { top: 20 },
                tableWidth: 'auto'
            });
            fileData = doc.output('blob');
            fileType = 'application/pdf';
            fileExtension = 'pdf';
            break;
        }
        default:
            return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `business_branches_${formattedDate}.${fileExtension}`);
};
