import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportSepplierData = (data, format) => {
    const headers = ["SI.No", "Supplier Name", "Supplier Short Name", "Supplier ID", "Phone Number", "Mobile Number", "Email ID", "GST Number", "Address", "Country", "State", "City", "Zip Code", "Status"];
    const rows = data.map(Sup => {
        return [
            Sup.Rowid,
            Sup.supplierName,
            Sup.supplierShortName,
            Sup.supplierID,
            Sup.phoneNumber,
            Sup.mobileNumber,
            Sup.email,
            Sup.gstNumber,
            Sup.address,
            Sup.country,
            Sup.state,
            Sup.city,
            Sup.zipCode,
            Sup.SupplierStatus
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Supplier_List_${formattedDate}.${fileExtension}`);
};

export const exportSourcedAssetData = (data, format) => {
    const headers = ["SI.No", "Asset ID", "Category", "Sub Category", "Brand", "Associated Date"];
    const rows = data.map(Asset => {
        return [
            Asset.Rowid,
            Asset.assetID,
            Asset.categoryName,
            Asset.subCategoryName,
            Asset.brandName,
            Asset.formatedPurchaseDate,
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Sourced_Asset_List_${formattedDate}.${fileExtension}`);
};
