import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

export async function updateGSettings(payload) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateGeneralSetting', payload, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating General Settings:', error);
        throw error;
    }
}

export async function getGSetting() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getGeneralSetting', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching GSData:', error);
        throw error;
    }
}

// Document
export async function getDocument() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getDocument', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function createDocument(doc) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateDocument', doc, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function updateDocument(doc) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateDocument', doc, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function deleteDocument(docID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteDocument?docID=${docID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}