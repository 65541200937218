import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportDataCategory = (data, format) => {
    const headers = ["SI.No", "Category", "Code", "Description", "Status"];

    const rows = data.map(Category => {
        return [
            Category.Rowid || '',
            Category.categoryName?.trim() || '',
            Category.categoryCode?.trim() || '',
            Category.description?.trim() || '',
            Category.status || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Category_Data_${formattedDate}.${fileExtension}`);
};

export const exportDataSubCategory = (data, format) => {
    const headers = ["SI.No", "Category", "Sub Category", "Code", "Description", "Status"];
    const rows = data.map(SubCategory => {
        return [
            SubCategory.Rowid || '',
            SubCategory.categoryName?.trim() || '',
            SubCategory.subCategoryName?.trim() || '',
            SubCategory.subCategoryCode?.trim() || '',
            SubCategory.description?.trim() || '',
            SubCategory.status || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `SubCategory_Data_${formattedDate}.${fileExtension}`);
};


export const exportDataBrand = (brandData, format) => {
    const headers = ["SI.No", "Category Name", "Sub-Category Name", "Brand Name", "Model Name", "Status"];

    const rows = brandData.map((brand, index) => {
        return [
            index + 1,
            brand.categoryName || '',
            brand.subCategoryName || '',
            brand.brandName || '',
            brand.modelName || '',
            brand.status || ''
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Brand_Data_${formattedDate}.${fileExtension}`);
};
