import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

// Country
export async function getCountry() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getCountry', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Country:', error);
        throw error;
    }
}

export async function createCountry(country) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateCountry', country, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Country:', error);
        throw error;
    }
}

export async function updateCountry(country) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateCountry', country, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update Country:', error);
        throw error;
    }
}

export async function deleteCountry(countryID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteCountry?countryID=${countryID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Country:', error);
        throw error;
    }
}


// State
export async function getState() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getState', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching State:', error);
        throw error;
    }
}

export async function createState(state) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateState', state, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create State:', error);
        throw error;
    }
}

export async function updateState(State) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateState', State, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update State:', error);
        throw error;
    }
}

export async function deleteState(stateID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteState?stateID=${stateID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete State:', error);
        throw error;
    }
}


// City
export async function getCity() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getCity', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching getCity:', error);
        throw error;
    }
}

export async function createCity(City) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateCity', City, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching City:', error);
        throw error;
    }
}

export async function updateCity(City) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateCity', City, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching City:', error);
        throw error;
    }
}

export async function deleteCity(CityID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteCity?cityID=${CityID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching City:', error);
        throw error;
    }
}