import React from 'react';
import { Circles, ColorRing } from 'react-loader-spinner';

const LoadingSpinner = () => {
    return (
        <div className="loaderStyle">
            <ColorRing
                visible={true}
                height="100"
                width="100"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#605bff', '#605bff','#605bff','#605bff','#605bff']} // Red and Yellow
                backgroundColor={'red'}
            />
            {/* <Circles
                height="80"
                width="80"
                color="#605bff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            /> */}
        </div>
    );
};

export default LoadingSpinner;
