import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { useAuth } from '../../Auth/AuthContext';
import { getRoleData } from '../../Services/RoleManagementService';
import { getUserAccessPermission } from '../../Services/UserAccessPermissionService';
import { loginUser, getCountries, getStates, getCities } from '../../Services/SignInService';
import { getProFormInsurance, getProFormMaintenance, getProFormServiceRequest, getProFormWarranty } from '../../Services/ProcessFormConfigurationService';

import './SignIn.css';
import '../../assets/css/style.css';
import '../../assets/vendor/quill/quill.snow.css';
import '../../assets/vendor/quill/quill.bubble.css';
import '../../assets/vendor/remixicon/remixicon.css';
import '../../assets/vendor/simple-datatables/style.css';
import '../../assets/vendor/boxicons/css/boxicons.min.css';
import '../../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../assets/vendor/bootstrap-icons/bootstrap-icons.css';

import LoginImg from '../../assets/img/LoginImg.png';
import iTechLogo from '../../assets/img/iTech_Logo.png';
import Ellipse from '../../assets/img/Ellipse.png'


const SignIn = () => {
    const { logout, login } = useAuth();
    const navigate = useNavigate();
    const passwordRef = useRef(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        logout();
        loadRememberedCredentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setUserAccess = async () => {
        try {
            // Fetch user ID from localStorage
            const userID = parseInt(localStorage.getItem('UserEmpID'), 10);
            if (!userID) throw new Error("User ID not found in localStorage");
    
            // Fetch user access permissions
            const userData = await getUserAccessPermission();
            const user = userData?.find(user => user?.employeeId === userID);
            if (!user) throw new Error("User access data not found for the given User ID");
    
            // Parse role IDs from user data
            const roleIds = user.roleId?.split(',')?.map(id => parseInt(id, 10));
            if (!roleIds || roleIds.length === 0) throw new Error("No role IDs found for the user");
    
            // Fetch role data and map role names
            const roleData = await getRoleData();
            const roles = roleIds?.map(roleId => {
                const foundRole = roleData?.find(option => option?.rolE_ID === roleId);
                return foundRole ? foundRole?.rolE_User_Role : '';
            }).filter(Boolean);
    
            // Store the first valid role in localStorage if any roles were found
            if (roles.length > 0) {
                localStorage.setItem('UserRole', roles[0]);
    
                // Extract screens and permissions for the first role
                const roleScreens = roleData?.find(option => option?.rolE_ID === roleIds[0])?.rolE_Screen;
                if (!roleScreens) throw new Error("Screen permissions not found for the user's primary role");
    
                const selectedScreens = JSON.parse(roleScreens);
    
                // Clean up selected screens by removing any empty objects
                for (let key in selectedScreens) {
                    if (Object.keys(selectedScreens[key]).length === 0) {
                        delete selectedScreens[key];
                    }
                }
    
                // Store cleaned screens in localStorage
                console.log('selectedScreens in signin', selectedScreens);
                localStorage.setItem('UserScreens', JSON.stringify(selectedScreens));
            } else {
                throw new Error("No valid roles found for the user");
            }
        } catch (error) {
            console.error('Error fetching user access data:', error);
            toast.error('Error fetching user access data');
        }
    };
    

    const fetchData = async () => {
        try {
            await getCountries();
            await getStates();
            await getCities();
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const loadRememberedCredentials = async () => {
        const rememberedUsername = localStorage.getItem('username');
        const encryptedPassword = localStorage.getItem('password');
        console.log('rememberedUsername:', rememberedUsername);
        console.log('encryptedPassword:', encryptedPassword);

        if (rememberedUsername && encryptedPassword) {
            setUsername(rememberedUsername);
            const secretKey = deriveSecretKey();

            try {
                const decryptedPassword = decryptPassword(encryptedPassword, secretKey);
                setPassword(decryptedPassword);
                setRememberMe(true);
            } catch (error) {
                console.error('Error decrypting password:', error);
            }
        } else {
            console.log('No remembered credentials found.');
        }
    };

    const encryptPassword = (password, secretKey) => {
        try {
            const encrypted = CryptoJS.AES.encrypt(password, secretKey).toString();
            return encrypted;
        } catch (error) {
            console.error('Error encrypting password:', error);
            throw error;
        }
    };

    const deriveSecretKey = () => {
        try {
            const salt = 'someSaltValue';
            const secret = 'someSecretValue';
            const derivedKey = CryptoJS.PBKDF2(secret, salt, { keySize: 256 / 32, iterations: 1000 }).toString();
            return derivedKey;
        } catch (error) {
            console.error('Error deriving secret key:', error);
            throw error;
        }
    };

    const decryptPassword = (ciphertext, secretKey) => {
        try {
            const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
            const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedPassword.trim();
        } catch (error) {
            console.error('Error decrypting password:', error);
            throw error;
        }
    };

    const encryptAndStorePassword = (username, password) => {
        const secretKey = deriveSecretKey();
        const encryptedPassword = encryptPassword(password, secretKey);
        localStorage.setItem('username', username);
        localStorage.setItem('password', encryptedPassword);
    };


    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    const handleUserNameChange = (e) => {
        setUsername(e.target.value);
        setErrorUsername(e.target.value ? '' : 'Please enter your email');
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        if (!value) {
            setErrorPassword('Please enter your password!');
        } else if (value.length < 8) {
            setErrorPassword('Password must contain at least 8 characters');
        } else {
            setErrorPassword('');
        }
    };

    const handleKeyDown = (e, ref) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            ref.current.focus();
        }
    };

    const btnLogin = async (event) => {
        event.preventDefault();
        logout();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!username) {
            setErrorUsername('Please enter your email');
            return;
        } else if (!emailRegex.test(username)) {
            setErrorUsername('Please enter a valid email address');
            return;
        }

        if (!password) {
            setErrorPassword('Please enter your password!');
            return;
        }
        if (password.length < 8) {
            setErrorPassword('Password must contain at least 8 characters');
            return;
        }

        try {
            const loginData = await loginUser(username, password);
            if (loginData === 'Login Successfully') {
                toast.success(loginData);
                login(); // Perform login actions

                try {
                    await setUserAccess(); // Set user access if login is successful
                } catch (error) {
                    console.error("Failed to set user access:", error);
                    toast.error("Failed to set user access");
                }

                // Remember credentials if 'rememberMe' is checked
                if (rememberMe) {
                    encryptAndStorePassword(username, password);
                } else {
                    localStorage.setItem('username', username);
                    localStorage.removeItem('password');
                }

                // Navigate to Dashboard after a brief delay
                setTimeout(() => navigate("/Dashboard"), 1000);

                // Fetch additional data for inbox and dashboard after navigation
                await Promise.all([fetchInboxData(), fetchData()]);

            } else {
                toast.error(loginData); // Show login error if login fails
            }
        } catch (error) {
            toast.error("Login failed. Please try again.");
            console.error("Login error:", error); // Log error for debugging
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const fetchInboxData = async () => {
        const currentDate = new Date();
        const oneMonthLater = new Date();
        oneMonthLater.setMonth(currentDate.getMonth() + 1);

        // Insurance
        const insuranceData = await getProFormInsurance();
        let activeInsuranceCount = 0;
        let expiredInsuranceCount = 0;
        let nearlyExpiringInsuranceCount = 0;

        const finalInsuranceData = insuranceData?.map((insurance, index) => {
            const endDate = new Date(insurance?.insuranceEndDate);
            if (endDate > currentDate) {
                // Active Insurance
                activeInsuranceCount++;

                // Check if it is nearly expiring (within 1 month)
                if (endDate <= oneMonthLater) {
                    // Nearly Expiring Insurance
                    nearlyExpiringInsuranceCount++;
                }
            } else {
                // Expired Insurance
                expiredInsuranceCount++;
            }
        });
        localStorage.setItem('InsuranceCount', nearlyExpiringInsuranceCount);


        // Warranty
        const warrantyData = await getProFormWarranty();
        let activeWarrantyCount = 0;
        let expiredWarrantyCount = 0;
        let nearlyExpiringWarrantyCount = 0;

        const finalWarrantyData = warrantyData?.map((war, index) => {
            const expirationDate = new Date(war?.expirationDate);
            if (expirationDate > currentDate) {
                // Active Warranty
                activeWarrantyCount++;

                // Check if it is nearly expiring (within 1 month)
                if (expirationDate <= oneMonthLater) {
                    // Nearly Expiring Warranty
                    nearlyExpiringWarrantyCount++;
                }
            } else {
                // Expired Warranty
                expiredWarrantyCount++;
            }
        });
        localStorage.setItem('WarrantyCount', nearlyExpiringWarrantyCount);

        // ServiceRequest
        const serviceRequestData = await getProFormServiceRequest();
        const userID = parseInt(localStorage.getItem('UserEmpID'), 10);
        const myPendingRequestData = serviceRequestData.filter((e) => e?.empIds.includes(userID) && e.status != 'completed')
        localStorage.setItem('ServiceRequestCount', myPendingRequestData.length);

        // Maintenance
        const maintenanceData = await getProFormMaintenance();
        const _inprogressdMaintenanceData = maintenanceData.filter((e) => e.maintenanceStatus == 'inprogress');
        localStorage.setItem('MaintenanceCount', _inprogressdMaintenanceData.length);
    };

    return (
        <div>
            <header style={{ position: 'relative', top: '10px', left: '10px' }}>
                <div className='headerStyle'>
                    <div className='fontFamily fontsize36 fontweightSemiBold'>
                        iTech Assets
                    </div>
                    <div>
                        <img src={iTechLogo} style={{ width: '200px' }} alt="iTech Logo" />
                    </div>
                </div>
            </header>
            <main className="container-fluid">
                <div style={{ textAlign: 'center' }}>
                    <ToastContainer theme="colored" />
                </div>
                <div className="container-wrapper" style={{ height: '84%' }}>
                    <div className="image-container" style={{ padding: '0px 74px', marginBottom: '3%' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ textAlign: 'left', width: '37%' }}>
                                <img src={LoginImg} alt="Login" className='loginImage' />
                            </div>
                            <div className='fontFamily fontsize32 fontweightRegular ashTextColor' style={{ textAlign: 'left', marginLeft: '13%' }}>
                                A Streamlined Asset<br />management with AI <br />and ML
                            </div>
                        </div>
                        <div className='fontFamily fontsize15 fontweightRegular ashTextColor' style={{ textAlign: 'left', padding: '3% 0%' }}>
                            Experience the power of New age AI, ML integrated asset management system. <br />Explore our intuitive interface and discover how we take asset management to the <br /> next level.
                        </div>
                        <div className='fontFamily fontsize16 fontweightBold' style={{ textAlign: 'left' }}>
                            See it in Action:
                            <div style={{ display: 'flex', padding: '1% 0%' }}>
                                <div>
                                    <img src={Ellipse} alt="Login" style={{ paddingBottom: '5px' }} />
                                </div>
                                <div className='fontFamily fontsize12 fontweightRegular ashTextColor' style={{ padding: '0% 1%' }}>
                                    Track everything from the smallest tool to the largest equipment.
                                </div>
                            </div>
                            <div style={{ display: 'flex', padding: '1% 0%' }}>
                                <div>
                                    <img src={Ellipse} alt="Login" style={{ paddingBottom: '5px' }} />
                                </div>
                                <div className='fontFamily fontsize12 fontweightRegular ashTextColor' style={{ padding: '0% 1%' }}>
                                    Get real-time insights to make informed decisions.
                                </div>
                            </div>
                            <div style={{ display: 'flex', padding: '1% 0%' }}>
                                <div>
                                    <img src={Ellipse} alt="Login" style={{ paddingBottom: '5px' }} />
                                </div>
                                <div className='fontFamily fontsize12 fontweightRegular ashTextColor' style={{ padding: '0% 1%' }}>
                                    Automate routine tasks and boost productivity.
                                </div>
                            </div>
                            <div style={{ display: 'flex', padding: '1% 0%' }}>
                                <div>
                                    <img src={Ellipse} alt="Login" style={{ paddingBottom: '5px' }} />
                                </div>
                                <div className='fontFamily fontsize12 fontweightRegular ashTextColor' style={{ padding: '0% 1%' }}>
                                    Achieve maximum efficiency and compliance.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-container">
                        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-md-12 d-flex flex-column align-items-center justify-content-center">
                                        <div className="Logincard mb-3">
                                            <div className="card-body">
                                                <h4 className="card-title text-center pb-0 fs-4 fontFamily fontsize32 fontweightBold mt-3 mb-4 px-2">Login</h4>

                                                <form className="row g-3 needs-validation mx-5" onSubmit={btnLogin} noValidate>
                                                    <div className="col-12">
                                                        <div className="input-group mb-2 has-validation position-relative">
                                                            <input
                                                                type="text"
                                                                name="username"
                                                                className="form-control input-line"
                                                                id="yourUsername"
                                                                value={username}
                                                                placeholder=""
                                                                onChange={handleUserNameChange}
                                                                onKeyDown={(e) => handleKeyDown(e, passwordRef)}
                                                                required
                                                            />
                                                            <label htmlFor="yourUsername" className={`form-label fontFamily fontsize16 fontweightMedium ${username ? 'active' : ''}`}>
                                                                <b>Email</b>
                                                            </label>
                                                        </div>
                                                        {errorUsername && <div className="text-danger mb-2 fontFamily fontsize16 fontweightMedium">{errorUsername}</div>}
                                                    </div>

                                                    <div className="col-12 position-relative">
                                                        <div className="position-relative">
                                                            <input
                                                                type={passwordVisible ? "text" : "password"}
                                                                name="password"
                                                                className="form-control input-line"
                                                                id="yourPassword"
                                                                value={password}
                                                                placeholder=""
                                                                onChange={handlePasswordChange}
                                                                ref={passwordRef}
                                                                required
                                                            />
                                                            <label htmlFor="yourPassword" className={`form-label mb-2 fontFamily fontsize16 fontweightMedium ${password ? 'active' : ''}`}>
                                                                <b>Password</b>
                                                            </label>
                                                            <FontAwesomeIcon
                                                                icon={passwordVisible ? faEye : faEyeSlash}
                                                                onClick={togglePasswordVisibility}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '50%',
                                                                    transform: 'translateY(-50%)',
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </div>
                                                        {errorPassword && <div className="text-danger fontFamily fontsize16 fontweightMedium">{errorPassword}</div>}
                                                    </div>

                                                    <div className="row Remember">
                                                        <div className="col-auto">
                                                            <div className="form-check">
                                                                <input className="form-check-input"
                                                                    type="checkbox"
                                                                    name="remember"
                                                                    id="rememberMe"
                                                                    value={rememberMe}
                                                                    checked={rememberMe}
                                                                    onChange={handleRememberMeChange}
                                                                />
                                                                <label className="form-check-label fontFamily fontsize16 fontweightMedium" htmlFor="rememberMe">Remember me</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="fontFamily fontsize14 fontweightMedium">
                                                                <Link to="/ForgotPassword">Forgot Password?</Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 d-flex justify-content-center custom-mt-8 custom-mb-8">
                                                        <button className="coloredButton custom-w-80 fontFamily fontsize24 fontweightSemiBold" type="submit">Log in</button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                        <div className='fontFamily fontweightRegular fontsize14 ashTextColor'>
                                            © 2024 iTech Software Group. All Rights Reserved.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default SignIn;
