import axios from 'axios';
import api from '../webconfig';
import { updateDocument } from './GeneralSettingsService';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

// Employee
export async function getEmployee() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getEmployeeManagement', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function createEmployee(emp) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateEmployeeManagement', emp, { headers: getAuthHeader() });
        const payload = {
            id:1,
            documentType:'EmployeeNo',
            documentNo: emp.NextID
        }
        await updateDocument(payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function updateEmployee(employee) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateEmployeeManagement', employee, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function deleteEmployee(employeeID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteEmployeeManagement?EmpID=${employeeID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}


// Attachments
export async function getAttachments() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getAttachments', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function createAttachments(attachments) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateAttachments', attachments, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function updateAttachments(attachments) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateAttachments', attachments, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function deleteAttachments(AttID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteAttachments?AttID=${AttID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}


// Designation
export async function getDesignation() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getDesignation', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function createDesignation(designation) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateDesignation', designation, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function updateDesignation(Designation) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateDesignation', Designation, { headers: getAuthHeader() });

        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}

export async function deleteDesignation(desID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteDesignation?AttID=${desID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Data:', error);
        throw error;
    }
}
