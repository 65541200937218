import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportUserData = (data, format) => {
    const headers = ["SI.No","User Name","Employee Name","Email Id","Role Name","status"];
    const rows = data.map(use => {
        return [
            use.Rowid,
            use.userName,
            use.EmployeeName,
            use.emailID,
            use.RoleName,
            use.UserAccesStatus
           
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Attachment_List_${formattedDate}.${fileExtension}`);
};
