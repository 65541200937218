import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const exportAssetData = (data, format) => {
    const headers = ["SI.No", "Asset ID", "Asset Name", "Brand", "Purchase Date", "Cost", "Status"];

    const rows = data.map(Asset => {
        return [
            Asset.Rowid || '',
            Asset.assetID || '',
            Asset.assetName || '',
            Asset.brandName || '',
            Asset.purchaseDate || '',
            Asset.cost || '',
            Asset.assetStatus || '',
        ];
    });

    let fileData;
    let fileType;
    let fileExtension;

    switch (format) {
        case 'csv': {
            const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
            fileData = csvContent;
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = 'csv';
            break;
        }
        case 'excel': {
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
            fileExtension = 'xlsx';
            break;
        }
        case 'pdf': {
            const doc = new jsPDF('landscape', 'mm', 'a4');
            doc.setFontSize(10);
            doc.autoTable({
                head: [headers],
                body: rows,
                styles: { overflow: 'linebreak', fontSize: 8 },
                margin: { top: 20 },
                tableWidth: 'auto'
            });
            fileData = doc.output('blob');
            fileType = 'application/pdf';
            fileExtension = 'pdf';
            break;
        }
        default:
            return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Asset_Data_${formattedDate}.${fileExtension}`);
};


export const downloadSampleExcelAsset = () => {
    const headers = [
        "Asset Tag ID", "Asset Name", "Purchased from", "Purchase Date", "Brand", "Cost",
        "Model", "Serial No", "Depreciable Asset", "Depreciable Cost", "Salvage Value",
        "Asset Life (months)", "Depreciation Method", "Date Acquired", "Category",
        "Sub Category", "Department", "Unit", "Branch", "Assigned to person"
    ];

    // Sample data for rows (optional)
    const rows = [
        ["AST000010", "HP Desktop", "Vendor1", "15/03/2017", "HP", "875.00", "Envy", "00180-10589", "True", "875.00", "50.00", "36", "Straight Line", "15/03/2017", "IT", "Computers", "Admin", "Unit1", "Branch1", "John Doe"]
    ];

    // Create the worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    // Write the workbook to an Excel file
    const fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;';
    const fileExtension = 'xlsx';

    // Create a formatted date string for the filename
    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');

    // Create a blob and trigger download
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Sample_Asset_Format_${formattedDate}.${fileExtension}`);
};