import { saveAs } from 'file-saver';
import 'jspdf-autotable';

export const exportEmployeeData = (data, format) => {
    const headers = ["SI.No","Employee Id","First Name", "Last Name", "Email", "Phone Number", "Emergency Contact Number", "Status"];
    const rows = data.map(Emp => {
        return [
            Emp.Rowid,
            Emp.employeeID,
            Emp.firstName,
            Emp.lastName,
            Emp.email,
            Emp.phoneNumber,
            Emp.emergencyContactNumber,
            Emp.EmployeeStatus
        ];
    });


    let fileData;
    let fileType;
    let fileExtension;

    if (format === 'csv') {
        const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
        fileData = csvContent;
        fileType = 'text/csv;charset=utf-8;';
        fileExtension = 'csv';
    } else {
        return;
    }

    const formattedDate = new Date().toISOString().replace(/T/, '_').replace(/:|\..+/, '');
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, `Employee_List_${formattedDate}.${fileExtension}`);
};
