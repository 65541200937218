import React from 'react';
import PropTypes from 'prop-types';

const CustomCheckBox = ({
    tab,
    item,
    option,
    roleData,
    handleCheckboxChange,
    isViewMode,
    selectAll,
    className,
}) => {
    const isChecked = () => {
        if (item === 'selectAll') {
            return selectAll;
        } else if (option === '') {
            // Screen level checkbox
            return Object.values(roleData?.[tab]?.[item] || {}).some((v) => v === true);
            // If roleData?.[tab]?.[item] is undefined or null, the empty object {} is used
        } else {
            return roleData?.[tab]?.[item]?.[option] === true;
            // Safely access roleData[tab][item][option] using optional chaining
        }
    };


    const onChange = () => {
        if (isViewMode) {
            return;
        }
        handleCheckboxChange(tab, item, option, item === 'selectAll' ? !selectAll : undefined);
    };

    return (
        <input
            type="checkbox"
            checked={isChecked()}
            onChange={onChange}
            className={className}
            disabled={isViewMode}
        />
    );
};

CustomCheckBox.propTypes = {
    tab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    option: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    roleData: PropTypes.object,
    handleCheckboxChange: PropTypes.func,
    isViewMode: PropTypes.bool,
    selectAll: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
};

export default CustomCheckBox;
