import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

// Category
export async function getCategory() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getAssetCategory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Category:', error);
        throw error;
    }
}

export async function createCategory(category) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateAssetCategory', category, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Category:', error);
        throw error;
    }
}

export async function updateCategory(category) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateAssetCategory', category, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update Category:', error);
        throw error;
    }
}

export async function deleteCategory(categoryID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteAssetCategory?catId=${categoryID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Category:', error);
        throw error;
    }
}


// Sub Category
export async function getSubCategory() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getSubCategory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching SubCategory:', error);
        throw error;
    }
}

export async function createSubCategory(subCategory) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateSubCategory', subCategory, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create SubCategory:', error);
        throw error;
    }
}

export async function updateSubCategory(SubCategory) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateSubCategory', SubCategory, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update SubCategory:', error);
        throw error;
    }
}

export async function deleteSubCategory(subCategoryID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteSubCategory?subCatId=${subCategoryID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete SubCategory:', error);
        throw error;
    }
}


// Brand
export async function getBrand() {
    try {
        const response = await axios.get(API_BASE_URL + 'Configuration/getBrand', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching getBrand:', error);
        throw error;
    }
}

export async function createBrand(Brand) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/CreateBrand', Brand, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Brand:', error);
        throw error;
    }
}

export async function updateBrand(Brand) {
    try {
        const response = await axios.post(API_BASE_URL + 'Configuration/UpdateBrand', Brand, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Brand:', error);
        throw error;
    }
}

export async function deleteBrand(BrandID) {
    try {
        const response = await axios.post(`${API_BASE_URL}Configuration/DeleteBrand?brandId=${BrandID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Brand:', error);
        throw error;
    }
}
