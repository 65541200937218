import axios from "axios";
import api from "../webconfig";
import { updateDocument } from './GeneralSettingsService';

const API_BASE_URL = api;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

// SupplierMaster
export async function getSupplier() {
  try {
    const response = await axios.get(
      API_BASE_URL + "SupplierMaster/GetSupplierMaster",
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching supplier:", error);
    throw error;
  }
}

export async function createSupplier(data) {
  try {
    const response = await axios.post(
      API_BASE_URL + "SupplierMaster/CreateSupplierMaster",
      data,
      { headers: getAuthHeader() }
    );
    const payload = {
      id: 2,
      documentType: "SupplierNo",
      documentNo: data.NextID,
    };
    await updateDocument(payload);
    return response.data;
  } catch (error) {
    console.error("Error creating supplier:", error);
    throw error;
  }
}

export async function updateSupplier(data) {
  try {
    const response = await axios.post(
      API_BASE_URL + "SupplierMaster/UpdateSupplierMaster",
      data,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating supplier:", error);
    throw error;
  }
}

export async function deleteSupplier(smId) {
  try {
    const response = await axios.post(
      `${API_BASE_URL}SupplierMaster/DeleteSupplierMaster?supplierMasterId=${smId}`,
      null,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting supplier:", error);
    throw error;
  }
}

// SupplierMaster Contact
export async function getSMContact() {
  try {
    const response = await axios.get(
      API_BASE_URL + "SupplierMasterContact/GetSupplierMasterContact",
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching supplier Contact:", error);
    throw error;
  }
}

export async function createSMContact(data) {
  try {
    const response = await axios.post(
      API_BASE_URL + "SupplierMasterContact/CreateSupplierMasterContact",
      data,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating supplier Contact:", error);
    throw error;
  }
}

export async function updateSMContact(data) {
  try {
    const response = await axios.post(
      API_BASE_URL + "SupplierMasterContact/UpdateSupplierMasterContact",
      data,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating supplier Contact:", error);
    throw error;
  }
}

export async function deleteSMContact(smId) {
  try {
    const response = await axios.post(
      `${API_BASE_URL}SupplierMasterContact/DeleteSupplierMasterContact?supplierContactId=${smId}`,
      null,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting supplier Contact:", error);
    throw error;
  }
}

// SupplierMaster Purchase
export async function getSMPurchase() {
  try {
    const response = await axios.get(
      API_BASE_URL + "SupplierMasterPurchase/GetSupplierMasterPurchase",
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching supplier Purchase:", error);
    throw error;
  }
}

export async function createSMPurchase(data) {
  try {
    const response = await axios.post(
      API_BASE_URL + "SupplierMasterPurchase/CreateSupplierMasterPurchase",
      data,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating supplier Purchase:", error);
    throw error;
  }
}

export async function updateSMPurchase(data) {
  try {
    const response = await axios.post(
      API_BASE_URL + "SupplierMasterPurchase/UpdateSupplierMasterPurchase",
      data,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating supplier Purchase:", error);
    throw error;
  }
}

export async function deleteSMPurchase(smId) {
  try {
    const response = await axios.post(
      `${API_BASE_URL}SupplierMasterPurchase/DeleteSupplierMasterPurchase?supplierPurchaseId=${smId}`,
      null,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting supplier Purchase:", error);
    throw error;
  }
}
