import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

// UserAccessPermission
export async function getUserAccessPermission() {
    try {
        const response = await axios.get(API_BASE_URL + 'UserAccessPermission/GetUserAccessPermission', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching UserAccessPermission:', error);
        throw error;
    }
}

export async function createUserAccessPermission(userAccess) {
    try {
        const response = await axios.post(API_BASE_URL + 'UserAccessPermission/CreateUserAccessPermission', userAccess, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create UserAccessPermission:', error);
        throw error;
    }
}

export async function updateUserAccessPermission(userAccess) {
    try {
        const response = await axios.post(API_BASE_URL + 'UserAccessPermission/UpdateUserAccessPermission', userAccess, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update UserAccessPermission:', error);
        throw error;
    }
}

export async function deleteUserAccessPermission(userAccessID) {
    try {
        const response = await axios.post(`${API_BASE_URL}UserAccessPermission/DeleteUserAccessPermission?userAccessId=${userAccessID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete UserAccessPermission:', error);
        throw error;
    }
}
