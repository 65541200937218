import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from 'react-select';
import { format, set } from 'date-fns';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';

import { getUnitID, getBranchID, getDepartmentID, getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";
import { getAssetLink } from "../../../Services/AssetSettingsService";
import { getDocument } from "../../../Services/GeneralSettingsService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";
import {
    getAsset,
    getAssetStatus,
    deleteAuditHD,
    createAuditHD,
    updateAuditHD,
    getAuditHD,
    getAuditLN,
    getAuditHistory,
    createAuditHistory,
} from "../../../Services/AssetSettingsService";

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import nodataFound from "../../../assets/img/nodatafound.png";
import CalendarWithBG from "../../../assets/icon/CalendarWithBG.svg";
import Swal from 'sweetalert2';

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        marginBottom: '10px',
        position: 'absolute'
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '275px'
    // }),
};
const auditStatusDropdown = [
    { id: 1, idValue: 'Scheduled' },
    { id: 2, idValue: 'Audit Inprogress' },
    { id: 3, idValue: 'Audit Completed' },
]
const auditResultDropdown = [
    { id: 1, idValue: 'Not Started' },
    { id: 2, idValue: 'Pending' },
    { id: 3, idValue: 'Completed' },
]
const auditAssetMatchesDropdown = [
    { id: 1, idValue: 'Yes' },
    { id: 0, idValue: 'No' },
]
const auditDescripencyTypeDropdown = [
    { id: 1, idValue: 'Condition Mismatch' },
    { id: 2, idValue: 'Duplicate Serial Number' },
    { id: 3, idValue: 'Excess Asset' },
    { id: 4, idValue: 'Incorrect Serial Number' },
    { id: 5, idValue: 'Location Mismatch' },
    { id: 6, idValue: 'Missing' },
    { id: 7, idValue: 'Missing or Damaged Asset Tags' },
    { id: 8, idValue: 'No Discrepancy' },
]

const AuditList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");
    const [pageAudit, setPageAudit] = useState(0);
    const [rowsPerPageAudit, setRowsPerPageAudit] = useState(5);
    const [searchQueryAudit, setSearchQueryAudit] = useState("");
    const [dateFormat, setDateFormat] = useState("");

    const [auditID, setAuditID] = useState('');
    const [auditDocID, setAuditDocID] = useState(0);
    const [auditArrayHD, setAuditArrayHD] = useState([]);
    const [auditArrayHDfilter, setAuditArrayHDfilter] = useState([]);
    const [auditArrayLN, setAuditArrayLN] = useState([]);
    const [auditArrayLNfilter, setAuditArrayLNfilter] = useState([]);
    const [assetArray, setAssetArray] = useState([]);
    const [assetArrayfilter, setAssetArrayfilter] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);
    const [assetLinkArray, setAssetLinkArray] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [selectAll, setSelectAll] = useState(false);


    const [selectAssetData, setSelectAssetData] = useState([]);
    const [IsAddtoList, setIsAddtoList] = useState(false);
    const [IsAuditView, setIsAuditView] = useState(false);
    //need to set this as false for list page
    const [IsAuditCreate, setIsAuditCreate] = useState(false);
    const [IsAuditUpdate, setIsAuditUpdate] = useState(false);
    const [selectedAssetsPending, setSelectedAssetsPending] = useState([]);
    const [selectAllAssetPending, setSelectAllAssetPending] = useState(false);

    const [isAudit, setisAudit] = useState(false);
    const [addAssetList, setAddAssetList] = useState([]);
    const [selectedAssetAdd, setSelectedAssetAdd] = useState([]);
    const [selectAllAssetAdd, setSelectAllAssetAdd] = useState(false);
    const [IsAssetMatches, setIsAssetMatches] = useState(false);
    const [isAuditSelectedId, setIsAuditSelectedId] = useState(0);
    const [completedOrPendingText, setCompletedOrPendingText] = useState('Audit Pending');
    const [completedOrPendingCount, setCompletedOrPendingCount] = useState(0);
    const [auditHistory, setAuditHistory] = useState([]);
    const [filterAuditHistory, setFilterAuditHistory] = useState([]);

    let localAssetDate = new Date();
    let offset = localAssetDate.getTimezoneOffset(); // Offset in minutes
    // Adjust for local time
    localAssetDate.setMinutes(localAssetDate.getMinutes() - offset);
    // Convert to ISO format without the 'Z'
    let localISODate = localAssetDate.toISOString().slice(0, -1);


    //Asset
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        defaultValues: {
            categoryId: 0,
            subCategoryId: 0,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
            empId: 0,
        },
    });

    //Audit
    const {
        register: registerAudit,
        handleSubmit: handleSubmitAudit,
        formState: { errors: errorsAudit },
        control: controlAudit,
        reset: resetAudit,
        setValue: setValueAudit,
        watch: watchAudit
    } = useForm({
        defaultValues: {
            auditName: '',
            auditStatus: 1,
            auditStartDate: null,
            auditEndDate: null,
            auditNote: null,
            auditLock: false
        },
    });

    // Watch the auditLock value
    const auditLock = watchAudit("auditLock");

    //Update Audit
    const {
        register: registerAuditUpdate,
        handleSubmit: handleSubmitAuditUpdate,
        formState: { errors: errorsAuditUpdate },
        control: controlAuditUpdate,
        reset: resetAuditUpdate,
        setValue: setValueAuditUpdate,
        watch: watchAuditUpdate
    } = useForm({
        defaultValues: {
            auditName: '',
            auditNote: '',
            auditLock: false,
            auditStartDate: null,
            auditEndDate: null,
            auditCreatedAt: null,
            auditUpdatedAt: null,
            auditCreatedBy: '',
            auditUpdatedBy: '',
            auditResult: 1,
            auditDescripency: 8,
            auditAssetMatches: 1,
            auditUnit: 0,
            auditBranch: 0,
            auditDepartment: 0,
            auditAssignee: 0,
            auditStatus: 1,
            completedToggle: false
        },
    });

    // Watch the auditLock value On Update
    const auditLockOnUpdate = watchAuditUpdate("auditLock");

    // Define dataInitialization as a regular async function
    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                fetchAuditData(),
                fetchAssetAssociation(),
                fetchAllData(),
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const user = localStorage.getItem('UserName');
        setUserName(user);
        getAuditDocNo();
        dataInitialization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAuditData = async () => {
        const dateCurrentFormat = await getDateFormat();
        const auditHistoryData = await getAuditHistory();
        setAuditHistory(auditHistoryData);

        const auditDataHD = await getAuditHD();
        const finalDataHD = auditDataHD?.map((item, index) => {
            const FormattedStartDate = item.auditStartDate ? format(new Date(item?.auditStartDate), dateCurrentFormat) : null;
            const FormattedEndDate = item.auditEndDate ? format(new Date(item?.auditEndDate), dateCurrentFormat) : null;
            const FormattedCreatedAt = item.auditCreatedAt ? format(new Date(item?.auditCreatedAt), dateCurrentFormat) : null;
            const FormattedUpdatedAt = item.auditUpdatedAt ? format(new Date(item?.auditUpdatedAt), dateCurrentFormat) : null;
            const auditStatus = auditStatusDropdown.find(status => status.id === item?.auditStatus);
            const lockOrUnlock = item?.auditLock ? "Lock" : "Unlock";

            return {
                ...item,
                Rowid: index + 1,
                auditNameAndID: `${item?.auditName} - ${item?.auditID}`,
                Status: auditStatus?.idValue,
                LockOrUnlock: lockOrUnlock,
                AuditCreatedDate: FormattedCreatedAt,
                AuditUpdatedDate: FormattedUpdatedAt,
                AuditDuration: `${FormattedStartDate}${FormattedEndDate ? ' - ' + FormattedEndDate : ''}`,
            };
        });
        setAuditArrayHD(finalDataHD);
        setAuditArrayHDfilter(finalDataHD);
    };


    const fetchAllData = async () => {
        try {
            // Define colors
            const grayTextColor = "#888787";
            const parentTextColor = "#2ED47A";
            const childTextColor = "#605BFF";

            // Fetch and format asset data
            const dateCurrentFormat = await getDateFormat();
            setDateFormat(dateCurrentFormat);
            const assetData = await getAsset();
            const auditDataLN = await getAuditLN();
            const assetStatus = await getAssetStatus();
            const assetCategory = await getCategory();
            const assetSubCategory = await getSubCategory();
            const assetBrand = await getBrand();
            const assetUnit = await getBUData();
            const assetBranch = await getBRData();
            const departmentData = await getDepartment();
            const empData = await getEmployee();
            const finalEmployeeData = empData.map((Emp) => ({
                ...Emp,
                EmployeeName: `${Emp?.firstName} ${Emp?.lastName}`
            }));
            const linkData = await getAssetLink();
            setAssetLinkArray(linkData);
            const findDropdownValue = (id, dropdown) => dropdown.find(item => item.id === id)?.idValue || '';

            // Map and format the asset data with relationship and color styling
            const finalData = assetData?.length > 0 ? assetData.map((asset, index) => {
                const categoryAsset = assetCategory?.find(x => x.id === asset?.categoryId);
                const subcategoryAsset = assetSubCategory?.find(x => x.id === asset?.subCategoryId);
                const brandAsset = assetBrand?.find(x => x.id === asset?.brandId);
                const branchAsset = assetBranch?.find(x => x.bR_ID === asset?.branchId);
                const unitAsset = assetUnit?.find(x => x.bU_ID === asset?.unitId);
                const formattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const FilterformattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), 'yyyy-MM-dd') : null;
                const formattedDateAcquired = asset.dateAcquired ? format(new Date(asset.dateAcquired), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);
                const employeeAsset = finalEmployeeData?.find(x => x.id === asset?.empId);
                const matchedAuditLN = auditDataLN?.find(item => item.assetId === asset.id);

                // Determine if the asset is a Parent or Child based on linkData
                let relation = "None";
                let textColor = grayTextColor;
                let linkedAssetId = null; // To store the related asset's ID
                let linkedAssetRelationId = null; // To store the related asset's link ID
                let linkedAssetColor = grayTextColor; // Default color for related asset

                // Check the linkData to determine relationships and assign colors
                const link = linkData?.find(link => link.assetRelationId === asset.id || link.assetId === asset.id);

                if (link) {
                    if (link.relation === "Parent") {
                        // If the asset is a Parent, the linked asset is the Child
                        relation = "Parent";
                        textColor = childTextColor;
                        linkedAssetId = link.assetId;             // Linked as a Child
                        linkedAssetRelationId = link.assetRelationId;
                        linkedAssetColor = parentTextColor;        // Child color
                    }
                    if (link.relation === "Child") {
                        // If the asset is a Child, the linked asset is the Parent
                        relation = "Child";
                        textColor = parentTextColor;
                        linkedAssetId = link.assetId;     // Linked as a Parent
                        linkedAssetRelationId = link.assetRelationId;
                        linkedAssetColor = childTextColor;       // Parent color
                    }
                }

                return {
                    ...asset,
                    Rowid: index + 1,
                    categoryName: categoryAsset ? categoryAsset?.categoryName : '',
                    subcategoryName: subcategoryAsset ? subcategoryAsset?.subCategoryName : '',
                    brandName: brandAsset ? brandAsset?.brandName : '',
                    branchName: branchAsset ? branchAsset?.bR_NAME : '',
                    unitName: unitAsset ? unitAsset?.bU_NAME : '',
                    formatedPurchaseDate: formattedDate,
                    filterPurchaseDate: FilterformattedDate,
                    formattedDateAcquired: formattedDateAcquired,
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue,
                    employeeName: employeeAsset ? employeeAsset?.EmployeeName : '',
                    auditResult: findDropdownValue(matchedAuditLN?.auditResult, auditResultDropdown),
                    descripencyType: findDropdownValue(matchedAuditLN?.descriptanceyType, auditDescripencyTypeDropdown),
                    relation,              // Relationship type (Parent/Child/None)
                    textColor,             // Color based on the asset's relationship
                    linkedAssetId,         // ID of the related asset (Child if Parent, Parent if Child)
                    linkedAssetRelationId, // ID of the relationship between the assets
                    linkedAssetColor       // Color for the related asset based on its relationship
                };
            }) : [];

            // Set the final asset array with relation-based styling and linked asset information
            setAssetArray(finalData);
            setAuditArrayLN(auditDataLN);
            setAuditArrayLNfilter(auditDataLN);
            setCategoryArray(assetCategory);
            setSubCategoryArray(assetSubCategory);
            setDepartmentArray(departmentData);
            const sortedUnitData = assetUnit.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
            setUnitArray(sortedUnitData);
            const sortedBranchData = assetBranch.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
            setBranchArray(sortedBranchData);
            const sortedEmployeeData = finalEmployeeData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
            setEmployeeArray(sortedEmployeeData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        }
    };

    const getAuditDocNo = async () => {
        try {
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 10);
            const newId = maxId.documentNo + 1;
            setAuditDocID(newId);

            const assetAuditPrefix = 'AUD';
            setAuditID(`${assetAuditPrefix.toUpperCase()}${newId}`);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const fetchAssetAssociation = async () => {
        const unitId = await getUnitID();
        setValueAudit("unitId", unitId);

        const branchId = await getBranchID();
        setValueAudit("branchId", branchId);

        const departmentId = await getDepartmentID();
        setValueAudit("departmentId", departmentId);
    };

    const handleSearchChange = (inputFieldsValue) => {
        if (inputFieldsValue.date == null && inputFieldsValue.unitId == 0 && inputFieldsValue.branchId == 0 && inputFieldsValue.categoryId == 0 && inputFieldsValue.subCategoryId == 0 && inputFieldsValue.departmentId == 0 && inputFieldsValue.empId == 0 && inputFieldsValue.status == 0) {
            toast.error("Please select a field to search Asset.")
        } else {
            let inputDate = null;
            let formattedInputDate = null;
            if (inputFieldsValue.date != null) {
                inputDate = new Date(inputFieldsValue?.date)
                let date = inputDate.getDate();
                let fullDate = date < 10 ? '0' + date : date;
                let month = inputDate.getMonth() + 1;
                let fullMonth = month < 10 ? '0' + month : month
                let year = inputDate.getFullYear();
                formattedInputDate = "" + year + "-" + fullMonth + "-" + fullDate + ""
            }
            let filterData = assetArray.filter(record => {
                return (
                    (formattedInputDate === null || record.filterPurchaseDate === formattedInputDate) &&
                    (inputFieldsValue.unitId === 0 || inputFieldsValue.unitId === null || record.unitId === inputFieldsValue.unitId) &&
                    (inputFieldsValue.branchId === 0 || inputFieldsValue.branchId === null || record.branchId === inputFieldsValue.branchId) &&
                    (inputFieldsValue.categoryId === 0 || inputFieldsValue.categoryId === null || record.categoryId === inputFieldsValue.categoryId) &&
                    (inputFieldsValue.subCategoryId === 0 || inputFieldsValue.subCategoryId === null || record.subCategoryId === inputFieldsValue.subCategoryId) &&
                    (inputFieldsValue.departmentId === 0 || inputFieldsValue.departmentId === null || record.departmentId === inputFieldsValue.departmentId) &&
                    (inputFieldsValue.empId === 0 || inputFieldsValue.empId === null || record.empId === inputFieldsValue.empId)
                );
            });
            setAssetArrayfilter(filterData);
        }
    };

    const handleSearchClear = () => {
        reset();
        setSelectedAssets([]);
        setSelectAll(false)
        setAssetArrayfilter([]);
    };


    const handleSearchChangeAsset = (event) => {
        setSearchQueryAsset(event.target.value);
        const idArray = selectAssetData.map(item => item.id);
        const selectedData = assetArray.filter(asset => !idArray.includes(asset.id));
        const searchTextInput = event.target.value;
        const filteredSelectedAsset = searchTextInput != '' ? selectedData?.filter((asset) =>
            asset?.assetID?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.assetName?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.assetStatusValue?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.employeeName?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.branchName?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.auditResult?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.descripencyType?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            asset?.unitName?.toLowerCase()?.includes(searchTextInput?.toLowerCase())
        ) : selectedData;
        setAddAssetList(filteredSelectedAsset);
    };

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(assetArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = assetArrayfilter?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedAssets([]);
        } else {
            setSelectedAssets(assetArrayfilter?.map((asset) => asset.id));
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (assetId) => {
        // Find all matching links with the specified assetId
        const currentLinks = assetLinkArray?.filter(e => e.assetId === assetId || e.assetRelationId === assetId);

        setSelectedAssets((prevSelected) => {
            let newSelectedAssets;

            if (prevSelected.includes(assetId)) {
                // If assetId is currently selected, we want to remove it and all linked assetRelationIds
                newSelectedAssets = prevSelected.filter((selectedId) =>
                    selectedId !== assetId &&
                    !currentLinks.some(link => link.assetRelationId === selectedId || link.assetId === selectedId)
                );
            } else {
                // If assetId is not selected, add it and any linked assetRelationIds
                newSelectedAssets = [...prevSelected, assetId];
                if (currentLinks?.length) {
                    currentLinks.forEach(link => {
                        if (link.assetRelationId && !newSelectedAssets.includes(link.assetRelationId)) {
                            newSelectedAssets.push(link.assetRelationId);
                        }
                        if (link.assetId && !newSelectedAssets.includes(link.assetId)) {
                            newSelectedAssets.push(link.assetId);
                        }
                    });
                }
            }

            // Handle the select all checkbox state
            if (newSelectedAssets.length !== assetArrayfilter.length) {
                setSelectAll(false);
            } else if (newSelectedAssets.length === assetArrayfilter.length) {
                setSelectAll(true);
            }

            return newSelectedAssets;
        });
    };

    const addToList = () => {
        if (selectedAssets.length > 0) {
            const selectedData = assetArray.filter(asset =>
                selectedAssets.includes(asset.id)
            );
            setSelectAssetData(selectedData);
            setSelectAllAssetPending(false);
            setSelectedAssetsPending([]);
            setIsAddtoList(!IsAddtoList);
        } else {
            toast.error("Please select the asset to Add.");
        }
    };

    const removeAsset = async () => {
        const result = await Swal.fire({
            title: 'Are you sure do you want to Remove the Asset ?',
            text: ``,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it',
            cancelButtonText: 'No, keep it'
        });

        if (result.isConfirmed) {
            if (selectedAssetsPending.length !== selectAssetData.length) {
                if (selectedAssetsPending.length > 0) {
                    const selectedData = selectAssetData.filter(asset =>
                        !selectedAssetsPending.includes(asset.id)
                    );
                    setSelectAssetData(selectedData);
                    setSelectAllAssetPending(false);
                    setSelectedAssetsPending([]);
                } else {
                    toast.error("Please select the asset to Remove.");
                }
            } else {
                toast.warning("Asset Data must be at least 1.");
            }
        }
      
    };

    const handleSelectAllAssetPendingChange = () => {
        if (selectAllAssetPending) {
            setSelectedAssetsPending([]);
        } else {
            setSelectedAssetsPending(selectAssetData?.map((asset) => asset.id));
        }
        setSelectAllAssetPending(!selectAllAssetPending);
    };

    const handleCheckboxChangeAssetPending = (assetId) => {
        // Find all matching links with the specified assetId
        const currentLinks = assetLinkArray?.filter(e => e.assetId === assetId || e.assetRelationId === assetId);

        setSelectedAssetsPending((prevSelected) => {
            let newSelectedAssets;

            if (prevSelected.includes(assetId)) {
                // If assetId is currently selected, remove it and all linked assetRelationIds
                newSelectedAssets = prevSelected.filter((selectedId) =>
                    selectedId !== assetId &&
                    !currentLinks.some(link => link.assetRelationId === selectedId || link.assetId === selectedId)
                );
            } else {
                // If assetId is not selected, add it and any linked assetRelationIds
                newSelectedAssets = [...prevSelected, assetId];
                if (currentLinks?.length) {
                    currentLinks.forEach(link => {
                        if (link.assetRelationId && !newSelectedAssets.includes(link.assetRelationId)) {
                            newSelectedAssets.push(link.assetRelationId);
                        }
                        if (link.assetId && !newSelectedAssets.includes(link.assetId)) {
                            newSelectedAssets.push(link.assetId);
                        }
                    });
                }
            }

            // Handle the select all checkbox state
            if (newSelectedAssets.length !== selectAssetData.length) {
                setSelectAllAssetPending(false);
            } else if (newSelectedAssets.length === selectAssetData.length) {
                setSelectAllAssetPending(true);
            }

            return newSelectedAssets;
        });
    };


    const handleCheckboxChangeAssetPendingUpdate = (assetId) => {
        setSelectedAssetsPending((prevSelected) => {
            const newSelectedAssets = prevSelected.includes(assetId)
                ? prevSelected.filter((selectedId) => selectedId !== assetId)
                : [...prevSelected, assetId];

            if (newSelectedAssets.length !== selectAssetData.length) {
                setSelectAllAssetPending(false);
            } else if (newSelectedAssets.length === selectAssetData.length) {
                setSelectAllAssetPending(true);
            }
            return newSelectedAssets;
        });
    };

    const onSubmitAudit = async (data) => {
        try {
            setIsLoading(true);
            const auditListString = JSON.stringify(selectAssetData);
            const payload = {
                id: 0,
                auditName: data?.auditName,
                auditID: auditID,
                auditNote: data?.auditNote,
                auditStartDate: data?.auditStartDate,
                auditEndDate: data?.auditEndDate,
                auditLock: data?.auditLock,
                auditAssetList: auditListString,
                auditStatus: data?.auditStatus,
                auditCreatedAt: localISODate,
                auditCreatedBy: userName,
                NextID: auditDocID
            };
            const response = await createAuditHD(payload);
            if (response === "Inserted") {
                toast.success("Audit Data Created successfully");
                fetchAuditData();
                // const matchingAsset = assetArray.find(asset => asset.id === assetID);
                // const historyPayload = {
                //     id: 0,
                //     assetId: assetID || 0,
                //     assetDate: localISODate,
                //     assetEvent: 'Asset Audit',
                //     assetField: "Status",
                //     assetChangedFrom: matchingAsset?.assetStatusValue,
                //     assetChangedTo: "Asset Audit",
                //     assetActionBy: userName || '',
                // };
                // await CreateAssetHistory(historyPayload);
                setIsLoading(false);
                onClose();
            } else {
                toast.error(response);
                setIsLoading(false);
            }
            // setTimeout(() => {
            //     resetAudit();
            //     window.location.reload();
            // }, 2000);
        } catch {
            console.log('error occur when saving audit data.');
            setIsLoading(false);
        }
    };

    const onClose = () => {
        reset();
        resetAudit();
        resetAuditUpdate();
        setIsAuditCreate(false);
        setIsAuditUpdate(false);
        setIsAddtoList(false);
        setSelectAssetData([]);
        setSelectedAssetAdd([]);
        setSelectAllAssetAdd(false);
        setSelectedAssetsPending([]);
        setSelectAllAssetPending(false);
        setSelectedAssets([]);
        setSelectAll(false)
        setAssetArrayfilter([]);
        // window.location.reload();
    };

    const onSelectAsset = () => {
        if (selectAssetData.length > 0) {
            const idArray = selectAssetData.map(item => item.id);
            const selectedData = assetArray.filter(asset => !idArray.includes(asset.id));
            setSearchQueryAsset('');
            setSelectAllAssetAdd(false);
            setSelectedAssetAdd([]);
            setAddAssetList(selectedData);
            setisAudit(true);
        } else {
            toast.error("Please select the asset to Add.");
        }
    };

    const handleSelectAllAddAssetChange = () => {
        if (selectAllAssetAdd) {
            setSelectedAssetAdd([]);
        } else {
            setSelectedAssetAdd(addAssetList?.map((asset) => asset.id));
        }
        setSelectAllAssetAdd(!selectAllAssetAdd);
    };

    const handleCheckboxChangeAddAsset = (assetId) => {
        // Find all matching links with the specified assetId
        const currentLinks = assetLinkArray?.filter(e => e.assetId === assetId || e.assetRelationId === assetId);

        setSelectedAssetAdd((prevSelected) => {
            let newSelectedAssets;

            if (prevSelected.includes(assetId)) {
                // If assetId is currently selected, remove it and all linked assetRelationIds
                newSelectedAssets = prevSelected.filter((selectedId) =>
                    selectedId !== assetId &&
                    !currentLinks.some(link => link.assetRelationId === selectedId || link.assetId === selectedId)
                );
            } else {
                // If assetId is not selected, add it and any linked assetRelationIds
                newSelectedAssets = [...prevSelected, assetId];
                if (currentLinks?.length) {
                    currentLinks.forEach(link => {
                        if (link.assetRelationId && !newSelectedAssets.includes(link.assetRelationId)) {
                            newSelectedAssets.push(link.assetRelationId);
                        }
                        if (link.assetId && !newSelectedAssets.includes(link.assetId)) {
                            newSelectedAssets.push(link.assetId);
                        }
                    });
                }
            }

            // Handle the select all checkbox state
            if (newSelectedAssets.length !== addAssetList.length) {
                setSelectAllAssetAdd(false);
            } else if (newSelectedAssets.length === addAssetList.length) {
                setSelectAllAssetAdd(true);
            }

            return newSelectedAssets;
        });
    };

    const addAssetPopUp = () => {
        if (selectedAssetAdd.length > 0) {
            const selectedData = assetArray.filter(asset =>
                selectedAssetAdd.includes(asset.id)
            );
            const updatedSelectAssetData = [...selectAssetData, ...selectedData];
            setSelectAssetData(updatedSelectAssetData);
            setSelectAllAssetAdd(false);
            setSelectedAssetAdd([]);
            setisAudit(false);
        } else {
            toast.error("Please select the asset to Add.");
        }
    };

    const handleClose = () => {
        setisAudit(false)
    };

    const handleAssetMatchesChange = (e) => {
        if (e.idValue == 'No') {
            setIsAssetMatches(true);
            setValueAuditUpdate("auditUnit", 0);
            setValueAuditUpdate("auditBranch", 0);
            setValueAuditUpdate("auditDepartment", 0);
        } else {
            setIsAssetMatches(false);
        }
    };

    const findEmployeeNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.EmployeeName) : '';
    };

    const findUnitNameById = (id, array) => {
        const record = array?.find(item => item.bU_ID === id);
        return record ? String(record.bU_NAME) : '';
    };

    const findBranchNameById = (id, array) => {
        const record = array?.find(item => item.bR_ID === id);
        return record ? String(record.bR_NAME) : '';
    };

    const findDepartmentNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.departmentName) : '';
    };

    const onSubmitUpdateAudit = async (data) => {
        try {
            setIsLoading(true);
            const selectedData = assetArray.filter(asset => selectedAssetsPending.includes(asset.id));
            const auditListString = JSON.stringify(selectedData);

            const payload = {
                id: isAuditSelectedId,
                auditName: data?.auditName,
                auditID: data?.auditID,
                auditNote: data?.auditNote,
                auditStartDate: data?.auditStartDate,
                auditEndDate: data?.auditEndDate,
                auditLock: data?.auditLock,
                auditAssetList: auditListString,
                auditResult: data?.auditResult,
                assetMatch: data?.auditAssetMatches,
                descriptanceyType: data?.auditDescripency,
                actualUnit: data?.auditUnit,
                actualBranch: data?.auditBranch,
                actualDepartment: data?.auditDepartment,
                actualAssingnee: data?.auditAssignee,
                auditStatus: data?.auditStatus,
                auditCreatedAt: data?.auditCreatedAt,
                auditCreatedBy: data?.auditCreatedBy,
                auditUpdatedAt: localISODate,
                auditUpdatedBy: userName,
            };

            // Fetch current audit details
            const selectedAudit = auditArrayHD.find(audit => audit.id === isAuditSelectedId);
            let historyMessage = [];

            // Define a helper function to add changes with `changedFrom` and `changedTo`
            const addChangeMessage = (field, oldValue, newValue) => {
                if (oldValue !== newValue) {
                    historyMessage.push(`${field} changed from "${oldValue}" to "${newValue}"`);
                }
            };

            // Check and record changes for each relevant field
            addChangeMessage("Audit Name", selectedAudit?.auditName, data?.auditName);
            addChangeMessage("Audit Note", selectedAudit?.auditNote, data?.auditNote);

            // Handle dropdown fields by finding corresponding text
            const findDropdownValue = (id, dropdown) => dropdown.find(item => item.id === id)?.idValue || '';

            addChangeMessage("Audit Status", findDropdownValue(selectedAudit?.auditStatus, auditStatusDropdown), findDropdownValue(data?.auditStatus, auditStatusDropdown));
            addChangeMessage("Audit Result", findDropdownValue(selectedAudit?.auditResult, auditResultDropdown), findDropdownValue(data?.auditResult, auditResultDropdown));
            addChangeMessage("Asset Match", findDropdownValue(selectedAudit?.assetMatch, auditAssetMatchesDropdown), findDropdownValue(data?.auditAssetMatches, auditAssetMatchesDropdown));
            addChangeMessage("Discrepancy Type", findDropdownValue(selectedAudit?.descriptanceyType, auditDescripencyTypeDropdown), findDropdownValue(data?.auditDescripency, auditDescripencyTypeDropdown));

            // Handle relational fields with `findXNameById` functions
            addChangeMessage("Unit", findUnitNameById(selectedAudit?.actualUnit, unitArray), findUnitNameById(data?.auditUnit, unitArray));
            addChangeMessage("Branch", findBranchNameById(selectedAudit?.actualBranch, branchArray), findBranchNameById(data?.auditBranch, branchArray));
            addChangeMessage("Department", findDepartmentNameById(selectedAudit?.actualDepartment, departmentArray), findDepartmentNameById(data?.auditDepartment, departmentArray));
            addChangeMessage("Assignee", findEmployeeNameById(selectedAudit?.actualAssingnee, employeeArray), findEmployeeNameById(data?.auditAssignee, employeeArray));

            // Utility function to format and compare dates
            const formatAndCompareDates = (fieldName, oldDate, newDate) => {
                // Check if both dates exist
                if (!oldDate && !newDate) return; // Both dates don't exist, so no change

                // Format dates to a consistent ISO format for accurate comparison
                const formattedOldDate = oldDate ? new Date(oldDate).toISOString().split("T")[0] : null;
                const formattedNewDate = newDate ? new Date(newDate).toISOString().split("T")[0] : null;

                // Compare the formatted dates; if different, call addChangeMessage
                if (formattedOldDate !== formattedNewDate) {
                    addChangeMessage(fieldName, formattedOldDate, formattedNewDate);
                }
            };

            // Use the utility function to compare start and end dates
            formatAndCompareDates("Start Date", selectedAudit?.auditStartDate, data?.auditStartDate);
            formatAndCompareDates("End Date", selectedAudit?.auditEndDate, data?.auditEndDate);


            // Compile history message
            let historyMessageString = '';
            if (historyMessage.length > 0) {
                historyMessageString = historyMessage.join('; ') + '.';
            }

            const historyPayload = {
                id: 0,
                auditId: isAuditSelectedId,
                message: historyMessageString,
                createdById: parseInt(localStorage.getItem('UserEmpID'), 10),
                createdDate: localISODate,
            };

            // Submit payload
            const response = await updateAuditHD(payload);
            if (response === "Updated") {
                if (historyMessage.length > 0) {
                    await createAuditHistory(historyPayload);
                }
                toast.success("Audit Data Updated successfully");
                fetchAuditData();
                setIsLoading(false);
                onClose();
            } else {
                toast.error(response);
                setIsLoading(false);
            }
        } catch {
            console.log('Error occurred when updating audit data.');
            setIsLoading(false);
        }
    };

    const handleViewOrEditAudit = (Audit, viewStatus) => {
        setIsAuditView(Audit.auditLock || viewStatus);
        setIsAuditSelectedId(Audit?.id);

        // Check if all items related to the given Audit have auditResult === 3
        const allResultsAreThree = auditArrayLN
            ?.filter((item) => item.auditHdId === Audit?.id)
            .every((item) => item.auditResult === 3);

        // Conditionally filter: if not all are 3, filter out items where auditResult !== 3
        const filteredAuditLN = auditArrayLN?.filter((item) =>
            item.auditHdId === Audit?.id && (allResultsAreThree || item.auditResult !== 3)
        );

        setAuditArrayLNfilter(filteredAuditLN);

        // Extract asset IDs from filteredAuditLN
        const filteredAssetIds = filteredAuditLN.map(item => item.assetId);

        // Filter assetArray based on matching IDs
        const selectedData = assetArray.filter(asset =>
            filteredAssetIds.includes(asset.id)
        );

        setSelectAssetData(selectedData);

        // Populate audit form fields
        setValueAuditUpdate('auditName', Audit?.auditName);
        setValueAuditUpdate('auditID', Audit?.auditID);
        setValueAuditUpdate('auditNote', Audit?.auditNote);
        setValueAuditUpdate('auditStartDate', Audit?.auditStartDate?.split('T')[0]);
        setValueAuditUpdate('auditEndDate', Audit?.auditEndDate?.split('T')[0]);
        setValueAuditUpdate('auditLock', Audit.auditLock);
        setValueAuditUpdate('auditResult', allResultsAreThree ? 3 : Audit?.auditResult);
        setValueAuditUpdate('auditAssetMatches', Audit?.assetMatch);
        setValueAuditUpdate('auditDescripency', Audit?.descriptanceyType);
        setValueAuditUpdate('auditUnit', Audit?.actualUnit);
        setValueAuditUpdate('auditBranch', Audit?.actualBranch);
        setValueAuditUpdate('auditDepartment', Audit?.actualDepartment);
        setValueAuditUpdate('auditAssignee', Audit?.actualAssingnee);
        setValueAuditUpdate('auditStatus', Audit?.auditStatus);
        setValueAuditUpdate('auditCreatedAt', Audit?.auditCreatedAt);
        setValueAuditUpdate('auditCreatedBy', Audit?.auditCreatedBy);

        // Filter audit history based on selected audit
        const filterAuditHstory = auditHistory?.filter((item) => item.auditId === Audit?.id);
        const finalAuditHistory = filterAuditHstory?.map((item) => {
            const empData = employeeArray?.find((x) => x.id == item.createdById)

            return {
                ...item,
                historyMessage: item.message,
                empName: empData?.EmployeeName,
                empCodeId: empData?.employeeID,
                modifiedCreatedDate: item.createdDate.split('T')[0],
            }
        });
        setFilterAuditHistory(finalAuditHistory);

        setIsAuditCreate(false);
        setIsAuditUpdate(true);
    };

    const filterAssetsByAuditStatus = (showCompleted) => {
        // Check if all items related to `isAuditSelectedId` have `auditResult === 3`
        const auditRelatedItems = auditArrayLN?.filter(item => item.auditHdId === isAuditSelectedId);

        // Filter based on `showCompleted` value: true for completed, false for pending
        const filteredAuditLN = auditRelatedItems?.filter(item =>
            showCompleted ? item.auditResult === 3 : item.auditResult !== 3
        );

        // Extract the asset IDs from the filtered audit items
        const filteredAssetIds = filteredAuditLN.map(item => item.assetId);

        // Filter the main assetArray to only include those with IDs from `filteredAssetIds`
        const selectedData = assetArray.filter(asset =>
            filteredAssetIds.includes(asset.id)
        );

        // Update state with filtered data
        setAuditArrayLNfilter(filteredAuditLN);
        setSelectAssetData(selectedData);

        // Update the text and count
        setCompletedOrPendingText(showCompleted ? 'Audit Completed' : 'Audit Pending');
        setCompletedOrPendingCount(selectedData.length);

        return selectedData;
    };

    const handleToggleCompleted = (showCompleted) => {
        filterAssetsByAuditStatus(showCompleted);
    };


    const handleAuditCreation = async () => {
        setIsAuditCreate(true);
        setIsAuditUpdate(false);
    };

    const exportAsset = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== "") {
            handleExportAsset(selectedOption);
            event.target.value = "";
        }
    };

    const handleExportAsset = (format) => {
        // exportAssetData(assetArray, format);
    };

    const handleDeleteAudit = async (audit) => {
        try {
            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteAuditHD(audit.id);
                if (response === "Deleted") {
                    toast.success("Audit Deleted Successfully");
                    fetchAuditData();
                    fetchAllData();
                } else {
                    toast.error(response);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting audit:", error);
            toast.error(`An error occurred while deleting the audit.`);
        } finally {
            setIsLoading(false);
        }
    };


    // Audit pagination and table
    const handleSearchChangeAudit = (event) => {
        setSearchQueryAudit(event.target.value);
        const searchTextInput = event.target.value;
        const filteredAudit = searchTextInput != '' ? auditArrayHD?.filter((item) =>
            item?.auditID?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            item?.auditName?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            item?.Status?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            item?.AuditCreatedDate?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            item?.auditCreatedBy?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            item?.AuditDuration?.toLowerCase()?.includes(searchTextInput?.toLowerCase()) ||
            item?.LockOrUnlock?.toLowerCase()?.includes(searchTextInput?.toLowerCase())
        ) : auditArrayHD;
        setAuditArrayHDfilter(filteredAudit);
    };

    const handleRowsPerPageChangeAudit = (event) => {
        setRowsPerPageAudit(parseInt(event.target.value));
        setPageAudit(0);
    };

    const handleFirstPageAudit = () => {
        handleChangePagePaginationAudit({ selected: 0 });
    };

    const handleChangePagePaginationAudit = ({ selected }) => {
        setPageAudit(selected);
    };

    const handleLastPageAudit = () => {
        handleChangePagePaginationAudit({
            selected: Math.ceil(auditArrayHD?.length / rowsPerPageAudit) - 1,
        });
    };

    const totalEntriesAudit = auditArrayHD?.length;
    const startEntryAudit = pageAudit * rowsPerPageAudit + 1;
    const endEntryAudit = Math.min((pageAudit + 1) * rowsPerPageAudit, totalEntriesAudit);


    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleCategory">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">Asset Audit<div><InfoOutlinedIcon /></div></div>
                    </div>
                    {!IsAuditCreate && !IsAuditUpdate && (
                        <div>
                            <div className="">
                                <div className="card mt-4 padding20">
                                    <div className="cardBodySupplierMaster">
                                        <div id="printableContent">
                                            <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px" }}>
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsPerPageAudit} onChange={handleRowsPerPageChangeAudit} >
                                                            {paginateArray.map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                            Entries
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={searchQueryAudit}
                                                        onChange={handleSearchChangeAudit}
                                                        className="searchTextBox"
                                                    />
                                                </div>

                                                <div className="addbuttondiv">
                                                    <button
                                                        type="button"
                                                        id="BtnaddUnit"
                                                        className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                        onClick={handleAuditCreation}
                                                    >
                                                        Add New
                                                    </button>
                                                    {/* <select
                                                        className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn"
                                                        name="language"
                                                        id="language"
                                                        defaultValue=""
                                                        onChange={exportAsset}
                                                    >
                                                        <option value="" hidden={true}>
                                                            Export
                                                        </option>
                                                        <option value="csv">CSV</option>
                                                        <option value="excel">Excel</option>
                                                    </select> */}
                                                </div>
                                            </div>

                                            <div className="p-1">
                                                <div className="EmpBoxShadow">
                                                    <div className="customScrollbar" style={{ padding: "10px" }}>
                                                        <table className="w-100 mt-2">
                                                            <thead>
                                                                <tr className="text-left empTableHeight">
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "12%", padding: '2px 2px 2px 1%' }} > Audit ID </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Audit Name </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Status </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Audit Created </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Audit Created By </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "14%" }} > Audit Duration </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Lock / Unlock </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "8%" }} > Action </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {auditArrayHDfilter.length > 0 ? (
                                                                    auditArrayHDfilter.slice(pageAudit * rowsPerPageAudit, (pageAudit + 1) * rowsPerPageAudit).map((Audit, index) => (
                                                                        <tr key={Audit.Rowid} style={{ height: "59px" }}>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >
                                                                                <span style={{ color: "#605BFF", width: "12%", padding: "2px 2px 2px 3%", maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                                    title={Audit.auditID}>{Audit.auditID}</span></td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >
                                                                                <span style={{ width: "12%", maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                                    title={Audit.auditName}>{Audit.auditName}</span></td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Audit.Status}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Audit.AuditCreatedDate}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`}
                                                                                style={{ color: "#605BFF", maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                                title={Audit.auditCreatedBy}>{Audit.auditCreatedBy}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`}
                                                                                style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                                title={Audit.AuditDuration}>{Audit.AuditDuration}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Audit.LockOrUnlock}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >
                                                                                <span style={{ cursor: "pointer" }}><a href={'#viewOrEditAudit'} onClick={() => handleViewOrEditAudit(Audit, true)}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                                <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href={'#viewOrEditAudit'} onClick={() => handleViewOrEditAudit(Audit, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                                <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href="#deleteAuditHD" onClick={() => handleDeleteAudit(Audit)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {totalEntriesAudit > 0 && (
                                                    <div>
                                                        <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                            <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                                Showing {startEntryAudit} to {endEntryAudit} of {totalEntriesAudit} entries
                                                            </div>
                                                            <div>
                                                                <div className="pagination-container d-flex align-items-center">
                                                                    <button className="paginationButton btn btn-link" onClick={handleFirstPageAudit} >
                                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                    </button>
                                                                    <ReactPaginate
                                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                        breakLabel={"..."}
                                                                        breakClassName={"break-me"}
                                                                        pageCount={Math.ceil(totalEntriesAudit / rowsPerPageAudit)}
                                                                        forcePage={pageAudit}
                                                                        marginPagesDisplayed={2}
                                                                        pageRangeDisplayed={5}
                                                                        onPageChange={handleChangePagePaginationAudit}
                                                                        containerClassName={"pagination"}
                                                                        subContainerClassName={"pages pagination"}
                                                                        activeClassName={"active"}
                                                                        renderOnZeroPageCount={null}
                                                                    />
                                                                    <button className="paginationButton btn btn-link" onClick={handleLastPageAudit}>
                                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div style={{ padding: "0px 100px" }}></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                    {IsAuditCreate && !IsAuditUpdate && (
                        <div>
                            {IsAddtoList === false ? (
                                <div className="card mt-4 customScrollbar">
                                    <div className="card-body-category">
                                        <div className="pLeft30 pRight20">
                                            <div className="row" style={{ paddingTop: "24px" }}>
                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                        Unit
                                                    </div>
                                                    <Controller
                                                        name="unitId"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={unitArray.find((c) => c.bU_ID === field.value) || null}
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                                }}
                                                                options={unitArray}
                                                                getOptionLabel={(option) => option.bU_NAME}
                                                                getOptionValue={(option) => option.bU_NAME}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                    {errors.unitId && (
                                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.unitId.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                        Branch
                                                    </div>
                                                    <Controller
                                                        name="branchId"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={branchArray.find((c) => c.bR_ID === field.value) || null}
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                                }}
                                                                options={branchArray}
                                                                getOptionLabel={(option) => option.bR_NAME}
                                                                getOptionValue={(option) => option.bR_NAME}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                    {errors.branchId && (
                                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.branchId.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                        Category
                                                    </div>
                                                    <Controller
                                                        name="categoryId"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={categoryArray.find(option => option.id === field.value) || null}
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={categoryArray}
                                                                getOptionLabel={(option) => option.categoryName}
                                                                getOptionValue={(option) => option.categoryName}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                    {errors.categoryId && (
                                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.categoryId.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row" style={{ paddingTop: "24px" }}>
                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                        Sub Category
                                                    </div>
                                                    <Controller
                                                        name="subCategoryId"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={subCategoryArray.find(option => option.id === field.value) || null}
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={subCategoryArray}
                                                                getOptionLabel={(option) => option.subCategoryName}
                                                                getOptionValue={(option) => option.subCategoryName}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                    {errors.subCategoryId && (
                                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.subCategoryId.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                        Department
                                                    </div>
                                                    <Controller
                                                        name="departmentId"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={departmentArray.find((c) => c.id === field.value) || null}
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={departmentArray}
                                                                getOptionLabel={(option) => option.departmentName}
                                                                getOptionValue={(option) => option.departmentName}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                    {errors.departmentId && (
                                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                                            {errors.departmentId.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                                        Employee
                                                    </div>
                                                    <Controller
                                                        name="empId"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={employeeArray.find(emp => emp.id === field.value) || null}
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={employeeArray}
                                                                getOptionLabel={(option) => option.EmployeeName}
                                                                getOptionValue={(option) => option.EmployeeName}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                    {errors.empId && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                            {errors.empId.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row" style={{ paddingTop: "10px" }}>
                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12"></div>
                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12"></div>
                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="d-flex align-items-center mTop35">
                                                        <button
                                                            className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                            style={{ marginLeft: "20px" }}
                                                            onClick={handleSubmit(handleSearchChange)}
                                                        >
                                                            Search
                                                        </button>
                                                        <span>
                                                            <button
                                                                className="fontFamily fontweightRegular fontsize16 importBtn"
                                                                onClick={handleSearchClear}
                                                            >
                                                                Clear
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px", paddingTop: "8px" }}>
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                            {paginateArray.map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                            Entries
                                                        </span>
                                                    </span>
                                                </div>

                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                    style={{ height: '37px', width: '108px' }}
                                                    onClick={addToList}
                                                >
                                                    Add to List
                                                </button>
                                            </div>
                                        </div>

                                        <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                            <div className="tableScroll" style={{ height: '350px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-0">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "3%", padding: '2px 2px 2px 3%' }}>
                                                                <input
                                                                    id="myCheckboxHead"
                                                                    type="checkbox"
                                                                    checked={selectAll}
                                                                    onChange={handleSelectAllChange}
                                                                />
                                                            </th>
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Asset ID</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Assigned to</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Branch</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Unit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {assetArrayfilter.length > 0 ? (
                                                            assetArrayfilter.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((asset, index) => (
                                                                <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                        <input
                                                                            id={`checkbox-${asset.id}`}
                                                                            type="checkbox"
                                                                            checked={selectedAssets.includes(asset.id)}
                                                                            onChange={() => handleCheckboxChange(asset.id)}
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        className={`~grayTextColor fontFamily fontweightRegular fontsize14 `}
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            color:
                                                                                asset.id === asset.linkedAssetRelationId
                                                                                    ? asset.linkedAssetColor
                                                                                    : asset.id === asset.linkedAssetId
                                                                                        ? asset.textColor
                                                                                        : '#888787'
                                                                        }}
                                                                    >
                                                                        {asset?.assetID}
                                                                    </td>
                                                                    <td className={`~grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center', color: '#109CF1' }}>{asset?.assetName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetStatusValue}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.employeeName}</td>
                                                                    <td className={`~grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center', color: '#109CF1' }}>{asset?.branchName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.unitName}</td>
                                                                </tr>

                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="7" className="text-center fontFamily fontweightRegular fontsize16">
                                                                    <img src={nodataFound} alt="no data found" />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {totalEntriesAsset > 0 && (
                                        <div>
                                            <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                <div className="text-muted fontFamily fontsize12 fontweightRegular pLeft20">
                                                    Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                                </div>
                                                <div>
                                                    <div className="pagination-container d-flex align-items-center">
                                                        <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                            <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                        </button>
                                                        <ReactPaginate
                                                            previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                            nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                            forcePage={pageAsset}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handleChangePagePaginationAsset}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"}
                                                            renderOnZeroPageCount={null}
                                                        />
                                                        <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                            <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div style={{ padding: "0px 100px" }}></div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="mb-2 text-center" style={{ marginTop: "2%" }}>
                                        <button type="button" className="outlineButton" onClick={onClose} style={{ marginLeft: '18px' }} >Cancel</button>
                                    </div>
                                </div>
                            ) : (
                                <div className="card mt-4 customScrollbar">
                                    <div className="card-body-category">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="blackTextColor fontFamily fontsize16 fontweightRegular mLeft20 mTop20 mBottom20">
                                                Asset pending Audit
                                            </div>
                                            <div className="d-flex align-items-center mRight20">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                        style={{ height: '37px', width: 'auto' }}
                                                        onClick={() => setIsAddtoList(false)}
                                                    >
                                                        Back
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="fontFamily fontweightRegular fontsize16 coloredButtonAdd mLeft20"
                                                        style={{ height: '37px', width: '135px' }}
                                                        onClick={removeAsset}
                                                    >
                                                        Remove Assets
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="fontFamily fontweightRegular fontsize16 coloredButtonAdd mLeft20"
                                                        style={{ height: '37px', width: '128px' }}
                                                        onClick={onSelectAsset}
                                                    >
                                                        Select Assets
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ margin: '0% 2%' }}>
                                            <form onSubmit={handleSubmitAudit(onSubmitAudit)}>
                                                <div className="row">
                                                    <div className='col-4 col-md-4 col-lg-4 col-sm-12'>
                                                        <div className='grayTextColor fontFamily fontsize16 fontweightRegular pLeft8'>
                                                            Audit Name<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <input
                                                            className="textBoxNew"
                                                            {...registerAudit('auditName', {
                                                                required: 'Please Enter audit Name',

                                                            })}
                                                        />
                                                        {errorsAudit.auditName && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsAudit.auditName.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="CustomBoxShadow" style={{ marginTop: '20px' }}>
                                                    <div className="tableScroll" style={{ height: 'auto', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                        <table className="w-100 mt-0">
                                                            <thead>
                                                                <tr className="text-left">
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "3%", padding: '2px 2px 2px 3%' }}>
                                                                        <input
                                                                            id="myCheckboxHead"
                                                                            type="checkbox"
                                                                            checked={selectAllAssetPending}
                                                                            onChange={handleSelectAllAssetPendingChange}
                                                                        />
                                                                    </th>
                                                                    <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Asset ID</th>
                                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Status</th>
                                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Assigned to</th>
                                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Branch</th>
                                                                    <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Unit</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {selectAssetData.length > 0 ? (
                                                                    selectAssetData.map((asset, index) => (
                                                                        <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                                <input
                                                                                    id={`checkbox-${asset.id}`}
                                                                                    type="checkbox"
                                                                                    checked={selectedAssetsPending.includes(asset.id)}
                                                                                    onChange={() => handleCheckboxChangeAssetPending(asset.id)}
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                className={`~grayTextColor fontFamily fontweightRegular fontsize14 `}
                                                                                style={{
                                                                                    textAlign: 'center',
                                                                                    color:
                                                                                        asset.id === asset.linkedAssetRelationId
                                                                                            ? asset.linkedAssetColor
                                                                                            : asset.id === asset.linkedAssetId
                                                                                                ? asset.textColor
                                                                                                : '#888787'
                                                                                }}
                                                                            >
                                                                                {asset?.assetID}
                                                                            </td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetName}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetStatusValue}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.employeeName}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.branchName}</td>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.unitName}</td>
                                                                        </tr>

                                                                    ))
                                                                ) : (
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: '20px' }}>
                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                            Audit Status<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="auditStatus"
                                                            control={controlAudit}
                                                            rules={{ required: 'Please select Audit Status' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={auditStatusDropdown.find(auditStatus => auditStatus.id === field.value) || null}
                                                                    onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                                    options={auditStatusDropdown}
                                                                    getOptionLabel={(option) => option.idValue}
                                                                    getOptionValue={(option) => option.id}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsAudit.auditStatus && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsAudit.auditStatus.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                            Audit Start Date<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <Controller
                                                                name="auditStartDate"
                                                                control={controlAudit}
                                                                rules={{
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) {
                                                                                return "Please select start date";
                                                                            }

                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);

                                                                            // Get today's date for comparison
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);

                                                                            // Check if selected date is in the future
                                                                            // if (selectedDate > today) {
                                                                            //     return "Date cannot be in the future";
                                                                            // }

                                                                            // Validate date format using regex
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + ")";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => { field.onChange(date); }}
                                                                        dateFormat={dateFormat}
                                                                        className="customDatePicker date-picker-input"
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                                        </div>
                                                        {errorsAudit.auditStartDate && (
                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {errorsAudit.auditStartDate.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                            Audit End Date
                                                        </div>
                                                        <div className="d-flex">
                                                            <Controller
                                                                name="auditEndDate"
                                                                control={controlAudit}
                                                                rules={{
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            // If the value is empty, it's valid because the field is optional
                                                                            if (!value) {
                                                                                return true;
                                                                            }

                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);

                                                                            // Get today's date for comparison
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);

                                                                            // Check if selected date is in the future
                                                                            // if (selectedDate > today) {
                                                                            //     return "Date cannot be in the future";
                                                                            // }

                                                                            // Validate date format using regex
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + ")";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => { field.onChange(date); }}
                                                                        dateFormat={dateFormat}
                                                                        className="customDatePicker date-picker-input"
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                                        </div>
                                                        {errorsAudit.auditEndDate && (
                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {errorsAudit.auditEndDate.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: '20px' }}>
                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Audit Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerAudit('auditNote')}
                                                        />
                                                    </div>

                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">{auditLock ? "Lock" : "Unlock"}</div>
                                                        <Controller
                                                            name="auditLock"
                                                            control={controlAudit}
                                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                <Switch
                                                                    color="primary"
                                                                    checked={value}
                                                                    onChange={(e) => {
                                                                        onChange(e.target.checked);
                                                                        setIsAuditView(e.target.checked);
                                                                    }}
                                                                    onBlur={onBlur}
                                                                    inputRef={ref}
                                                                    sx={{
                                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                                            color: '#605bff',
                                                                            '& + .MuiSwitch-track': {
                                                                                backgroundColor: '#605bff',
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-2 text-center" style={{ marginTop: "2%" }}>
                                                    <button type="submit" className="coloredButtonSave">Save</button>
                                                    <button type="button" className="outlineButton" onClick={onClose} style={{ marginLeft: '18px' }} >Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                        <Modal show={isAudit} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                                                        Add Assets
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div>
                                                    <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryAsset}
                                                            onChange={handleSearchChangeAsset}
                                                            style={{ marginLeft: '0px', marginBottom: '7px' }}
                                                        />
                                                        <div className="tableScroll" style={{ height: '250px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                            <table className="w-100 mt-0">
                                                                <thead>
                                                                    <tr className="text-left">
                                                                        <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "3%", padding: '2px 2px 2px 3%' }}>
                                                                            <input
                                                                                id="myCheckboxHead"
                                                                                type="checkbox"
                                                                                checked={selectAllAssetAdd}
                                                                                onChange={handleSelectAllAddAssetChange}
                                                                            />
                                                                        </th>
                                                                        <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Asset ID</th>
                                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Status</th>
                                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Assigned to</th>
                                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Branch</th>
                                                                        <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Unit</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {addAssetList.length > 0 ? (
                                                                        addAssetList.map((asset, index) => (
                                                                            <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                                    <input
                                                                                        id={`checkbox-${asset.id}`}
                                                                                        type="checkbox"
                                                                                        checked={selectedAssetAdd.includes(asset.id)}
                                                                                        onChange={() => handleCheckboxChangeAddAsset(asset.id)}
                                                                                    />
                                                                                </td>
                                                                                <td
                                                                                    className={`~grayTextColor fontFamily fontweightRegular fontsize14 `}
                                                                                    style={{
                                                                                        textAlign: 'center',
                                                                                        color:
                                                                                            asset.id === asset.linkedAssetRelationId
                                                                                                ? asset.linkedAssetColor
                                                                                                : asset.id === asset.linkedAssetId
                                                                                                    ? asset.textColor
                                                                                                    : '#888787'
                                                                                    }}
                                                                                >
                                                                                    {asset?.assetID}
                                                                                </td>
                                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetName}</td>
                                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetStatusValue}</td>
                                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.employeeName}</td>
                                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.branchName}</td>
                                                                                <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.unitName}</td>
                                                                            </tr>

                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="7" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                                        </tr>

                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 text-center" style={{ marginTop: "3%" }}>
                                                        <button type="button" className="outlineButton" onClick={handleClose} >Cancel</button>
                                                        <button type="submit" className="coloredButtonSave" style={{ marginLeft: '18px' }} onClick={addAssetPopUp}>Add To List</button>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}


                    {IsAuditUpdate && !IsAuditCreate && (
                        <div>
                            <div className="card mt-4 customScrollbar">
                                <div className="card-body-category">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mLeft20 mTop20 mBottom20">
                                            Audit Details
                                        </div>
                                    </div>
                                    <div style={{ margin: '0% 2%' }}>
                                        <div className="row">
                                            <div className='col-4 col-md-4 col-lg-4 col-sm-12'>
                                                <div className='grayTextColor fontFamily fontsize16 fontweightRegular pLeft8'>
                                                    Audit Name<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    className="textBoxNew"
                                                    {...registerAuditUpdate('auditName')}
                                                    disabled={IsAuditView}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end mRight20 mTop20">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                    style={{ height: '37px', width: 'auto' }}
                                                    onClick={() => setIsAddtoList(false)}
                                                >
                                                    Back
                                                </button>
                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 coloredButtonAdd mLeft20"
                                                    style={{ height: '37px', width: '135px' }}
                                                    onClick={removeAsset}
                                                    disabled={IsAuditView}
                                                >
                                                    Remove Assets
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 coloredButtonAdd mLeft20"
                                                    style={{ height: '37px', width: '128px' }}
                                                    onClick={onSelectAsset}
                                                    disabled={IsAuditView}
                                                >
                                                    Select Assets
                                                </button>
                                            </div>
                                        </div>
                                        <div className="CustomBoxShadow" style={{ marginTop: '20px' }}>
                                            <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px" }}>
                                                <div className="custom-container">
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        // value={searchQueryAsset}
                                                        // onChange={handleSearchChangeAsset}
                                                        style={{ marginLeft: '0px', marginBottom: '7px' }}
                                                    />
                                                </div>
                                                <div className="addbuttondiv">
                                                    <div className="fontFamily fontweightRegular fontsize16 blackTextColor">
                                                        {completedOrPendingText} {completedOrPendingCount}
                                                    </div>
                                                    <Controller
                                                        name="completedToggle"
                                                        control={controlAuditUpdate}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <Switch
                                                                color="primary"
                                                                checked={value}
                                                                onChange={(e) => {
                                                                    const showCompleted = e.target.checked;
                                                                    onChange(showCompleted);
                                                                    handleToggleCompleted(showCompleted); // Call the toggle handler with the new status
                                                                }}
                                                                onBlur={onBlur}
                                                                inputRef={ref}
                                                                sx={{
                                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                                        color: '#605bff',
                                                                        '& + .MuiSwitch-track': {
                                                                            backgroundColor: '#605bff',
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="tableScroll" style={{ height: 'auto', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-0">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "3%", padding: '2px 2px 2px 3%' }}>
                                                                <input
                                                                    id="myCheckboxHead"
                                                                    type="checkbox"
                                                                    disabled={IsAuditView}
                                                                    checked={selectAllAssetPending}
                                                                    onChange={handleSelectAllAssetPendingChange}
                                                                />
                                                            </th>
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "14%", textAlign: 'center' }}>Asset ID</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "14%", textAlign: 'center' }}>Description</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "14%", textAlign: 'center' }}>Assigned to</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Unit</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Branch</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "14%", textAlign: 'center' }}>Audit Result</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "13%", textAlign: 'center' }}>Descripency Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectAssetData.length > 0 ? (
                                                            selectAssetData.map((asset, index) => (
                                                                <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ padding: "2px 2px 2px 3%" }}>
                                                                        <input
                                                                            id={`checkbox-${asset.id}`}
                                                                            type="checkbox"
                                                                            disabled={IsAuditView}
                                                                            checked={selectedAssetsPending.includes(asset.id)}
                                                                            onChange={() => handleCheckboxChangeAssetPendingUpdate(asset.id)}
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        className={`~grayTextColor fontFamily fontweightRegular fontsize14 `}
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            color:
                                                                                asset.id === asset.linkedAssetRelationId
                                                                                    ? asset.linkedAssetColor
                                                                                    : asset.id === asset.linkedAssetId
                                                                                        ? asset.textColor
                                                                                        : '#888787'
                                                                        }}
                                                                    >
                                                                        {asset?.assetID}
                                                                    </td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.assetName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.employeeName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.unitName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.branchName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.auditResult}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{asset?.descripencyType}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="7" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmitAuditUpdate(onSubmitUpdateAudit)}>
                                            <div className="row" style={{ marginTop: '15px' }}>
                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                        Asset Audit Result
                                                    </div>
                                                    <Controller
                                                        name="auditResult"
                                                        control={controlAuditUpdate}
                                                        // rules={{ required: 'Please select Audit Status' }}
                                                        defaultValue={auditResultDropdown[0] ? auditResultDropdown[0].id : null}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={auditResultDropdown.find(auditResult => auditResult.id === field.value) || null}
                                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                                options={auditResultDropdown}
                                                                getOptionLabel={(option) => option.idValue}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={IsAuditView}
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                    Discrepancy Type<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <Controller
                                                        name="auditDescripency"
                                                        control={controlAuditUpdate}
                                                        // rules={{ required: 'Please select Audit Status' }}
                                                        defaultValue={auditResultDropdown[7] ? auditResultDropdown[7].id : null}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={auditDescripencyTypeDropdown.find(auditDescripency => auditDescripency.id === field.value) || null}
                                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                                options={auditDescripencyTypeDropdown}
                                                                getOptionLabel={(option) => option.idValue}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={IsAuditView}
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                        Asset Matches with Expected Location
                                                    </div>
                                                    <Controller
                                                        name="auditAssetMatches"
                                                        control={controlAuditUpdate}
                                                        // rules={{ required: 'Please select Audit Status' }}
                                                        defaultValue={auditAssetMatchesDropdown[1]}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={auditAssetMatchesDropdown.find(auditAssetMatches => auditAssetMatches.id === field.value) || null}
                                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); handleAssetMatchesChange(selectedOption) }}
                                                                options={auditAssetMatchesDropdown}
                                                                getOptionLabel={(option) => option.idValue}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={IsAuditView}
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {IsAssetMatches && (
                                                <div className="row" style={{ marginTop: '15px' }}>
                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                            Actual Unit<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="auditUnit"
                                                            control={controlAuditUpdate}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={unitArray.find((c) => c.bU_ID === field.value) || null}
                                                                    onChange={(selectedOption) => {
                                                                        field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                                    }}
                                                                    options={unitArray}
                                                                    getOptionLabel={(option) => option.bU_NAME}
                                                                    getOptionValue={(option) => option.bU_NAME}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={IsAuditView}
                                                                    styles={customStyles}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                            Actual Branch<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="auditBranch"
                                                            control={controlAuditUpdate}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={branchArray.find((c) => c.bR_ID === field.value) || null}
                                                                    onChange={(selectedOption) => {
                                                                        field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                                    }}
                                                                    options={branchArray}
                                                                    getOptionLabel={(option) => option.bR_NAME}
                                                                    getOptionValue={(option) => option.bR_NAME}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={IsAuditView}
                                                                    styles={customStyles}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                            Actual Department<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="auditDepartment"
                                                            control={controlAuditUpdate}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={departmentArray.find((c) => c.id === field.value) || null}
                                                                    onChange={(selectedOption) => {
                                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                                    }}
                                                                    options={departmentArray}
                                                                    getOptionLabel={(option) => option.departmentName}
                                                                    getOptionValue={(option) => option.departmentName}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={IsAuditView}
                                                                    styles={customStyles}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row" style={{ marginTop: '15px' }}>
                                                <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                        Assignee
                                                    </div>
                                                    <Controller
                                                        name="auditAssignee"
                                                        control={controlAuditUpdate}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={employeeArray.find(emp => emp.id === field.value) || null}
                                                                onChange={(selectedOption) => {
                                                                    field.onChange(selectedOption ? selectedOption.id : null);
                                                                }}
                                                                options={employeeArray}
                                                                getOptionLabel={(option) => option.EmployeeName}
                                                                getOptionValue={(option) => option.EmployeeName}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={IsAuditView}
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                        Audit Start Date<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Controller
                                                            name="auditStartDate"
                                                            control={controlAuditUpdate}
                                                            rules={{
                                                                validate: {
                                                                    validDate: (value) => {
                                                                        // If the value is empty, it's valid because the field is optional
                                                                        // if (!value) {
                                                                        //     return "Please select start date";
                                                                        // }

                                                                        // Check if the value is a valid date object
                                                                        const dateValue = new Date(value);
                                                                        if (isNaN(dateValue.getTime())) {
                                                                            return "Invalid date value";
                                                                        }

                                                                        // Format the date and validate it
                                                                        const formattedDate = format(dateValue, dateFormat);
                                                                        const [day, month, year] = formattedDate.split('/');
                                                                        const selectedDate = validateDate(dateFormat, day, month, year);

                                                                        // Get today's date for comparison
                                                                        const today = new Date();
                                                                        today.setHours(0, 0, 0, 0);

                                                                        // Check if selected date is in the future
                                                                        // if (selectedDate > today) {
                                                                        //     return "Date cannot be in the future";
                                                                        // }

                                                                        // Validate date format using regex
                                                                        const regex = getRegexForDateFormat(dateFormat);
                                                                        if (!regex.test(formattedDate)) {
                                                                            return "Invalid date format (" + dateFormat + ")";
                                                                        }
                                                                        return true;
                                                                    },
                                                                },
                                                            }}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={field.value}
                                                                    onChange={(date) => { field.onChange(date); }}
                                                                    dateFormat={dateFormat}
                                                                    disabled={true}
                                                                    className="customDatePicker date-picker-input"
                                                                    ref={(input) => field.ref(input?.inputElement)}
                                                                />
                                                            )}
                                                        />
                                                        <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                                    </div>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                        Audit End Date
                                                    </div>
                                                    <div className="d-flex">
                                                        <Controller
                                                            name="auditEndDate"
                                                            control={controlAuditUpdate}
                                                            rules={{
                                                                validate: {
                                                                    validDate: (value) => {
                                                                        // If the value is empty, it's valid because the field is optional
                                                                        // if (!value) {
                                                                        //     return true;
                                                                        // }

                                                                        // Check if the value is a valid date object
                                                                        const dateValue = new Date(value);
                                                                        if (isNaN(dateValue.getTime())) {
                                                                            return "Invalid date value";
                                                                        }

                                                                        // Format the date and validate it
                                                                        const formattedDate = format(dateValue, dateFormat);
                                                                        const [day, month, year] = formattedDate.split('/');
                                                                        const selectedDate = validateDate(dateFormat, day, month, year);

                                                                        // Get today's date for comparison
                                                                        const today = new Date();
                                                                        today.setHours(0, 0, 0, 0);

                                                                        // Check if selected date is in the future
                                                                        // if (selectedDate > today) {
                                                                        //     return "Date cannot be in the future";
                                                                        // }

                                                                        // Validate date format using regex
                                                                        const regex = getRegexForDateFormat(dateFormat);
                                                                        if (!regex.test(formattedDate)) {
                                                                            return "Invalid date format (" + dateFormat + ")";
                                                                        }
                                                                        return true;
                                                                    },
                                                                },
                                                            }}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={field.value}
                                                                    onChange={(date) => { field.onChange(date); }}
                                                                    dateFormat={dateFormat}
                                                                    disabled={IsAuditView}
                                                                    className="customDatePicker date-picker-input"
                                                                    ref={(input) => field.ref(input?.inputElement)}
                                                                />
                                                            )}
                                                        />
                                                        <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row" style={{ marginTop: '20px' }}>
                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                        Audit Status<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <Controller
                                                        name="auditStatus"
                                                        control={controlAuditUpdate}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={auditStatusDropdown.find(auditStatus => auditStatus.id === field.value) || null}
                                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                                options={auditStatusDropdown}
                                                                getOptionLabel={(option) => option.idValue}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder=""
                                                                isClearable
                                                                isSearchable
                                                                isDisabled={IsAuditView}
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                    {errorsAudit.auditStatus && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                            {errorsAudit.auditStatus.message}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Audit Notes</div>
                                                    <textarea
                                                        placeholder=""
                                                        className="textareaCustom textAeraHeight"
                                                        {...registerAuditUpdate('auditNote')}
                                                        disabled={IsAuditView}
                                                    />
                                                </div>

                                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor">{auditLockOnUpdate ? "Lock" : "Unlock"}</div>
                                                    <Controller
                                                        name="auditLock"
                                                        control={controlAuditUpdate}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <Switch
                                                                color="primary"
                                                                checked={value}
                                                                onChange={(e) => {
                                                                    onChange(e.target.checked);
                                                                    setIsAuditView(e.target.checked);
                                                                }}
                                                                onBlur={onBlur}
                                                                inputRef={ref}
                                                                sx={{
                                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                                        color: '#605bff',
                                                                        '& + .MuiSwitch-track': {
                                                                            backgroundColor: '#605bff',
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="CustomBoxShadow" style={{ width: '100%', padding: '2% 1%', marginTop: '2%' }}>
                                                <div className="fontFamily fontsize16 fontweightSemiBold blackTextColor pLeft12">
                                                    History
                                                </div>
                                                <div className='ServiceHistorycustomScrollbar' >
                                                    {filterAuditHistory.length > 0 ? (filterAuditHistory?.map((history, index) => (
                                                        <div style={{ background: 'rgba(96, 91, 255, 0.08)', borderRadius: '5px', marginTop: '1%', marginLeft: '1%' }}>
                                                            <div style={{ padding: '1%' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className="fontFamily fontsize16 fontweightSemiBold blackTextColor">
                                                                        {history.empName} ({history.empCodeId})
                                                                    </div>
                                                                    <div className="fontFamily fontsize16 fontweightSemiBold blackTextColor">
                                                                        {history.modifiedCreatedDate}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='fontFamily fontsize16 fontweightSemiBold grayTextColor' style={{ padding: '0% 1%' }}>
                                                                {history.historyMessage}
                                                            </div>
                                                        </div>
                                                    ))) : (
                                                        <div className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mb-2 text-center" style={{ marginTop: "2%" }}>
                                                <button type="submit" className="coloredButtonSave">Save</button>
                                                <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={onClose} >Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            )}
        </div >

    );
}
export default AuditList;