import axios from 'axios';
import api from '../webconfig';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

// ActionFormConfiguration
//Tab
export async function getTab() {
    try {
        const response = await axios.get(API_BASE_URL + 'ActionFormConfig/GetActionFormConfig', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching TabData:', error);
        throw error;
    }
}

//CheckOut
export async function createCheckOut(checkOut) {
    try {
        const response = await axios.post(API_BASE_URL + 'CheckOut/CreateCheckOut', checkOut, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create CheckOut:', error);
        throw error;
    }
}

export async function getCheckOut() {
    try {
        const response = await axios.get(API_BASE_URL + 'CheckOut/getCheckOut', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching CheckOut:', error);
        throw error;
    }
}

//CheckIn
export async function createCheckIn(insurance) {
    try {
        const response = await axios.post(API_BASE_URL + 'CheckIn/CreateCheckIn', insurance, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create CheckIn:', error);
        throw error;
    }
}

export async function getCheckIn() {
    try {
        const response = await axios.get(API_BASE_URL + 'CheckIn/getCheckIn', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching CheckIn:', error);
        throw error;
    }
}

//Lost
export async function getLost() {
    try {
        const response = await axios.get(API_BASE_URL + 'Lost/GetLost', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Lost:', error);
        throw error;
    }
}

export async function createLost(lost) {
    try {
        const response = await axios.post(API_BASE_URL + 'Lost/CreateLost', lost, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Lost:', error);
        throw error;
    }
}

//Found
export async function createFound(found) {
    try {
        const response = await axios.post(API_BASE_URL + 'Found/CreateFound', found, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Found:', error);
        throw error;
    }
}

//Repair
export async function createRepair(repair) {
    try {
        const response = await axios.post(API_BASE_URL + 'Repair/CreateRepair', repair, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Repair:', error);
        throw error;
    }
}

export async function updateRepair(repair) {
    try {
        const response = await axios.post(API_BASE_URL + 'Repair/UpdateRepair', repair, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error update Repair:', error);
        throw error;
    }
}

export async function getRepair() {
    try {
        const response = await axios.get(API_BASE_URL + 'Repair/GetRepair', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Repair:', error);
        throw error;
    }
}

//Broken
export async function getBroken() {
    try {
        const response = await axios.get(API_BASE_URL + 'Broken/GetBroken', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Broken:', error);
        throw error;
    }
}

export async function createBroken(broken) {
    try {
        const response = await axios.post(API_BASE_URL + 'Broken/CreateBroken', broken, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Broken:', error);
        throw error;
    }
}

//Disposed
export async function getDisposed() {
    try {
        const response = await axios.get(API_BASE_URL + 'Disposed/GetDisposed', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Disposed:', error);
        throw error;
    }
}

export async function createDisposed(disposed) {
    try {
        const response = await axios.post(API_BASE_URL + 'Disposed/CreateDisposed', disposed, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Disposed:', error);
        throw error;
    }
}

//Donate
export async function getDonate() {
    try {
        const response = await axios.get(API_BASE_URL + 'Donate/GetDonate', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Donate:', error);
        throw error;
    }
}

export async function createDonate(donate) {
    try {
        const response = await axios.post(API_BASE_URL + 'Donate/CreateDonate', donate, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Donate:', error);
        throw error;
    }
}

//Sell
export async function getSell() {
    try {
        const response = await axios.get(API_BASE_URL + 'Sell/GetSell', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Sell:', error);
        throw error;
    }
}

export async function createSell(sell) {
    try {
        const response = await axios.post(API_BASE_URL + 'Sell/CreateSell', sell, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Sell:', error);
        throw error;
    }
}

//Clone
export async function cloneAsset(count, assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}assetcreation/CloneAsset?count=${count}&assetID=${assetID}`, null, {
            headers: getAuthHeader()
        });
        // const response = await axios.post(API_BASE_URL + "AssetCreation/CloneAsset/CloneCount/" + count + "/assetID/" + assetID + "", {
        //     headers: getAuthHeader()
        // });
        return response.data;
    } catch (error) {
        console.error('Error Clone Asset:', error);
        throw error;
    }
}

//Transfer
export async function transferAsset(transferAssetData) {
    try {
        const response = await axios.post(API_BASE_URL + 'Transfer/TransferAsset', transferAssetData, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error Transfer Asset:', error);
        throw error;
    }
}