import Swal from 'sweetalert2';

const ConfirmDelete = async () => {
    const result = await Swal.fire({
        title: 'Are you sure you want to delete this record?',
        text: 'This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it'
    });
    return result.isConfirmed;
};

export default ConfirmDelete;
